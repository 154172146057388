$breakpoints: (
  'xs': 479,
  'sm': 576,
  'md': 767,
  'lg': 959,
  'xl': 1280,
  'xl1440': 1439,
  'xl1560': 1559,
  'xl1920': 1919,
) !default;

@mixin mq($mq, $bp1: md, $bp2: lg) {
  $w1    : map-get($breakpoints, $bp1);
  $w2    : map-get($breakpoints, $bp2);
  $min1  : 'min-width: #{convert-em($w1, 1)}';
  $min2  : 'min-width: #{convert-em($w1, 1)}';
  $max1  : 'max-width: #{convert-em($w1)}';
  $max2  : 'max-width: #{convert-em($w2)}';

  @if $mq == min {
    @media screen and ($min1) {
      @content;
    }
  }
  @else if $mq == max {
    @media screen and ($max1) {
      @content;
    }
  }
  @else if $mq == min-max {
    @media screen and ($min2) and ($max2) {
      @content;
    }
  }
}

@function convert-em($width, $ge: 0) {
  @return '#{($width + $ge) / 16}em';
}

// sp-fontsize
@function get_vw($size, $viewport:320){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}

@mixin fz_vw($font_size:10){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}
