@import "mixin";

@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */
 $color-base: #373737;
 $color-black: #212121;
 $color-blue: #0091e3;

body {
  margin: 0;
  padding: 0;
  color: $color-base;
  font-family: /* -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, */YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-weight: 500;
  @media (max-width: 1280px){
    &.sp-menu-open{
      overflow: hidden;
    }
  }
}
b, strong, .bold{
	font-family: /* -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, */YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif;
}
h1,h2,h3 {
  font-family: /* -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI semibold", "Helvetica Neue", HelveticaNeue, */YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-feature-settings : "palt";
}
table{margin: 1em 0;width: 100%;font-size:110%;}
th,td{vertical-align: top;padding:0.4em;border: 1px dotted #5D3F37;}
th{text-align: left;}
td ol, td ul{margin: 0;}

dt{font-weight: bold;}
dd{margin: 0 0 1em;}

a{
  color: #444;
  &:hover{
    color: $color-base;
  }
}
.bg-blue{
  background: #e0f2fc;
}

/* ---------------------------
header
*/
header{
  position: fixed;
  top:0;
  background: rgba(255,255,255,1);
  z-index: 1200;
  width: 100%;
  .title{
    a{
      color: $color-base;
      &:hover{
        text-decoration: none;
      }
    }
  }
  ul{
    li{
      a{
        color: $color-base;
        &:hover{
          text-decoration: none;
          opacity: .9;
        }
      }
      &#nav-online,&#nav-contact{
        background: #fff;
          a{
            display: block;
            height: 100%;
            &:hover{
              opacity: .6;
            }
          }
      }
      &#nav-online{
        a{
          background: #0080f0;
        }
      }
      &#nav-contact{
        a{
          background: #0864b5;
        }
      }
    }
  }
  .sns{
    align-items: center;
    li{
      font-size: 1.5rem;
      &#nav-sns-youtube a{
        color: #ff0500;
      }
      &#nav-sns-line{
        height: 29px;
        width: 29px;
        border-radius: 50%;
        overflow: hidden;
        justify-content: center;
        display: flex;
        align-items: center;
        border:2px solid #25C131;
        margin-right: .4rem;
        a{
          color: #25C131;
          font-size: 2rem;
        }
      }
    }
  }
  @include mq('min', 'xl') {
    display: flex;
    justify-content:space-between;
    .title{
      // padding:44px 0 0 46px;
      padding: 29px 0 0 29px;
      a{
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 500;
      }

      img{
        width: 165px;
        // width: 206px;
      }
    }
    nav{
      display: flex;
      justify-content:flex-end;
      ul{
        display: flex;
        justify-content:flex-end;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li{
          font-size: .9rem;
          padding:0;
          font-feature-settings : "palt";
          letter-spacing: 0.08rem;
        }
      }
      ul#gnav{
        margin: 30px 0 0;
        height: 80px;
        li{
          line-height: 1.2;
          position: relative;
          a{
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 0 ;
            text-decoration: none;
            text-align: center;
            transition: 0.2s;
            color: $color-base;
            height: 100%;
            border-right: 1px solid $color-base;
            img{
              width: 93px;
              margin-bottom: 2px;
            }
            span{
              height: 2rem;
              vertical-align: middle;
              padding-top: 7px;
            }
            &:hover{
              text-decoration: none;
              color: #222;
            }
          }
          &>div{
            align-items: center;
            flex-direction: column;
            padding: 0 ;
            text-decoration: none;
            text-align: center;
            transition: 0.2s;
            height: 100%;
            border-right: 1px solid $color-base;
            cursor: pointer;
            img{
              width: 93px;
              margin-bottom: 2px;
            }
            span{
              height: 2rem;
              vertical-align: middle;
              padding-top: 7px;
            }
          }
          .pulldown{
            position: absolute;
            left:0;
            top: 100px;
            width: 100%;
            ul{
              background: rgba(255,255,255,.9);
              display: none;
                padding:17px 0;
              li{
                padding:3px 0 2px;
                font-size: 13px;
                line-height: 20px;
                a{
                  border: 0 !important;
                  color: $color-base !important;
                  text-align: left !important;
                  padding:5px 20px !important;
                  display: block;
                  &:hover{
                    color: $color-blue !important;
                  }
                }
              }
            }
            &.spread ul {
              display: block;
            }
          }
          &#nav-rent{
            div{
              border-left: 1px solid $color-base;
              padding:0 5px;
            }
            .pulldown{
              border: 0 !important;
              width:155px;
            }
          }
          &#nav-buyandsell{
            a{
              span{
                padding-top: 0;
              }
            }
          }
          &#nav-campaign{
            div{
              padding: 0 13px;
              span{
                padding-top: 0;
              }
            }
            .pulldown{
              border: 0 !important;
              padding:0;
              width:175px;
            }
          }
          &#nav-topics{
            a{
              padding: 0 5px;
              span{
                padding-top: 0;
              }
            }
          }
          &#nav-customer{
            a{
              padding: 0 5px;
            }
          }
          &#nav-about, &#nav-recruit{
            a{
              padding: 0 1px;
            }
          }
        }
      }
      ul#head-nav{
        height: 130px;
        li{
          color: #fff;
          width: 130px;
          text-align: center;
          font-weight: bold;
          line-height: 1.3;
          a{
            color: #fff;
            padding: .2rem .1rem 0 .1rem;
            border:0;
            img{
              width: 130px;
              margin-top: 10px;
              margin-bottom: 3px;
            }
          }
          &#nav-online{
            background: #0080f0;
            margin-left: 40px;
          }
          &#nav-contact{
            background: #0864b5;
            border:0;
            a{
              img{
                margin-bottom: 12px;
              }
              span{
                padding-top: 10px;
              }
            }
          }
        }
      }
    }
    .overlay, .menu-trigger{
      display: none;
    }
    .sns{
      position: absolute;
      top:0;
      right:10px;
    }
  }
  @include mq('max', 'xl') {
    .title{
      z-index:10000;
      position: relative;
      margin: 0 100px;
      text-align: center;
      background: rgba(255,255,255,0.9);
      padding: 10px 0 12px;
      .logo{
        font-size: 1.5rem;
        img{
          width: 80px;
        }
      }
      @include mq('min', 'xs') {
        padding: 20px 0 14px 10px;
        .logo{
          img{
            width: 128px;
          }
        }
      }
    }
		.menu-trigger {
			display: inline-block;
			vertical-align: middle;
			cursor: pointer;
			position: fixed;
      z-index:10001;
			transform: translateX(0);
      @include mq('max', 'xs') {
        width: 22px;
        height: 34px;
        top: 19px;
        right: 18px;
      }
      @include mq('min', 'xs') { //20210219
        width: 25px;
        height: 34px;
        top: 33px;
        right: 40px;
      }
			span {
				display: inline-block;
				box-sizing: border-box;
				position: absolute;
				left: 0;
				width: 100%;
				height: 2px;
				background-color: $color-blue;
				transition: all .5s;
        @include mq('max', 'xs') {
          &:nth-of-type(1) {
            top: 3px;
          }
          &:nth-of-type(2) {
            top: 11px;
          }
          &:nth-of-type(3) {
            top: 19px;
          }
        }
        @include mq('min', 'xs') {
          &:nth-of-type(1) {
            top: 3px;
          }
          &:nth-of-type(2) {
            top: 12px;
          }
          &:nth-of-type(3) {
            top: 21px;
          }
        }
			}
			&.active {
				transform: translateX(0);
				z-index: 9990;
        // right:19px;
        span {
          left: -3px;
          &:nth-of-type(2) {
            opacity: 0;
          }
          @include mq('max', 'xs') {
            &:nth-of-type(1) {
              transform: translateY(8px) rotate(-43deg);
              width: 30px;
            }
            &:nth-of-type(3) {
              transform: translateY(-8px) rotate(43deg);
              width: 30px;
            }
          }
          @include mq('min', 'xs') {
            &:nth-of-type(1) {
              transform: translateY(9px) rotate(42deg);
              width: 28px;
            }
            &:nth-of-type(3) {
              transform: translateY(-9px) rotate(-42deg);
              width: 28px;
            }
          }
        }
			}
		}
    nav{
      background: rgba(255,255,255,0.9);
      display: none;
	  	width: 100vw;
      position: fixed;
			z-index: 9900;
	    transform: translate(-100vw);
	    transition: all .5s;
	    overflow-y:scroll !important;
	    overflow-x:hidden !important;
      padding: 5px 8px 0;
      ul {
        padding-left: 0;
        width: 85%;
        z-index: 2000;
  			li {
  	      a {
  	        color: $color-base;
  	        display: block;
  	        text-decoration: none;
            &:hover{
              text-decoration: none;
            }
  	      }
          &>div.icon-down{
            z-index: 10000;
            cursor: pointer;
            display: block;
            i{
              color: $color-blue;
              padding: 0 .4rem;
              transition: .5s;
            }
            &.icon-rotate{
              i{
                transform: rotateX(180deg);
              }
            }
          }
  	    }
        &#gnav{
          li{
            position: relative;
            span.icon-down{
              padding-left: .4rem;
              color: $color-blue;
            }
            div.pulldown.spread ul {
              display: block;
              border-top: 1px solid $color-base;
              li{
                font-size: 1rem;
                a{
                }
              }
            }
          }
        }
        &#head-nav{
          display: flex;
          width: 75%;
          li{
            width: 50%;
            text-align: center;
            padding: 0;
            a{
              color: #fff;
              font-weight: bold;
              line-height: 1.4;
              padding: 3px 5px 15px;
            }
            img{
              width: 100%;
              margin-bottom: 2px
            }
          }
          #nav-contact{
            a{
              img{
                margin-bottom: 12px
              }
            }
          }
        }
        ul{
          display: none;
        }
      }
      #nav-sns{
        text-align: center;
        padding-bottom: 20px;
        span img{
          width: 77px;
          margin-right: 1rem;
        }
        a{
          color: $color-blue;
          margin:0 .2rem;
          vertical-align: middle;
        }
      }
	    &.open {
        display: block;
        transform: translateX(0);
        overflow:hidden;
        z-index: 9900;
        ul{
  	      box-sizing:border-box;
  		    li {
            list-style: none;
  				}
  	    }
  		}
      @include mq('min', 'xs') {
        width: 480px;
        right: 0;
        height: calc(100vh - 90px);
        top: 90px;
        ul#gnav{
          margin: 60px auto 5px;
          width: 330px;
          li{
            font-size: 1rem;
            padding: .35rem .5rem .25rem;
            div.pulldown.spread ul {
              padding: 8px 0 0 55px;
              margin:5px 16px 7px;
            }
          }
          img{
            width: 80px;
          }
        }
        ul#head-nav{
          position: relative;
          width: 276px;
          margin: 25px auto 25px;
        }
        #nav-sns{
          margin:10px;
          padding-bottom: 70px;
          span img{
            width:92px;
          }
          a{
            font-size: 1.4rem;
          }
        }
      }
      @include mq('max', 'xs') {
  	    height: calc(100vh - 60px);
  	    top: 60px;
        ul#gnav{
    	    margin: 20px auto 5px;
          width: 90.625vw;
          li{
            @include fz_vw(14);
            padding: .2rem .5rem .4rem;
            div.pulldown.spread ul {
              padding: 10px 0 0 46px;
              margin:5px 16px 5px;
              li{
                @include fz_vw(13);
                padding: .1rem .5rem;
                a{
                  padding:.25rem 0 .3rem;
                }
              }
            }
          }
          img{
            width: 21.875vw;
          }
        }
        ul#head-nav{
          position: relative;
          width: 75vw;
          margin: 17px auto 20px;
          li{
            @include fz_vw(14);
            a{
              padding: 6px 7px 13px;
            }
          }
        }
        #nav-sns{
          @include fz_vw(13);
          margin:7px auto;
          span img{
            width: 77px;
          }
          a{
            @include fz_vw(19);
          }
        }
      }
    }
    .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background: rgba(255,255,255,0);
	    position: fixed;
	    top: 0;
	    left: 0;
	    opacity: 0;
	    transition: opacity .5s;
	    top: 0;
      z-index: 0;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
  	    z-index: 9000;
	    }
	  }
  }

}

/* ---------------------------
footer
*/
footer {
  width: 100vw;
  text-align: center;
  background: #0082cd;
  color: #fff;
  a{color: #fff;
    &:hover{
      color: rgba(255,255,255,.8);
    }
  }
  .copyright {
    margin-top: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  @include mq('min', 'md') {
    padding:95px 0 30px;
    #foot-logo img{width: 175px;margin-bottom: 35px;}
    dl{
      font-feature-settings : "palt";margin-bottom: 0;
      dt{margin-bottom: .4rem;font-size: .95rem;letter-spacing: .1rem;}
      dd{letter-spacing: .05rem;margin-bottom: 0.1rem;}
    }
    .banners{
      width: 960px;
      display: flex;
      justify-content: space-between;
      margin:0 auto;
      a{
        margin:60px 0 65px;
        background: #fff;
        img{
          width: 217px;
        }
        &:hover img{
          opacity: .5
        }
      }
    }
    .sns{
      a{
        font-size: 1.8rem;
        display: inline-block;
        margin:.5rem 13px 1.4rem;
      }
    }
    .copyright {
      text-align: right;
      padding-right: 30px;
      font-size: 13px;
    }
    @include mq('max', 'lg') {
      width: 960px;
    }
  }
  @include mq('max', 'md') {
    padding:17.8125vw 0 13.8vw;
    #foot-logo img{
      width: 31.25vw;
      margin-bottom: 7vw;
    }
    dl{
      font-feature-settings : "palt";
      margin-bottom: 9vw;
      dt{
        margin-bottom: 2.7vw;
        @include fz_vw(13);
        letter-spacing: .1rem;
      }
      dd{
        letter-spacing: .05rem;
        margin-bottom: 0;
        @include fz_vw(12);
        line-height: 1.8;
      }
    }
    .banners{
      width:81.25vw;
      margin:0 auto 7.3vw;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      a{
        display: inline-block;
        margin-bottom: 3.125vw;
        img{
          width: 39.0625vw;
        }
      }
    }
    .sns{
      a{
        @include fz_vw(22);
        display: inline-block;
        margin:0 1.35vw 5.4vw;
      }
    }
    .copyright {
      @include fz_vw(11);
    }
  }
}
#page-top {
  position: fixed;
  z-index: 1000;
  // display: none !important;
  @include mq('min', 'md') {
    bottom: 64px;
    right: 35px;
  }
  @include mq('max', 'md') {
    bottom: 15px;
    right: 15px;
    img{
      width: 10.3125vw;
    }
  }
  a {
    display: block;
    text-decoration: none;
  }
}


/* ---------------------------
contents
*/
#contents{
  .container{
    @include mq('max', 'md') {
      padding-right: 9.375vw;
      padding-left: 9.375vw;//30px
      max-width: 100%;
    }
    @include mq('min', 'md') {
      max-width: 1200px;
    }
  }
  section{
    @include mq('min', 'md') {
      padding:60px 0;
    }
  }
  p{
    font-size: 1rem;
    line-height: 1.8;
  }
  h2 {
    margin-top: 0.4rem;
    font-feature-settings : "palt";
  }
  h3 {
    color:$color-blue;
  }
  @include mq('min', 'xl'){
    padding-top: 130px;
  }
  @include mq('max', 'xl'){
    padding-top:90px;
    h2 {
      font-size: 1.4rem;
    }
    h3 {
      font-size: 1.1rem;
    }
    @include mq('min', 'md') {
      h3 {
        font-size: 1.75rem;
        margin-top: 0.4rem;
      }
    }
    @include mq('max', 'md'){
      padding-bottom: 1.5625vw;
    }
    @include mq('max','xs'){
      padding-top: 60px;
    }
  }
  @include mq('min-max', 'md', 'lg'){
    width: 960px;
  }
  @include mq('min','md'){
    h2 {
      font-size: 1.875rem;
      span{
        font-size: 1rem;
      }
    }
    .hover-blue{
      position: relative;
      height: 100%;
    }
    .hover-blue:hover::before{
      background: rgba(0,145,227,.3);
      content:' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      z-index: 0;
    }
    .hover-blue a{
      z-index: 1;
    }
    .hover-transparent{
      position: relative;
      height: 100%;
    }
    .hover-transparent::before{
      background: rgba(0,145,227,.3);
      content:' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      z-index: 0;
    }
    .hover-transparent:hover::before{
      background: rgba(0,145,227,0);
    }
    .hover-transparent a{
      z-index: 1;
    }
  }
  @include mq('max', 'md') {
    h2 {
      @include fz_vw(22);
      span{
        @include fz_vw(12);
      }
    }
    h3{
      @include fz_vw(20);
    }
    p{
      @include fz_vw(13);
      line-height: 1.75;
      letter-spacing: .03rem;
    }
    li{
      @include fz_vw(13);
    }
    table{
      th{
        @include fz_vw(14);
      }
      td{
        @include fz_vw(13);
      }
    }
  }

  #feature{
    .text{
      h2{
        color: $color-blue;
        text-align: center;
      }
      @include mq('min', 'xl1440') {
        max-width: 1200px;
        margin: 0 auto;
      }
      @include mq('min-max', 'lg', 'xl1440') {
        padding: 0 9.722%;
      }
      @include mq('min-max', 'md', 'lg') {
        width:900px;
        margin: 0 auto;
      }
      @include mq('min', 'md') {
        h2{
          font-size: 2rem;
          line-height: 1.65;
          margin-top: 140px;
        }
        p{
          text-align: center;
          line-height: 2;
          margin: 45px 0 65px;
        }
      }
      @include mq('max', 'md') {
        padding: 0 9.375vw;
        h2{
          @include fz_vw(18);
          line-height: 1.7;
          margin-top: 15.625vw;
        }
        p{
          margin-top: 4.6875vw;
          margin-bottom: 9.375vw;
          @include fz_vw(13);
          line-height: 2;
        }
      }
    }
    .images{
      @include mq('min', 'xl1440') {
        max-width: 1200px;
        margin: 0 auto;
      }
      @include mq('min-max', 'lg', 'xl1440') {
        width: 100vw;
        padding: 0 9.722%;
      }
      @include mq('min-max', 'md', 'lg') {
        width: auto;
        padding: 0 9.722%;
        img.main, .sub{
          width: 100%;
          display: block;
          margin-left:auto;
          margin-right: auto;
        }
      }
      @include mq('min', 'md') {
        margin-bottom: 70px;
        img{
          margin-bottom: 15px;
        }
        img.main{
          width: 1200px !important;
        }
        .sub{
          display: flex;
          justify-content: space-between;
          img{
            width: calc(50% - 7.5px);
            height: 66.385%;
          }
        }
      }
      @include mq('max', 'md') {
        margin-bottom: 12.5vw;
        img{
          margin-bottom: 4.6875vw;
        }
        .sub{
          padding: 4.6875vw 9.375vw;
          img{
            margin:1.5625vw auto;
            display: block;
          }
        }
      }
    }
  }
  .heading{
    text-align: center;
    a,a:hover{
      text-decoration: none;
    }
    &#headline{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #eee;
      h1{
        margin-bottom: 1.8rem;
        text-align: center;
        a, a:hover{
          text-decoration: none;
        }
        img{
          height: 100px;
          display: block;
          margin: 0 auto;
        }
        strong{
          padding: 0 0 1rem .4rem;
          display: inline-block;
          font-feature-settings : "palt";
          letter-spacing: 0.3rem;
          font-weight: 500;
        }
        span{
          display: block;
          font-family: 'Montserrat', sans-serif;
          font-feature-settings : "palt";
          letter-spacing: 0.18rem;
          font-weight: 600;
        }
      }
      @include mq('min', 'md') {
        height: 600px;
        h1{
          img{
            height: 100px;
            margin-bottom: 25px
          }
          strong{
            font-size:2.7rem;
            padding: 0 0 1.3rem .4rem;
          }
          span{
            font-size: 1.25rem;
            margin-top: 1.45rem;
          }
        }
      }
      @include mq('max', 'md') {
        height: 112.5vw;
        h1{
          strong{
            @include fz_vw(28);
            letter-spacing: 0.4rem;
            margin-top: 1rem;
            border-bottom: .9375vw solid #fff;
            padding: 0 0 .6rem .4rem;
            font-weight: 500;
          }
          span{
            @include fz_vw(14);
            letter-spacing: 0.1rem;
            margin-top: .9rem;
          }
          img{
            height: 17.8125vw;
          }
        }
      }
    }
    h2{
      a{
        color: $color-base;
        text-decoration: none;
      }
      strong{
        color: $color-base;
        border-bottom: 3px solid $color-blue;
        padding: 0 0 1.1rem .4rem;
        display: inline-block;
        font-feature-settings : "palt";
        letter-spacing: 0.65rem;
        font-weight: 500;
      }
      span{
        display: block;
        color: $color-blue;
        font-size: 1.1rem;
        margin-top: 1.4rem;
        font-family: 'Montserrat', sans-serif;
        font-feature-settings : "palt";
        letter-spacing: 0.1.8rem;
        font-weight: 600;
      }
      img{
        display: block;
        height: 100px;
        margin:0 auto 10px;
      }
      @include mq('min', 'md') {
        margin-bottom: 1.7rem;
        strong{
          letter-spacing: .09rem;
          font-size: 2.2rem;
        }
        @media screen and (min-width: 1700px){
          background-position: 16% 200px;
        }
        @media screen and (max-width: 1400px){
          background-size: 300px;
          background-position: 6% 180px;
        }
      }
      @include mq('max', 'md') {
        margin-bottom: 8.5vw;
        strong{
          @include fz_vw(22);
          letter-spacing: 0.15rem;
          padding: 0 0 2.8vw 2vw;
          font-weight: 500;
          line-height: 1.5;
          border-bottom-width:.9375vw;
        }
        span{
          @include fz_vw(12);
          letter-spacing: 0.1rem;
          margin-top: 4vw;
          line-height: 1.6
        }
        img{
          height: 16.875vw
        }
      }
    }
    &.heading-w{
      h1,h2{
        strong{
          color: #fff;
          border-bottom: 5px solid #fff;
        }
        span{
          color: #fff;
        }
      }
    }
  }
  .viewmore{
    text-align: center;
  }
  a.btn{
    display: inline-block;
    text-align: center;
    color: $color-blue;
    border: 3px solid $color-blue;
    border-radius: 40px;
    padding: .8rem 2rem;
    font-weight: 500;
    font-size: 1.2rem;
    width: 100%;
    font-weight: bold;
    &:hover{
      background: $color-blue;
      color: #fff;
    }
    @include mq('max', 'md'){
      padding: 3.5vw 5vw;
      width: 100%;
      @include fz_vw(15);
      border-radius: 12.5vw;
    }
  }
  .btn-primary{
    font-weight: 300;
  }
  @include mq('min', 'md'){
    .section-block{
      display: flex;
    }
  }
  .iframe{
    position: relative;
    width: 100%;
    padding-top: 600px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border:0;
    }
  }
  #results{
    .section-block{
      flex-wrap: wrap;
      .asset{
        h3{
          color: $color-base;
        }
      }
    }
    @include mq('min', 'md') {
      padding:110px 0 145px;
      .heading{
        margin-bottom: 85px;
      }
      .section-block{
        margin:0 auto;
        .asset{
          margin:0 2.5% 50px;
          width: 20%;
          h3{
            font-size: 1rem;
            margin-top: 1.5rem;
          }
          h4{
            font-size: 1.2rem;
            margin-top: .8rem;
          }
        }
      }
      @include mq('max', 'xl1440') {
        .section-block{
          width:88.474%;
        }
      }
      @include mq('min', 'xl1440') {
        .section-block{
          max-width:1260px;
        }
      }
    }
    @include mq('min', 'xl1560') {
      width:1560px;
      margin: 0 auto;
    }
    #content-nav{
      margin-top:30px;
      a{
        font-size: 1.1rem;
        text-decoration: underline;
      }
    }
    @include mq('max','md'){
      padding:15.625vw 0;
      .heading{
        margin-bottom: 0;
      }
      .assets{
        padding:3.2vw 9.375vw 0;
        text-align: left;
        display: flex;
        justify-content: space-between;
        .asset{
          margin-bottom: 6vw;
          width: calc(50% - 3.125vw);
          h3{
            @include fz_vw(13);
            margin-top: 5vw;
          }
          h4{
            @include fz_vw(16);
            margin-top: 2vw;
          }
        }
      }
      #content-nav{
        margin-top:3.125vw;
          margin-bottom:2vw;
        display: flex;
        @include fz_vw(13);
        height: 5vw;
        overflow: hidden;
        a{
          @include fz_vw(13);
        }
      }
    }
  }

  .topic-block{
    display: flex;
    flex-wrap: wrap;
    margin: 0 20px;
    .topic{
      width: calc(50% - 20px);
      margin:0 10px 20px;
      position: relative;
      .icon-new{
        background: #fff000;
        color: #0080f0;
        border-radius: 5px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        display: inline-block;
        text-align: center;
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
        position: absolute;
        top:-10px;
        left:-10px;
        font-size: 12px;
        padding: 2px 6px;
        border-radius: 2px;
        z-index: 1;
      }
      .icon-new:before {
        content: '';
        position: absolute;
        display: block;
        z-index: 1;
        border-style: solid;
        border-color: #fff000 transparent;
        border-width: 8px 0 0 10px;
        bottom: -8px;
        left: 48%;
        margin-left: -5px;
      }
      a{
        display: block;
        text-decoration: none;
      }
      .thumb{
        position: relative;
      }
      .thumb:hover::after{
        background: rgba(255,255,255,.3);
        content:' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        z-index: 0;
      }
      h3{
        color: $color-base;
        letter-spacing: 0;
        font-weight: bold;
      }
      .cat{
        background: $color-blue;
        color: #fff;
        padding:5px 8px;
        border-radius: 3px;
        font-weight: bold;
        display: inline-block;
        &:hover{
          text-decoration: none;
        }
      }
      @include mq('min', 'md') {
        .thumb{
          margin-bottom: 30px;
        }
        span.date{
          display: block;
          margin-bottom: 10px;
          font-size:13px;
        }
        h3{
          font-size: 1rem;
          line-height: 1.5;
          margin-bottom: 20px;
        }
        .cat{
          font-size:13px;
          margin-bottom: .3rem;
          margin-right: .3rem;
        }
      }
      @include mq('max', 'md') {
        margin-bottom: 9vw;//7.8125vw;
        &:nth-child(1){
          width: 100%;
        }
        span.date{
          @include fz_vw(12);
        }
        h3{
          @include fz_vw(14);
        }
        .cat{
          @include fz_vw(13);
          margin-bottom: .3rem;
          margin-right: .3rem;
        }
      }
    }
    @include mq('min', 'md') {
      margin: 0 6.25%;
      .topic{
        width: 21.428571%;
        margin:0 1.785714% 60px;
      }
    }
  }
}

#appointment{
  @include mq('min-max', 'md', 'lg') {
    width: 960px;
  }
  width: 100%;
  a{
    text-align: center;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    z-index: 0;
    &#appointment-online{
      background:  url(/_uploads/appointment-online-unhover.jpg) no-repeat top center / cover;
    }
    &#appointment-contact{
      background:  url(/_uploads/appointment-contact-unhover.jpg) no-repeat top center / cover;
    }
    .heading{
      text-align: center;
      h2{
        margin-bottom: 1.7rem;
        img{
          display: block;
          margin:0 auto 10px;
        }
        strong{
          color: #fff;
          border-bottom: 5px solid #fff;
          display: inline-block;
          font-feature-settings : "palt";
          font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
          font-weight: 500;
        }
        span{
          display: block;
          color: #fff;
          font-size: 1.3rem;
          margin-top: 1.4rem;
          font-family: 'Montserrat', sans-serif;
          font-feature-settings : "palt";
          letter-spacing: 0.1.8rem;
          font-weight: 600;
        }
      }
    }
  }
  @include mq('min', 'md') {
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      width: 50%;
      height: 500px;
      h2{
        z-index: 100;
        position: relative;
        img{
          height: 130px;
          display: block;
          margin: 0 auto;
        }
        strong{
          letter-spacing: .09rem;
          padding: 0 0 1.1rem .4rem;
          font-size:2.7rem;
          line-height: 1.3;
        }
      }
      // &::after{
      //   // background: rgba(0,145,227,.35);
      //   // background: url('/_uploads/appointment-unhover.png');
      //   content:' ';
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top:0;
      //   left:0;
      //   z-index: 0;
      // }
      // &:hover::after{
      //   // background: rgba(0,145,227,.1);
      //   // background: url('/_uploads/appointment-hover.png');
      // }

      &#appointment-online:hover{
        background:  url(/_uploads/appointment-online-hover.jpg) no-repeat top center / cover;
      }
      &#appointment-contact:hover{
        background:  url(/_uploads/appointment-contact-hover.jpg) no-repeat top center / cover;
      }
    }
  }
  @include mq('max', 'md') {
    a{
      height: 84.375vw;
      &+a{
        margin-top: 5px;
      }
      .heading{
        z-index: 1;
        h2{
          strong{
            @include fz_vw(28);
            font-weight: 500;
            border-bottom-width: 3px;
            line-height: 1.3;
          }
          span{
            @include fz_vw(14);
            letter-spacing: 0.1rem;
            margin-top: 4.75vw;
          }
        }
      }
    }
    #appointment-online{
      .heading h2{
        margin-bottom: 0;
        img{
          height: 21vw;
          margin-bottom: 1.5625vw;
        }
        strong{
          padding:0 7vw 3.5vw;
          letter-spacing: 0.3rem;
        }
      }
    }
    #appointment-contact{
      .heading h2{
        margin-bottom: 4.6875vw;
        img{
          height: 23vw;
          margin-bottom: 8px;
        }
        strong{
          letter-spacing: 0.1rem;
          padding: 0 0 3.5vw 1vw;
        }
      }
    }
  }
}

.results-pager{
  #results{
    .assets{
      display: none !important;
    }
    .assets.show{
      display:  flex !important;
    }
    #content-nav #nav-pager::before{
      content: '｜';
    }
    #content-nav #nav-pager span.pager{
      text-decoration: underline;
      cursor: pointer;
    }
    #content-nav #nav-prev span, #content-nav #nav-next span{
      text-decoration: underline;
      cursor: pointer;
    }
    #content-nav #nav-pager span.pager.active{
      text-decoration: none;
    }
  }
}

/* ---------------------------
ホーム
*/
.home{
  @include mq('min', 'xl') {
    header{
      &.top0{
        background: transparent;
        .title{
          padding: 44px 0 0 46px;
          img{
            display: none;
            &.d-none{
              display: block!important;
              width:206px;
            }
          }
        }
        ul#gnav{
          li{
            a,&>div{
              color: #fff;
              border-right: 1px solid #fff;
              cursor: pointer;
            }
            div.nav-current > span{
              color:$color-blue !important;
            }
            &#nav-rent{
              &>div{
                border-left: 1px solid #fff;
              }
            }
          }
        }
      }
    }
  }
  #contents{
    z-index: 1000 !important;
    position: relative;
    @include mq('min','md') {
      padding: 0;
    }
    @include mq('min-max','xs', 'md') {
      padding-top:90px;
    }
    @include mq('max', 'md') {
      padding-bottom: 0;
    }
    @include mq('max', 'xs') {
      padding-top:60px;
    }
    .heading{
      h1, h2{
        strong{
          color: $color-blue;
          @include mq('min', 'md') {
            border-bottom-width: 5px;
          }
        }
        span{
          color: $color-black;
          @include mq('min', 'md') {
            font-size: 1.3rem;
          }
        }
      }
      &.heading-w{
        h1, h2{
          strong{
            color: #fff;
          }
          span{
            color: #fff;
          }
          @include mq('max', 'md') {
            img{
              height: 18vw;
              margin-bottom: 3.125vw;
            }
            strong{
              @include fz_vw(28);
              border-bottom-width: 3px;
              letter-spacing: 0.4rem;
              padding: 0 0 2vw 2vw;
              font-weight: 500;
            }
            span{
              @include fz_vw(14);
              margin-top: 3.5vw;
            }
          }
        }
      }
    }
    h2{
      a{
        color: $color-base;
        text-decoration: none;
      }
      strong{
        display: inline-block;
        font-feature-settings : "palt";
        font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
        font-weight: 500;
      }
      span{
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-feature-settings : "palt";
        letter-spacing: 0.1rem;
        font-weight: 600;
      }
      @include mq('min', 'md') {
        strong{
          font-size: 3rem;
          padding: .2rem 0 1.4rem .4rem;
          letter-spacing: 0.65rem;
        }
        span{
          margin-top: 1.6rem;
        }
        img{
          height: 100px;
        }
      }
      @include mq('max', 'md') {
        strong{
          @include fz_vw(28);
          letter-spacing: 0.4rem;
          padding: 0 0 3.5vw 2vw;
          border-bottom-width: 3px;
        }
        span{
          @include fz_vw(14);
          margin-top: 4.75vw;
        }
      }
    }
    h3{
      line-height: 1.7;
      font-size: 2rem;
      margin-bottom: 1.9rem;
      letter-spacing: 0.2rem;
      font-feature-settings : "palt";
    }
    p{
      line-height: 2.4;
      letter-spacing: 0.01rem;
      color: $color-black;
    }
    .btn{
      letter-spacing: 0.2rem;
      font-feature-settings : "palt";
      padding:1.3rem;
    }
    #feature{
      padding:0;
      .slick-dots{
        li{
          width:50px ;
          height: 8px;
          button{
            width:50px ;
            height: 8px;
            &:before{
              content:'　';
              background:rgba(55,55,55,.3);
              width:50px;
              height: 8px;
              opacity: 1;
            }
          }
          &.slick-active button:before{
            opacity: 1;
            background:#0080f0;
          }
        }
        @include mq('min', 'md') {
          bottom:-55px;
          li{
            margin:0 .9rem;
          }
        }
        @include mq('max', 'md') {
          bottom:-36px;
          li{
            width:5.9375vw;
            height: .9375vw;
            button{
              width:5.9375vw;
              height: .9375vw;
              &:before{
                width:5.9375vw;
                height: .9375vw;
              }
            }
          }
        }
      }
      @include mq('min', 'md') {
        height: 870px;
        margin-bottom: 140px !important;
        img{
          width: 870px;
        }
        .slick-arrow{
          width:60px;
          height: 90px;
          color:#fff;
          &:before{
            font-size: 6rem;
            top:calc(50% - 80px);
            width:60px;
            color:#fff;
            opacity: 1;
            font-weight: 900;
          }
        }
        .slick-prev{
          left:calc(50% - 470px);
          z-index: 1;
          &:before{
            font-family: 'Font Awesome 5 Free';
            content:'\f053';
          }
        }
        .slick-next{
          right:calc(50% - 470px);
          text-align: right;
          &:before{
            font-family: 'Font Awesome 5 Free';
            content:'\f054';
          }
        }
        @include mq('max', 'xl') {
          height: 67.96875vw;
          img{
            width: 67.96875vw;
          }
          .slick-prev{
            left:calc(17% - 40px);
          }
          .slick-next{
            text-align: right;
            right:calc(17% - 40px);
          }
        }
      }

      @include mq('max', 'md') {
        img{
          width:100vw
        }
        .slick-arrow{
          width:45px;
          height: 70px;
          color:#fff;
          &:before{
            @include fz_vw(30);
            top:calc(50% - 70px);
            width:45px;
            color:#fff;
            opacity: 1;
            font-weight: 900;
          }
        }
        .slick-prev{
          left:2px;
          z-index: 1;
          text-align: left;
          &:before{
            font-family: 'Font Awesome 5 Free';
            content:'\f053';
          }
        }
        .slick-next{
          right:2px;
          text-align: right;
          &:before{
            font-family: 'Font Awesome 5 Free';
            content:'\f054';
          }
        }
      }
    }
    #rent{
      .left{
        background: url(/_uploads/home-rent-bg.jpg) no-repeat center center/ cover;
        height: 84.375vw;
        a{
          display: block;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      @include mq('min', 'md') {
        position: relative;
        margin-bottom: 50px;
        padding-top: 40px;
        .section-block{
          justify-content: space-between;
          align-items: center;
        }
        .left{
          width:calc(86.4585% - 400px);
          height: 600px;
          order: 1;
          &:hover{
            background-image: url(/_uploads/home-rent-bg-o.jpg);
          }
          h2{
            strong{
              letter-spacing: .4rem;
            }
            img{
              margin:0 auto 20px;
            }
          }
        }
        .right{
          width:400px;
          margin-left:5.729%;
          margin-right:7.8125%;
          margin-bottom: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 2;
          p{
            letter-spacing: .04rem;
            margin-bottom: 4rem;
          }
        }
        .apaman{
          position: absolute;
          right: 0;
          bottom:70px;
          width:400px;
          margin-right:7.8125%;
        }
      }
      @include mq('max', 'md') {
        .right{
          padding:9.375vw 9.375vw 55px;
          h3{
            text-align: center;
            @include fz_vw(18);
            margin-top:4vw;
            margin-bottom:6vw;
            letter-spacing:.1rem;
          }
          p{
            line-height: 2;
            margin-bottom: 2vw;
            letter-spacing:.02rem;
          }
        }
        .apaman{
          padding:9.375vw 9.375vw 3.125vw;
          a{
            padding: .7rem;
            @include fz_vw(15);
            border-radius: 80px
          }
        }
      }
    }
    #buyandsell{
      .right{
        background: url(/_uploads/home-buyandsell-bg.jpg) no-repeat center center/ cover;
        height: 84.375vw;
        a{
          display: block;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      @include mq('min', 'md') {
        position: relative;
        padding-bottom: 142px;
        padding-top:0;
        .section-block{
          justify-content: space-between;
          align-items: center;
        }
        .right{
          width:calc(84.062% - 480px);
          height: 480px;
          margin-right: 7.865%;
          order: 2;
          &:hover{
            background-image: url(/_uploads/home-buyandsell-bg-o.jpg);
          }
          h2{
            margin-bottom: 0;
            img{
              height:100px;
            }
          }
          @include mq('max','xl') {
            width:calc(91.927% - 480px);
            margin-right: 0;
          }
        }
        .left{
          width:400px;
          margin-left:8.073%;
          margin-right:80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 1;
          h3{
            margin-top: 18px;
          }
        }
      }
      @include mq('max', 'md') {
        .left{
          padding:9.375vw;
          h3{
            text-align: center;
            @include fz_vw(18);
            margin-top:4vw;
            margin-bottom:6vw;
            letter-spacing:.1rem;
          }
          p{
            line-height: 1.8;
            margin-bottom: 2vw;
            letter-spacing: .03rem;
          }
        }
        .right{
          h2{
            img{
              height:16.875vw;
            }
            @include mq('max', 'md') {
              margin-bottom: 0;
              strong{
                line-height: 1.25;
                padding-bottom: 4vw;
              }
            }
          }
        }
      }
    }
    #specialcontents-h{
      width:31.875vw;
      margin:25px auto 0;
    }
    #specialcontents{
      padding-bottom: 20px;
      .heading{
        @include mq('min', 'md') {
          padding: 80px 0 65px;
          background: url(/_uploads/home-specialcontents-bg.png) no-repeat 80% bottom / 248px;
          @include mq('max', 'xl') {
            background-position:  90% bottom;
          }
          strong{
            color: $color-blue;
            border-bottom-color: $color-blue;
            letter-spacing: .09rem;
            font-size: 2.8rem;
          }
          span{
            color: $color-black;
          }
        }
        @include mq('max', 'md') {
          h2{
            margin: 0 0 13.5vw;
            padding-top: 15.625vw;
            strong{
              @include fz_vw(25);
            }
            span{
              @include fz_vw(14);
            }
            img{
              height: 16.875vw
            }
          }
        }
      }
      .banners{
        .banner{
          text-align: center;
          background: #fff;
          min-height: 100px;
          margin-bottom:5px;
          a:hover img{
            opacity: .7;
          }
        }
        @include mq('min', 'md') {
          display: flex;
          flex-wrap: wrap;
          margin: 0 8px;
          .banner{
            width:calc(50% - 16px);
            margin:0 8px 16px;
          }
        }
      }
    }
    #topics{
      padding-bottom: 20px;
      #topics-h-image{
        width:54vw;
        margin: 0 auto;
        padding:10px 0 0;
        display: block;
      }
      .heading{
        h2{
          margin-top:0;
        }
        img{
          // padding: 60px 0 25px;
          margin:0 auto;
        }
        @include mq('min', 'md') {
          padding: 55px 0;
          background: url(/_uploads/home-topics-bg.png) no-repeat 16% 30px / 390px;
          h2{
            strong{
              letter-spacing: .09rem;
              font-size: 2.8rem;
            }
          }
          @media screen and (max-width: 1600px){
            background-size: 300px;
            background-position: 6% 100px;
          }
          @include mq('max', 'xl') {
            background-size: 220px;
            background-position: 6% 140px;
          }
        }
        @include mq('max', 'md') {
          h2{
            margin: 0 0 13.5vw;
            padding-top: 15.625vw;
            strong{
              @include fz_vw(25);
              letter-spacing: .15rem;
            }
            span{
              @include fz_vw(14);
            }
          }
        }
      }
      .topic{
        @include mq('max', 'md') {
          .icon-new{
            @include fz_vw(10);
            padding: .625vw 1.5625vw;
            left:-1.5625vw;
            top:-1.875vw;
          }
          &:first-child{
            .icon-new{
              @include fz_vw(14);
              padding: .9375vw 2.1875vw;
              letter-spacing: .02rem;
            }
          }
          .thumb{
            margin-bottom: 3.125vw;
          }
          h3{
            margin-bottom: .3rem;
            line-height: 1.5;
          }
          .cat{
            @include fz_vw(10);
            padding: 1.25vw 2.1875vw;
            margin-bottom: .3rem;
            margin-right: .3rem;
          }
          &:nth-child(n+6){
            display: none;
          }
        }
      }
      .viewmore{
        a{
          max-width: 400px;
          padding:.82rem;
        }
      }
      @include mq('min', 'md') {
        padding-bottom: 230px;
        // @media (min-width: 1900px){
          background: #e0f2fc url(/_uploads/home-topics-bottom-bg.png) no-repeat bottom right / 457px;
        // }
        .viewmore{
          margin-top: 28px;
          a{
            background: #e0f2fc;
            &:hover{
              background: $color-blue;
            }
          }
        }
      }
      @include mq('max', 'md') {
        padding-bottom: calc( 72.76vw + 40px);
        background: #e0f2fc url(/_uploads/home-topics-bottom-bg-sp.png) no-repeat bottom right / contain;
        .viewmore{
          padding-right:30px;
          padding-left: 30px;
          margin-top: 3.125vw;
        }
      }
    }
  }
  #sns{
    margin-top: -160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      background: #f2f9fe;
      @include mq('min', 'md') {
        width:180px;
        height: 180px;
      }
      position: relative;
      &:hover::before{
        background: rgba(255,255,255,.3);
        content:' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        z-index: 0;
      }
    }
    @include mq('max', 'md') {
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: space-between;
      margin:-18.75vw 6.25vw 15.625vw;
      a{
        width:37.5vw;
        height:37.5vw;
        padding-top: calc((14% - 3.125vw) / 2);
        padding-bottom: calc((14% - 3.125vw) / 2);
        margin-bottom: 6.25vw;
        &#sns-line{
          width:100%;
        }
        img{
          display: block;
          width: 100%;
        }
      }
    }
  }
  #menu{
    a{
      text-decoration: none;
      h2{
        strong{
          letter-spacing: .3rem;
        }
      }
      &#menu-resident .bg-image{
        background: url(/_uploads/home-menu-resident.jpg) no-repeat top center / cover;
      }
      &#menu-company .bg-image{
        background: url(/_uploads/home-menu-company.jpg) no-repeat top center / cover;
      }
      &#menu-recruit .bg-image{
        background: url(/_uploads/home-menu-recruit.jpg) no-repeat top center / cover;
      }
      .bg-image{
        position: relative;
        z-index: -1;
        &::after{
          background: rgba(0,145,227,.2);
          content:' ';
          position: absolute;
          width: 100%;
          height: 100%;
          top:0;
          left:0;
          z-index: 0;
        }
      }
    }
    @include mq('min', 'md') {
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 120px;
      a{
        width: 28.05%;
        margin: 0 8px;
        text-align: center;
        .bg-image{
          height:330px;
          position: relative;
        }
        &:hover .bg-image::after{
          background: rgba(0,145,227,.1);
        }
        .heading{
          margin-top: -53px;
          position: relative;
          z-index: 100;
        }
        h2{
          img{
            height: 90px;
            margin-bottom: 5px;
            z-index: 100;
          }
          strong{
            font-size: 2.25rem;
            border-bottom-width: 3px;
            padding-bottom: .7rem;
          }
          span{
            font-size: 1.1rem;
            margin-top: .9rem;
          }
        }
      }
    }
    @include mq('max', 'md') {
      margin-bottom: 18.75vw;
      a{
        display: block;
        text-align: center;
        margin-bottom: 6.25vw;
        .bg-image{height:62.5vw;}
        .heading{
          margin-top: -14.0625vw;
          margin-bottom: 13.125vw;
        }
        h2{
          img{
            height: 18.75vw;
            margin-bottom: 2.5vw;
          }
          strong{
            @include fz_vw(25);
            border-bottom-width: 3px;
            margin-top: 0;
            padding-bottom: 3vw;
          }
        }
      }
    }
  }
}

/* ---------------------------
借りたい
*/
.for-rent{
  #contents{
    @include mq('max', 'md') {
      padding-bottom: 1.5625vw;
    }
    #headline{
      @include mq('min', 'md') {
        background: url(/_uploads/for-rent-headline-bg.jpg) no-repeat center center / cover;
        h1{
          strong{
            letter-spacing: .65rem;
          }
        }
      }
      @include mq('max', 'md') {
        background: url(/_uploads/for-rent-headline-bg-sp.jpg) no-repeat center center / cover;
      }
    }
    #feature{
      @include mq('min', 'md') {
        padding-top:0;
      }
      .text{
        @include mq('min', 'md') {
          padding: 0;
          width:auto;
        }
        @include mq('max', 'md') {
          padding: 0 4.5vw;
        }
      }
    }
    #concept{
      .section-block{
        justify-content: space-between;
        flex-wrap: wrap;
        .block{
          h3{
            padding:30px 0 10px;
          }
        }
      }
      .viewmore{
        a{
          max-width: 480px;
        }
      }
      #concept-contact{
        background: #fff;
        flex-direction: column;
        h3{
          text-align: center;
          color: $color-base;
          line-height: 1.6;
        }
        @include mq('min', 'md') {
          margin-top: 25px;
          padding: 85px 20px 100px;
          margin-bottom: 0;
          h3{
            margin-bottom: 2rem;
          }
        }
        @include mq('max', 'md') {
          margin:5.5vw 9.375vw;
          background: #fff;
          padding:11vw 9.375vw 12vw ;
          h3{
            text-align: left;
            margin-bottom: 6.25vw;
            @include fz_vw(16);
            letter-spacing: .02rem;
            line-height: 1.65;
            br{
              display: none;
            }
          }
          a{
            @include fz_vw(15);
            padding: 3.5vw;
            display: block;
            max-width: inherit;
            width: 100%;
          }
        }
      }
      @include mq('min', 'md') {
        padding:145px 0 75px;
        .heading{
          margin-bottom: 85px;
        }
        .section-block{
          .block{
            p{
              margin-bottom: 2.2rem;
            }
            .viewmore{
              a{
                padding: .8rem 2rem;
              }
            }
          }
        }
        @include mq('max', 'xl1440') {
          .section-block{
            margin:0 5.763%;
            .block{
              margin:0 3% 74px;
              width: 27%;
            }
          }
        }
        @include mq('min', 'xl1560') {
          width:1560px;
          margin: 0 auto;
        }
        @include mq('min', 'xl1440') {
          .section-block{
            margin:0 auto;
            box-sizing: border-box;
            width: 1200px;
            .block{
              margin:0 0 99px;
              width: 350px;
            }
          }
        }
      }
      @include mq('max', 'md') {
        padding:14.5vw 0 10.5vw;
        .heading{
          margin-bottom: 20px;
        }
        .section-block{
          display: block;
          .block{
            margin:0 auto;
            padding:4.6875vw 9.375vw 8.7vw;
            h3{
              padding: 8.7vw 0 1.5625vw;
            }
            p{
              margin-bottom: 8vw
            }
          }
        }
      }
    }
    #results{
      .section-block{
        a{
          display: block;
          .thumb{
            overflow: hidden;
          }
        }
      }
      #recommend-contact{
        background: #fff;
        flex-direction: column;
        h3{
          text-align: center;
          color: $color-base;
          line-height: 1.6;
        }
        .viewmore{
          a{
            max-width: 480px;
          }
        }
        @include mq('min', 'md') {
          margin-top: 125px;
          padding: 85px 20px 100px;
          margin-bottom: 5px;
          h3{
            margin-bottom: 2rem;
          }
          .viewmore{
            a{
              padding: .8rem 2rem;
              margin: 5px auto;
            }
          }
        }
        @include mq('max', 'md') {
          margin:14vw 9.375vw 4vw;
          background: #fff;
          padding:11vw 9.375vw 12vw ;
          h3{
            text-align: left;
            margin-bottom: 6.25vw;
            @include fz_vw(16);
            letter-spacing: .02rem;
            line-height: 1.65;
            br{
              display: none;
            }
          }
          a{
            @include fz_vw(15);
            padding: 3.5vw;
            display: block;
            max-width: inherit;
            width: 100%;
          }
        }
      }
      @include mq('min', 'md') {
        padding-top: 145px;
      }
      @include mq('max', 'md') {
        .heading{
          padding-bottom: 5px;
        }
      }
    }
    #management{
      .section-block{
        justify-content: space-between;
        flex-wrap: wrap;
        .left{
          h3{
            color: $color-base;
            line-height: 1.6
          }
        }
      }
      @include mq('min', 'md') {
        padding:140px 0 75px;
        margin-bottom: 178px;
        .heading{
          margin-bottom: 80px;
        }
        .right{
          order:2;
        }
        .left{
          h3{
            letter-spacing: .15rem;
            margin-bottom: 1.4rem;
          }
          p{
            margin-bottom: 2rem;
          }
        }
        @include mq('max', 'xl1440') {
          .section-block{
            margin:0 5.625%;
            .block{
              margin:0 3% 74px;
              width: 43.97%;
            }
          }
        }
        @include mq('min', 'xl1560') {
          width:1560px;
          margin: 0 auto 178px;
        }
        @include mq('min', 'xl1440') {
          .section-block{
            margin:0 180px;
            box-sizing: border-box;
            width: 1200px;
            .block{
              margin:0 0 74px;
              width: 562px;
              .viewmore{
                a{
                  padding: .8rem 2rem;
                }
              }
            }
          }
        }
      }
      @include mq('max', 'md') {
        padding: 13.6vw 9.375vw 19vw;
        .heading{
          h2{
            margin-bottom: 6vw;
          }
        }
        img{
          margin-bottom: 7.9vw;
        }
        h3{
          margin-bottom: 4vw;
          @include fz_vw(16);
          letter-spacing: .135rem
        }
        p{
          margin-bottom: 8.75vw;
          line-height: 1.76;
        }
      }
    }
  }
}

/* ---------------------------
買いたい・売りたい
*/
.buy-and-sell{
  #contents{
    #headline{
      @include mq('min', 'md') {
        background: url(/_uploads/buyandsell-headline-bg.jpg) no-repeat center center / cover;
      }
      @include mq('max', 'md') {
        background: url(/_uploads/buyandsell-headline-bg-sp.jpg) no-repeat center center / cover;
        h1{
          margin-bottom: 6vw;
          strong{
            padding: 0 0 3.75vw 2vw;
            line-height: 1.25;
            margin-top: 3vw;
            letter-spacing: .2rem;
          }
        }
      }
    }
    #feature{
      @include mq('min', 'md') {
        padding-top:0;
      }
      @include mq('max', 'md') {
        padding: 0;
      }
      .text{
        @include mq('min', 'md') {
          padding: 0;
          width:auto;
        }
        @include mq('max', 'md') {
          padding: 0 4.5vw;
          h2{
            margin-top: 14.625vw;
          }
        }
      }
    }
    #estate{
      .section-block{
        justify-content: space-between;
        flex-wrap: wrap;
        .left{
          h3{
            color: $color-base;
            line-height: 1.7
          }
        }
      }
      .cmp{
        background: #fff;
      }
      @include mq('min-max', 'md', 'xl1440') {
        .section-block{
          margin:0 5.625%;
          .block{
            margin:0 3% 74px;
            width: 43.97%;
          }
          .cmp{
            margin:0 3%;
            h3{
              letter-spacing: .06rem;
            }
          }
        }
      }
      @include mq('min', 'xl1560') {
        width:1560px;
        margin: 0 auto;
      }
      @include mq('min', 'xl1440') {
        .section-block{
          margin:0 180px;
          box-sizing: border-box;
          width: 1200px;
          .block{
            width: 562px;
          }
        }
      }
      @include mq('min', 'md') {
        padding:140px 0 145px;
        margin-bottom: 35px;
        .heading{
          h2 strong{
            padding-left:3rem;
            padding-right:3rem;
          }
        }
        .section-block{
          .block{
            margin-bottom:74px;
            h3{
              margin-bottom: 1.4rem;
              letter-spacing: .15rem;
            }
            p{
              margin-bottom: 3rem;
              line-height: 2;//20210219
            }
            .viewmore{
              a{
                padding: .8rem 2rem;
              }
            }
          }
        }
        .section-block+.section-block{
          margin-top: 40px;
        }
        .heading{
          margin-bottom: 80px;
        }
        .right{
          order:2;
        }
        .left{
          a+a{
            margin-top: 25px;
          }
        }
        .cmp{
          padding:7.333%;
          .cmp-block{
            display: flex;
            justify-content: space-between;
          }
          h3{
            color: $color-base;
            line-height: 1.6;
          }
          p{
            line-height: 2;
          }
          .image, .text{
            width: calc(50% - 30px;);
          }
          a{
            padding: 1rem 2rem;
          }
        }
      }
      @include mq('max', 'md') {
        padding: 14.75vw 9.375vw;
        .heading{
          margin-bottom: 10.9375vw;
        }
        img{
          margin-bottom: 7.8125vw;
        }
        h3{
          margin-bottom: 3vw;
          @include fz_vw(16);
        }
        p{
          margin-bottom: 8.75vw;
        }
        .viewmore{
          margin-bottom: 8.75vw;
          a{
            padding: 3vw;
            margin-bottom: 15px;
          }
        }
        .cmp{
          padding: 11vw 9.375vw 13vw;
          margin-bottom: 4.6875vw;
          h3{
            color: $color-base;
            line-height: 1.7;
            text-align: left !important;
            @include fz_vw(15);
            margin-bottom: 0;
            br{
              display: none;
            }
          }
          .cmp-block{
            margin-top: 5.625vw;
          }
          img{
            margin-bottom: 20px;
          }
          p{
            margin-bottom: 1rem;
          }
          a{
            padding: .5rem 2rem;
            font-size: .95rem;
          }
        }
      }
    }
  }
}


/* ---------------------------
スペシャルコンテンツ
*/
.special-contents{
  #contents{
    #headline{
      @include mq('min', 'md') {
        height: 740px;
        h1{
          margin-bottom: 0 !important;
          img{
            margin-bottom: 0 !important;
            height:auto;
            width: 870px;
          }
        }
      }
      @include mq('max', 'md') {
        height: 85vw;
        h1{
          margin-bottom: 0;
          img{
            height:auto;
            width: 100vw;
          }
        }
      }
    }
    #feature{
      p{
        line-height: 1.8;
        &.copy{
          color: $color-blue;
          text-align: center;
        }
      }
      h2{
        color: $color-base;
        text-align: center;
        strong{
          border-bottom: solid $color-blue;
          padding:.6rem 0;
        }
      }
      @include mq('min', 'md'){
        p{
          &.copy{
            font-size: 2rem;
            margin:75px 0 1.5rem;
          }
          &.lead{
            line-height: 2;
          }
        }
        h2{
          font-size: 2.8rem;
          margin: 0 0 3.1rem;
          strong{
            border-bottom-width: 5px;
            padding:.6rem 0;
            letter-spacing: .3rem;
            font-weight: 500;
          }
        }
        .images{
          margin-bottom: 80px;
        }
      }
      @include mq('max', 'md'){
        h2 strong{
          font-weight: 500;
        }
        p{
          line-height: 2;
          &.copy{
            margin:14.75vw 0 4.6vw;
            @include fz_vw(18);
            line-height: 1.7;
          }
          &.lead{
            letter-spacing: .02rem;
            br{
              display: none;
            }
          }
        }
        h2{
          @include fz_vw(25);
          margin: 0 0 13vw;
          letter-spacing: .2rem;
          strong{
            border-bottom-width: 3px;
            padding:0 0 4.5vw;
          }
        }
        .images{
          margin: 10.9375vw 0 12vw;
        }
      }
      .viewmore{
        @include mq('max', 'md'){
          margin: 0 9.375vw 18.75vw;
        }
        @include mq('min', 'md'){
          padding-bottom: 100px;
          max-width: 450px;
          margin: -65px auto 0;
        }
      }
    }
    #results{
      @include mq('min', 'md'){
        padding-top: 140px;
        margin-bottom: 145px;
      }
      @include mq('max', 'md'){
        padding: 15.625vw 0;
        h2 strong{
          letter-spacing: .2rem;
          padding-left: .1rem;
        }
      }
    }
    &.omoshiro-chintai{
      #headline{
        img{
          padding: 0 7% 0 15%;
          box-sizing: border-box;
        }
      }
      #feature .text{
        @include mq('min-max', 'lg', 'xl1440') {
          padding: 0 8%;
        }
      }
    }
    &.sumitsuku{
      #feature{
        .images{
          @include mq('max', 'md'){
            margin-bottom:2.25vw;
          }
        }
        .viewmore{
          @include mq('min', 'md'){
            margin: -20px auto 0;
          }
        }
      }
    }
    &.regnoah_{
      #feature{
        @include mq('min', 'md'){
          p.lead{
            letter-spacing: .13rem;
          }
        }
        .images{
          @include mq('max', 'md'){
            margin-bottom:2.25vw;
          }
        }
        .viewmore{
          @include mq('min', 'md'){
            margin: -20px auto 0;
          }
        }
      }
    }
    &.sumaino-shoushu{
      #feature{
        @include mq('min', 'md'){
          .images{
            .sub{
              img.img1{
                width: calc(33.95% - 7.5px);
              }
              img.img2{
                width: calc(66.04% - 7.5px);
              }
            }
          }
          .viewmore{
            margin-top: -20px;
            padding-bottom: 85px;
          }
        }
        @include mq('max', 'md'){
          .images{
            margin:4.6875vw 0 10.9735vw;
            .sub{
              width: 100%;
              padding:0;
              img{
                width:100%;
              }
            }
          }
          .viewmore{
          }
        }
      }
    }
    &.pet-kyousei-jyutaku{
      #feature{
        @include mq('max', 'md'){
          .images{
            margin-bottom:2vw;
          }
        }
        @include mq('min', 'md'){
          .viewmore{
            max-width: 600px;
          }
        }
      }
    }
    &.coin-laundry{
      #feature{
        .kind{
          dt{
            color: $color-blue;
            font-weight: 500;
            img{
              border:1px solid #80c8f1;
            }
          }
        }
        @include mq('min', 'md'){
          .kind{
            margin:0 30px 100px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 130px;
            dl{
              width: 29.5%;
              dt{
                font-size: 28px;
                letter-spacing: .1rem;
                margin-bottom: 1rem;
                img{
                  margin-bottom: 2rem;
                }
              }
              dd{
                line-height: 2;
                font-size: 1rem;
                letter-spacing: .03rem;
              }
            }
            @include mq('min', 'xl1440') {
              max-width: 1200px;
              margin: 0 auto 45px;
            }
            @include mq('min-max', 'lg', 'xl1440') {
              // width: 100vw;
              margin:0 0 45px;
              padding: 0 9.722%;
            }
            @include mq('max', 'lg') {
              width: 880px;
              margin: 0 auto 45px;
            }
          }
        }
        @include mq('max', 'md'){
          p.lead{
            margin-bottom: 6vw;
          }
          .images{
            margin: 10vw 0;
          }
          .kind{
            margin:0 9.375vw 17vw;
            dl{
              margin-bottom: 11vw;
              dt{
                @include fz_vw(20);
                margin-bottom: 5.2vw;
                img{
                  margin-bottom: 8vw;
                }
              }
              dd{
                @include fz_vw(14);
                margin-bottom: 5vw;
                line-height: 1.7;
              }
            }
          }
        }
      }
      #results{
        #locations{
          .location{
            border-bottom: 1px solid $color-base;
            strong{
              display: block;
              font-weight: bold;
            }
          }
          p{
            font-weight: bold;
            color: $color-blue;
          }
          @include mq('min', 'md'){
            padding-top: 60px;
            .location-block{
              display: flex;
              flex-wrap: wrap;
              .location{
                margin:0 2.94% 1rem;
                width: 27.45%;
                padding-top:2.1rem;
                padding-bottom: 1.5rem;
                strong{
                  font-size: 1.1rem;
                  margin-bottom: .3rem;
                }
              }
            }
            p{
              margin-top: 65px;
              text-align: center;
              width: 100%;
              margin-bottom: 0;
            }
            @include mq('max', 'xl1440'){
              margin:0 7.28%;
            }
            @include mq('min', 'xl1440'){
              .location-block{
                width:1275px;
                margin:0 auto;
                .location{
                }
              }
            }
          }
          @include mq('max', 'md'){
            .location-block{
              margin: 4vw 9.375vw;
              .location{
                padding-top: 7vw;
                padding-bottom: 5vw;
                @include fz_vw(13);
                color: #212121;
                strong{
                  color: $color-base;
                  @include fz_vw(15);
                  margin-bottom: 2vw;
                }
              }
            }
            p{
              margin: 8.5vw 9.375vw 1.5625vw;
              @include fz_vw(13);
            }
          }
        }
        @include mq('min', 'md'){
          padding-bottom: 175px;
          margin-bottom: 110px;
        }
      }
    }
    &.regcon{
      #feature{
        @include mq('min', 'md') {
          padding-bottom: 45px;
        }
      }
      #results{
        #locations{
          .location{
            border-bottom: 1px solid $color-base;
            strong{
              display: block;
              font-weight: bold;
            }
          }
          p{
            font-weight: bold;
            color: $color-blue;
          }
          @include mq('min', 'md'){
            padding-top: 60px;
            .location-block{
              display: flex;
              flex-wrap: wrap;
              .location{
                margin:0 2.94% 1rem;
                width: 27.45%;
                padding-top:2.1rem;
                padding-bottom: 1.5rem;
                strong{
                  font-size: 1.1rem;
                  margin-bottom: .3rem;
                }
              }
            }
            p{
              margin-top: 65px;
              text-align: center;
              width: 100%;
              margin-bottom: 0;
            }
            @include mq('max', 'xl1440'){
              margin:0 7.28%;
            }
            @include mq('min', 'xl1440'){
              .location-block{
                width:1275px;
                margin:0 auto;
                .location{
                }
              }
            }
          }
          @include mq('max', 'md'){
            .location-block{
              margin: 4vw 9.375vw;
              .location{
                padding-top: 7vw;
                padding-bottom: 5vw;
                @include fz_vw(13);
                color: #212121;
                strong{
                  color: $color-base;
                  @include fz_vw(15);
                  margin-bottom: 2vw;
                }
              }
            }
            p{
              margin: 8.5vw 9.375vw 1.5625vw;
              @include fz_vw(13);
            }
          }
        }
        @include mq('min', 'md'){
          padding-bottom: 132px;
          margin-bottom: 150px;
        }
        @include mq('max', 'md'){
          .heading{
            h2{
              margin-bottom:11.5vw;
            }
          }
        }
      }
    }
  }
}

/* ---------------------------
実績詳細
*/
.results-detail{
  main{
    width: 100%;
  }
  #contents{
    #headline{
      position: relative;
      &::after{
        background: rgba(0,0,0,.25);
        content:' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        z-index: 0;
      }
      h1{
        z-index: 1;
        span{
          font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
          font-weight: 500;
        }
      }
      @include mq('min', 'md') {
        h1{
          max-width: 1200px;
          margin-left:120px;
          margin-right: 120px;
          span{
            font-size: 2rem;
            margin-bottom: 1.75rem;
          }
          strong{
            font-size: 2.8rem;
            letter-spacing: .3rem;
            padding-bottom: .9rem;
            margin-bottom: 11px;
          }
        }
      }
      @include mq('max', 'md') {
        height: 84.375vw;
        h1{
          margin-right: 30px;
          margin-left: 30px;
          span{
            @include fz_vw(18);
          }
          strong{
            @include fz_vw(25);
            letter-spacing: .1rem;
            margin-top: 6vw
          }
        }
      }
    }
    @include mq('max', 'md') {
      margin-bottom: 5px;
    }
    #feature{
      width:100%;
      h2{
        color: $color-blue;
        text-align: center;
      }
      @include mq('min', 'md') {
        padding-bottom: 0;
        h2{
          font-size: 2rem;
          line-height: 1.6875;
          margin-top: 75px;
        }
        p{
          text-align: center;
          line-height: 2;
          margin: 40px 0 35px;
        }
      }
      @include mq('max', 'md') {
        h2{
          @include fz_vw(18);
          line-height: 1.7;
          margin-top: 42px;
        }
        p{
          margin-top: 6.25vw;
          @include fz_vw(13);
          line-height: 2;
          margin-bottom: 10vw;
        }
      }
    }
    #deg360{
      @include mq('min', 'md') {
        padding-bottom: 0;
      }
      #a3DTHETA-youtube{
        .iframe{
          padding-top: 56.25%;
        }
      }
      #a3DTHETA{
        @include mq('min', 'md') {
          margin-bottom: 100px;
        }
        @include mq('max', 'md') {
          margin-bottom: 9.375vw;
        }
      }
      #a3DTHETA + #a3DTHETA-youtube{
        @include mq('min', 'md') {
          margin: 0 auto 100px;
          .iframe{
            margin-bottom: 20px;
          }
        }
        @include mq('min-max', 'md', 'xl1560') {
          margin:0 5.625% 100px;
        }
        @include mq('min', 'xl1560') {
          width:1560px;
          padding:0 5.625%;
        }
        @include mq('max', 'md') {
          margin:9.375vw 0;
          .iframe{
            margin-bottom: 10px;
          }
        }
      }
    }
    #movie-photo{
      .iframe{
        padding-top: 56.25%;
      }
      @include mq('min', 'md') {
        padding:0;
        .iframe, img{
          margin-bottom: 100px;
        }
        @include mq('max', 'xl1440') {
          margin:0 5.625%;
        }
        @include mq('min', 'xl1440') {
          width: 1200px;
          margin:0 auto;
        }
      }
      @include mq('max', 'md') {
        margin:9.375vw 0 3.125vw;
        .iframe, img{
          margin-bottom: 3.125vw;
        }
      }
    }
    #detail{
      .section-block{
        justify-content: space-between;
        flex-wrap: wrap;
        .left{
          border:solid $color-base;
          border-width: 1px 0;
          padding:30px 0;
          position: relative;
          h3{
            color: $color-base;
            line-height: 1.6
          }
          .entry-body{
            padding-left:1rem;
            p{
              margin-bottom: 1rem;
            }
          }
          .entry-body-switch{
            color: $color-blue;
            font-size: 1.1rem;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #e0f2fc;
            width: 100%;
            padding:20px 15px 25px;
            cursor: pointer;
            strong{
              font-weight: bold;
            }
            display: none;
          }
          &.spread .entry-body{
            margin-bottom: 3rem;

          }
          .entry-more{
            padding-left:1rem;
            .more-switch{

            }
            .more-block{
              display: none;
            }
          }
        }
      }
      #images{
        &>div{
          position: relative;
          .over{
            background: rgba(51,51,51,.5);
            position: absolute;
            height: 100%;
            width: 100%;
            top:0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            flex-direction: column;
            strong{
              display: block;
            }
            @include mq('min', 'md') {
              font-size: 20px;
              strong{
                font-size: 36px;
              }
            }
            @include mq('max', 'md') {
              @include fz_vw(15);
              strong{
                @include fz_vw(22);
              }
            }
          }
          &:nth-child(n+5){
            display: none;
          }
        }
      }
      @include mq('min', 'md') {
        padding:0 0 20px;
        .section-block{
          margin: 0 auto;
          .block{
            margin:0 0 54px;
            h3{
              margin-bottom: 1.4rem;
            }
            p{
              margin-bottom: 3rem;
            }
            .viewmore{
              a{
                padding: .8rem 2rem;
              }
            }
          }
        }
        .section-block+.section-block{
          margin-top: 40px;
        }
        .right{
          order:2;
          img{
            display: block;
          }
        }
        .left{
          a+a{
            margin-top: 25px;
          }
        }
        #images{
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          &>div{
            width: calc(50% - 8px);
            margin-bottom: 16px;
            position: relative;
            img{
              width:100%;
              height: auto;
            }
          }
        }
        @include mq('max', 'xl1440') {
          .section-block{
            margin:0 5.625%;
            .block{
              width: 43.97%;
              margin:0 0 54px;
              width: calc(50% - 30px);
            }
          }
        }
        @include mq('min', 'xl1560') {
          width:1560px;
          margin: 0 auto;
        }
        @include mq('min', 'xl1440') {
          .section-block{
            // margin:0 180px;
            box-sizing: border-box;
            width: 1200px;
            .block{
              width: 562px;
            }
          }
        }
      }
      @include mq('max', 'md') {
        padding: 8vw 9.375vw;
        .section-block{
          .left{
            .entry-body-switch{
              @include fz_vw(15);
              padding-bottom: 9.375vw;
            }
            p{
              @include fz_vw(13);
              line-height: 2;
            }
          }
          .right{
            padding-bottom: 12.5vw;
          }
        }
        #images{
          margin:12.5vw 0 4.6875vw;
          &>div{
            margin-bottom: 1.5625vw;
          }
        }

        p{
          margin-bottom: 1vw;
        }
      }
    }
    #map{
      #gmap{
        padding-top: 550px;
      }
      .viewmore{
        @include mq('min', 'md') {
          padding: 70px 0 90px;
          max-width: 450px;
          margin: 0 auto;
        }
        @include mq('max', 'md') {
          padding: 9.375vw 0 14.0625vw;
          margin: 0 9.375vw;
          a{
            @include fz_vw(15);
            font-weight: bold;
            width: 100%;
          }
        }
      }
    }
  }
  .smartphoto{
    z-index: 10000;
    background: rgba(0,0,0,.8);
    .smartphoto-header{
      background: none;
      .smartphoto-count{
        display: none;
      }
    }
    .smartphoto-arrows li{
      background: transparent;
      width:50px;
      height: 80px;
      top:calc(45% - 40px);
      margin-top: 0;
      a{
        background: none;
        &:before{
          font-family: 'Font Awesome 5 Free';
          font-size: 80px;
          line-height: 1;
          color:rgba(255,255,255,.9);
          font-weight: 900;
        }
      }
    }
    .smartphoto-arrow-left{
      left: 20px;
      a:before{
        content:'\f053';
      }
    }
    .smartphoto-arrow-right{
      right: 20px;
      a:before{
        content:'\f054';
      }
    }
    @include mq('max', 'md') {
      .smartphoto-arrows li{
        width:25px;
        height: 40px;
        top:calc(45% - 20px);
        a{
          &:before{
            font-size: 40px;
          }
        }
      }
      .smartphoto-arrow-left{
        left: 5px;
      }
      .smartphoto-arrow-right{
        right: 5px;
      }
    }
  }
}


/* ---------------------------
お知らせ
*/
.news{
  #contents{
    #headline{
      h1{
        strong{
          color: $color-blue;
          border-bottom-color: $color-blue;
        }
        span{
          color: $color-base
        }
      }
      @include mq('min', 'md') {
        background: url(/_uploads/news-headline-bg.png) no-repeat center center / cover;
        height: 600px;
        h1{
          strong{
            border-bottom: 5px solid;
            letter-spacing: .3rem;
          }
        }
      }
      @include mq('max', 'md') {
        background: url(/_uploads/news-headline-bg-sp.png) no-repeat center center / cover;
        h1{
          strong{
            margin-top: 0;
          }
        }
      }
    }
    .main{
      .icon-new{
        background: #fff000;
        color: #09f;
        padding:5px 10px 3px;
        border-radius: 5px;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        display: inline-block;
        text-align: center;
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
      }
      .icon-new:before {
         content: '';
         position: absolute;
         display: block;
         z-index: 1;
         border-style: solid;
         border-color: #fff000 transparent;
         border-width: 10px 0 0 20px;
         bottom: -10px;
         left: 48%;
         margin-left: -10px;
      }
      .entry-meta{
        margin-bottom: 1rem;
        span+span{
          margin-left: 1rem;
        }
        .category{
          background: #0082cd;
          color: #fff;
          padding:5px 10px;
          border-radius: 5px;
          font-weight: bold;
          font-size: .8rem;
          margin-left: 1rem;
          display: inline-block;
          &:hover{
            text-decoration: none;
          }
        }
      }
      .entry-content{
        // overflow-x: scroll;
      }
    }
    .side{
      aside{
        margin-bottom: 70px;
        h3{
          color: $color-base;
          font-size: 24px;
          border-bottom: 1px solid $color-base;
          padding-bottom: 1.6rem;
          margin-bottom: 0;
          font-feature-settings : "palt";
          letter-spacing: 0.2rem;
          span{
            color: $color-blue;
            font-size: 55%;
            padding-left: .8rem;
            font-family: 'Montserrat', sans-serif;
            letter-spacing: 0.1rem;
            font-weight: 600;
          }
        }
        ul{
          list-style: none;
          padding-left: 0;
          li{
            border-bottom: 1px solid $color-base;
            a{
              display: block;
              text-decoration: none;
              span.date{
                display: block;
                letter-spacing: .05rem;
              }
            }
          }
        }
        &.widget-archive-category{
          ul{
            li{
              span{
                display: block;
              }
            }
          }
        }
        .select{
          margin-top: 40px;
          position:relative;
          width:100%;
          border: 1px solid #373737;
          box-sizing: border-box;
          background: #fff;
          select {
            appearance: none;
            border: none;
            outline: none;
            background: linear-gradient(90deg, #fff 0 82%, $color-blue 82% 100%);
            padding:10px 50px 10px 10px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 0;
            &::-ms-expand {
              display: none;
            }
          }
          &::before,
          &::after {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none; // Arrow clickable in some browsers

            // triangle set up
            border: 1px solid transparent; // reset all borders
            width: 0;
            height: 0;
            right: 16px;
          }
          // top arrow
          &::before {
            bottom: 55%;
            border-width: 0 6.5px 8px 6.5px;
            border-bottom-color: #fff;
          }
          // bottom arrow
          &::after {
            border-width: 8px 6.5px 0 6.5px;
            border-top-color: #fff;
            top: 55%;
          }
        }
      }
    }
    @include mq('min', 'md') {
      .section-block{
        display: flex;
        justify-content: space-between;
        .main{
          order:2;
          width: calc(92.5% - 270px);
        }
        .side{
          width:270px !important;
          order:1;
          aside{
            margin-bottom: 75px;
            ul{
              li{
                line-height: 1.4;
                a{
                  padding: 1.65rem 0;
                  span.date{
                    margin-bottom: .5rem;
                    font-size: .7rem;
                  }
                }
              }
            }
          }
          .widget-recent-entries{
            ul{
              li{
                a{
                  padding: 1.6rem 0 1.5rem;
                }
              }
            }
          }
          .widget-archive-monthly-dropdow{
            margin-bottom: 0;
          }
          .widget-archive-category{
            ul{
              li{
                span{
                  padding: 1.65rem 0;
                }
              }
            }
          }
          .select{
            margin-top: 40px;
          }
        }
      }
      @include mq('min-max', 'md', 'xl1440') {
        .section-block{
          margin:0 8.47% 0 8.26%;
          padding: 145px 0 140px;
        }
      }
      @include mq('min', 'xl1440') {
        .section-block{
          width: 1200px;
          margin: 0 auto;
          padding:140px 0;
          .main{
            width: calc(92.5% - 270px);
          }
        }
      }
    }
    @include mq('max', 'md') {
      .section-block{
        padding:14.0625vw 9.375vw 5vw;
        .main, .side{
          width:100%;
        }
        .main{
          padding-bottom: 25%;
          .entry-meta{
            .category{
              margin:0 2.5vw 1.5625vw;
              &+.category{
                margin-bottom: 1.5625vw;
                margin-left:0;
              }
            }
          }
        }
        .side{
          aside{
            margin-bottom: 12.5vw;
            h3{
              padding-bottom: 5vw;
              @include fz_vw(19);
              span{
                @include fz_vw(12);
              }
            }
            ul{
              li{
                line-height: 1.6;
                a{
                  padding: 5.5vw 0;
                  span.date{
                    margin-bottom: 1.3vw;
                    @include fz_vw(12);
                  }
                  span.post-title{
                    @include fz_vw(14);
                  }
                }
              }
            }
            &.widget-archive-category{
              ul{
                li{
                  span{
                    padding: 5.5vw 0;
                  }
                }
              }
            }
            .select{
              margin-top: 9.375vw;
            }
          }
        }
      }
    }
  }
}
/* お知らせ一覧 */
.news-list{
  #contents{
    .main{
      .news-block{
        border-top: 1px solid #333;
        @include mq('max', 'md') {
          padding-top: 35px;
        }
        @include mq('min', 'md') {
          padding-top: 27px;
        }
        .topic{
          border-bottom: 1px solid #373737;
          position: relative;
          span.date{
            font-size: 12px;
            display: inline-block;
            margin-right: 1rem;
            letter-spacing: .05rem;
          }
          span.cat a{
            font-size: 12px;
            padding:2px 5px;
            display: inline-block;
            background: $color-blue;
            border-radius: 3px;
            color:#fff;
            text-decoration: none;
          }
          h2, h3{
            color: $color-base;
          }
          @include mq('min', 'md') {
            display: flex;
            margin:0 0 30px;
            padding-bottom: 30px;
            .flag{
              position: absolute;
              top: -10px;
              left: -10px;
              .icon-new{
                font-size: 13px;
                padding:2px 5px 1px;
              }
              .icon-new:before {
                 border-width: 5px 0 0 10px;
                 bottom: -5px;
                 left: 48%;
                 margin-left: -4px;
              }
            }
            .meta{
              margin-bottom: 19px;
              span.cat{
                margin-bottom: 5px;
              }
            }
            .thumb{
              width:150px;
              margin-right:30px;
              a{
                max-height:100px;
                overflow: hidden;
                display: block;
              }
            }
            .text{
              width:calc( 100% - 180px);
              h2, h3{
                font-size: 1.5rem;
                line-height: 1.4;
                a{
                  text-decoration: none;
                }
              }
            }
          }
          @include mq('max', 'md') {
            .icon-new{
              position: absolute;
              border-radius: 3px;
              @include fz_vw(12);
              padding: .625vw 1.5625vw;
              left: -3.125vw;
              top: -3.125vw;
            }
            .icon-new:before {
              border-width: 5px 0 0 10px;
              bottom: -5px;
              left: 48%;
              margin-left: -4px;
            }
            .thumb{
              img{
                margin-bottom: 15px;
              }
            }
            .meta{
              margin-bottom: 13px;
              span.date{
                @include fz_vw(12);
              }
              span.cat{
                margin-bottom: 5px;
                a{
                  @include fz_vw(12);
                }
              }
            }
            h2, h3{
              @include fz_vw(16);
              line-height: 1.6;
            }
            padding-bottom: 10.9375vw;
            margin-bottom: 12.5vw;
          }
        }
      }
      #content-nav{
        display: flex;
        @include mq('min', 'md') {
          padding:140px 0;
        }
        @include mq('max', 'md') {
          height: 5vw;
          overflow: hidden;
          @include fz_vw(13);
          .col-3{
            padding:0;
          }
          a{
            text-decoration: underline;
          }
        }
      }
    }
  }
}
/* お知らせ詳細 */
.news-detail{
  #contents{
    .main{
      .entry-title{
        border-bottom: 1px solid $color-base;
        h1{
          font-weight: 500;
        }
      }
      .entry-widget{
        .share{
          color: $color-blue;
          font-weight: 600;
          font-family: 'Montserrat', sans-serif;
          #share-twitter{
            color:#1ab1f4;
            vertical-align: middle;
          }
          #share-facebook{
            color:#0079e6;
            vertical-align: middle;
          }
        }
        .copy{
          color: $color-blue;
          font-weight: bold;
          margin-bottom: .7rem;
        }
        dl{
          dt{
            margin-bottom: .8rem;
          }
          dd{
            line-height: 1.8;
            a{
              color: $color-blue;
            }
          }
        }
      }
    }
    @include mq('min', 'md') {
      .section-block{
        .main{
          .entry-meta{
            margin-bottom: 2.4rem;
            .icon-new{
              font-size: 1.1rem;
            }
            .date{
              letter-spacing: .1rem;
              font-size: 14px;
            }
          }
          .entry-title{
            padding-bottom: 2.2rem;
            margin-bottom: 4rem;
            h1{
              font-size:28px;
            }
          }
          .entry-content{
            p{
              line-height: 2;
              margin-bottom: 1.9rem;
            }
          }
          .entry-widget{
            .share{
              font-size: 1.2rem;
              margin: 3.2rem 0 3.6rem;
              #share-twitter, #share-facebook{
                font-size: 2.8rem;
              }
            }
          }
        }
      }
      .content-nav{
        margin-top: 110px;
        font-size: 1.1rem;
        a{
          text-decoration: underline;
        }
      }
    }
    @include mq('max', 'md') {
      .section-block{
        .main{
          .entry-meta{
            margin-bottom: 8vw;
            .icon-new{
              margin-bottom: 6.25vw;
              @include fz_vw(12);
              padding:5px 7px 3px;
              border-radius: 2px;
            }
            .date{
              @include fz_vw(12);
            }
            .date::before {
              content: "\A" ;
              white-space: pre;
            }
            .category{
              padding:3px 8px;
              @include fz_vw(12);
            }
          }
          .entry-title{
            margin-bottom: 10vw;
            padding-bottom: 2vw;
            h1{
              line-height: 1.5;
              @include fz_vw(17);
            }
          }
          .entry-content{
            p{
              line-height: 1.8;
              margin-bottom: 7vw;
            }
          }
          .entry-widget{
            .share{
              @include fz_vw(13);
              margin: 0 0 5vw;
              #share-twitter, #share-facebook{
                @include fz_vw(34);
              }
            }
            .copy{
              @include fz_vw(13);
              margin-bottom: 1.5625vw;
            }
            dl{
              margin-bottom: 3rem;
              dt{
                margin-bottom: 1.5625vw;
                @include fz_vw(14);
              }
              dd{
                line-height: 1.6;
                @include fz_vw(12);
                a{
                  text-decoration: underline;
                }
              }
            }
            .content-nav{
              @include fz_vw(13);
              margin: 0 0 1vw;
              &>div{
                padding:0;
                a{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ---------------------------
入居者さま
*/
.for-resident{
  #contents{
    #headline{
      @include mq('min', 'md') {
        background: url('/_uploads/for_resident-bg.jpg') no-repeat center center / cover;
      }
      @include mq('max', 'md') {
        background: url('/_uploads/for_resident-bg-sp.jpg') no-repeat center center / cover;
      }
    }
    .assets{
      border-top:1px solid #000;
      .asset{
        @include mq('min', 'md') {
          margin: 15px 0;
        }
        border-bottom:1px solid #000;
        h3{
          color: $color-base;
        }
      }
    }
    @include mq('max', 'md') {
      .assets{
        padding-top:3vw;
        .asset{
          text-align: center;
          padding-bottom:9.735vw;
          margin-bottom: 7.8125vw;
          padding-left:9.375%;
          padding-right:9.375%;
          h3{
            margin: 5vw;
            @include fz_vw(17);
            line-height: 1.5;
          }
          a{
            padding:2.8vw 5vw;
          }
        }
      }
    }
    @include mq('min', 'md') {
      .container{
        padding-bottom: 20px;
      }
      .assets{
        padding-top: 40px;
        .asset{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 45px;
          padding-bottom:40px;
          h3{
            font-size: 1.8rem;
            width: 71.77%;
          }
          a{
            // padding:10px 0;
            text-align: center;
            font-size: 1.1rem;
            width:28.23%;
            height: 60px;
          }
        }
      }
    }
    #feature{
      @include mq('min', 'md') {
        padding-bottom: 5px;
        .container{
          h2{
            margin-top:75px;
          }
          p{
            margin-top:52px;
            margin-bottom: 60px;
          }
        }
      }
      @include mq('max', 'md') {
        .container{
          padding:0 0 9.375vw;
        }
        p{
          br{
            display: none;
          }
        }
      }
    }
    #rules{
      @include mq('min', 'md') {
        .heading{
          padding: 140px 0 50px;
        }
        .section-block{
          padding:0 8.3% 150px;
        }
        .block{
          display: flex;
          justify-content: space-between;
          width: 100%;
          &> div{
            width: calc(50% - 30px;);
          }
          .text{
            order:1;
            h3{
              color: $color-base;
              line-height: 1.6;
              margin-bottom: 1.5rem;
              margin-top: .7rem;
              letter-spacing: .18rem;//20210219
            }
            p{
              margin-bottom: 3.3rem;
              line-height: 2;
              font-size: 1rem;
            }
          }
          .images{
            order:2;
          }
        }
        @include mq('min', 'xl1440') {
          .section-block{
            width:1440px;
            margin:0 auto;
          }
        }
        @include mq('min', 'xl1560') {
          max-width: 1560px;
          margin:0 auto;
        }
      }
      @include mq('max', 'md') {
        .bg-blue{
          padding: 13vw 9.375vw 18.75vw;
        }
        .heading{
          padding-bottom: 3.125vw;
          h2{
            strong{
              letter-spacing: .1rem;
            }
          }
        }
        .block{
          .text{
            h3{
              color: $color-base;
              margin-top: 9vw;
              line-height: 1.7;
              @include fz_vw(16);
            }
            p{
              @include fz_vw(13);
              line-height: 1.8;
            }
            a{
              margin-top: 3.125vw;
            }
          }
        }
      }
    }
    #procedures{
      @include mq('min', 'md') {
        .heading{
          padding: 20px 0 50px;
        }
        .section-block{
          p{
            text-align: center;
            width: 100%;
            line-height: 2;
          }
        }
        .assets{
          margin-left:8.333%;
          margin-right:8.333%;
          @include mq('min', 'xl1440') {
            margin-left:0;
            margin-right:0;
          }
          margin-top: 75px;
          margin-bottom: 70px;
          .asset{
            padding-left:13.75%;
            padding-right:13.75%;
          }
        }
      }
      @include mq('max', 'md') {
        .heading{
          padding: 14.0625vw 0 2vw;
        }
        .section-block{
          margin-bottom: 12vw;
          p{
            line-height: 1.8;
          }
        }
        .assets{
          margin-bottom: 18.75vw;
          .asset{
            h3{
              @include fz_vw(17);
              line-height: 1.5;
            }
          }
        }
      }
    }
    #problems{
      @include mq('max', 'md') {
        .bg-blue{
          padding: 14.0625vw 9.375vw 10.9375vw;
        }
        .heading{
          padding: 0 0 2vw;
        }
        .block{
          p{
            text-align: left;
            margin-bottom: 10.9375vw;
          }
          h3{
            margin-bottom: 2rem;
            @include fz_vw(16);
          }
        }
        .section-block{
          border-top:1px solid #000;
          margin-top: 9.735vw;
          padding-top: 12.5vw;
          h4{
            margin: 8.25vw 0 5vw;
            color: $color-blue;
            @include fz_vw(20);
            font-weight: 500;
            line-height: 1.4
          }
          .block{
            p{
              margin-bottom: 12vw;
            }
          }
        }
      }
      @include mq('min', 'md') {
        padding-top: 0;
        .heading{
          padding: 145px 0 20px;
        }
        .block-content{
          padding:0 8.3% 100px;
          &>.block{
            padding:30px 0;
            p{
              line-height: 1.9;
              margin-bottom: 85px;
            }
            h3{
              margin-bottom: 40px;
            }
          }
        }
        .section-block{
          padding:60px 0 30px;
          justify-content: space-between;
            border-top: 1px solid $color-base;
          &+.section-block{
            margin-top: 30px;
          }
          .left{
            width:33.333%;
          }
          .right{
            width:61.42%;
            h4{
              color: $color-blue;
              font-size: 28px;
              margin :8px 0 1rem;
              font-weight: 500;
            }
            p{
              margin-bottom: 0;
              line-height: 1.9;//20210219
            }
          }
          &+.assets{
            margin-top: 35px;
            .asset{
              padding-left:13.75%;
              padding-right:13.75%;
            }
          }
        }
        @include mq('min', 'xl1440') {
          .block-content{
            width:1440px;
            margin:0 auto;
            padding:0 120px 100px;
          }
        }
        @include mq('min', 'xl1560') {
          max-width: 1560px;
          margin:0 auto;
        }
      }
    }
    #contact{
      @include mq('max', 'md') {
        .container{
          padding-top: 14.0625vw;
          padding-bottom: 14.0625vw;
          h2{
            line-height: 1.8;
            margin-bottom: 7.8125vw;
            @include fz_vw(18);
          }
        }
      }
      @include mq('min', 'md') {
        padding:40px 0 80px;
        .container{
          h2{
            line-height: 1.9;
          }
          p{
            max-width: 400px;
            margin: 35px auto;
          }
        }
      }
    }
  }
}

/* ---------------------------
会社概要
*/
.company{
  #contents{
    #headline{
      background: url('/_uploads/company-bg.jpg') no-repeat center center / cover;
    }
    table{
      border-top: 1px solid #000;
      tr {
        border-bottom: 1px solid #000;
        th, td{
          border: 0;
          vertical-align: middle;
        }
        th{
          white-space: nowrap;
        }
      }
      @include mq('min', 'md') {
        margin:60px 0;
        tr{
          th,td{
            padding:1.9rem 0;
          }
        }
      }
    }
    #feature{
      h2{
        color: $color-blue;
        text-align: center;
        line-height: 1.8;
      }
      .section-block{
        .text{
          p{
            text-align: left;
            margin:0 0 1rem;
          }
        }
      }
      @include mq('min', 'md') {
        .container{
          padding-left: 0;
          padding-right:0;
          margin:0;
        }
        margin:0 auto;
        h2{
          font-size: 2rem;
          line-height: 1.6875;
          margin: 75px 0 50px;
        }
        @include mq('max', 'xl1440') {
          max-width: 83.4%;
        }
        @include mq('min', 'xl1440') {
          max-width: 1200px;
        }
        .section-block{
          display: flex;
          justify-content: space-between;
          &>div{
            width: calc( 50% - 30px);
            margin:0;
            @include mq('max', 'xl1440') {
              padding: 0;
            }
          }
          .text{
            .copy{
              font-size: 28px;
              margin-top: 15px;
              margin-bottom: 35px;
              line-height: 1.6;
              letter-spacing: .1rem;
            }
            p{
              letter-spacing: .1rem; //20210219
              font-feature-settings : "palt";
            }
            .name{
              font-size: 28px;
              margin-top: 30px;
              letter-spacing: .25rem;
              span{
                font-size: 1rem;
                letter-spacing: .1rem;
              }
            }
          }
        }
      }
      @include mq('max', 'md') {
        padding:12.5vw 0 10.9375vw;
        h2{
          margin-bottom: 10vw;
          @include fz_vw(18);
          line-height: 1.7;
        }
        .section-block{
          .images{
            margin-bottom: 3.125vw;
          }
          .text{
            padding-right: 0;
            padding-left: 0;
            .copy{
              @include fz_vw(18);
              margin-bottom:8vw;
              line-height: 1.7;
              text-align: center;
            }
            .name{
              @include fz_vw(18);
              margin-top: 6vw;
              letter-spacing: .2rem;
              text-align: center;
              span{
                @include fz_vw(13);
                letter-spacing: .1rem;
              }
            }
          }
        }
      }
    }
    #mission{
      .block{
        h3{
          color: $color-base;
        }
        p{
          line-height:2;
        }
      }
      #chara{
        background: #fff;
        h3{
          color: $color-base;
          margin-bottom: 2.7rem;
           &+p{
             line-height: 2;
           }
        }
        h4{
          color: $color-blue;
          margin-bottom: 18px;
        }
        table{
          border-top: 1px solid #000;
          margin-bottom: 25px;
          tr{
            border-bottom: 1px solid #000;
            th,td{
              padding:1rem .8rem;
              font-size: 1rem;
            }
          }
        }
      }

      @include mq('min', 'md') {
        .bg-blue{
          padding:145px 0 150px;
        }
        h2 strong{
          letter-spacing: .3rem;
        }
        .block{
          h3{
            margin-top: 80px;
            margin-bottom: 40px;
            letter-spacing: .2rem;
          }
        }
        #cm{
          margin:80px 8.3333% 125px;
          @include mq('min', 'xl1440') {
            width: 1200px;
            margin-left:auto;
            margin-right:auto;
          }
        }
        #chara{
          padding:10px 7.333% 20px;
          margin:120px 8.3333% 0;
          @include mq('min', 'xl1440') {
            width: 1200px;
            margin-left:auto;
            margin-right:auto;
          }
          .section-block{
            justify-content: space-between;
            padding:45px 0;
          }
          .left{
            width: 36.666%;
            margin-top: 50px;
          }
          .right{
            width: 56.56%;
          }
          table{
            margin-top: 0;
          }
        }
        @include mq('max', 'xl1560') {
          max-width: 100%;
          padding: 0;
        }
        @include mq('min', 'xl1560') {
          max-width: 1560px;
        }
      }
      @include mq('max', 'md') {
        padding-left: 0;
        padding-right: 0;
        .bg-blue{
          padding:14.0625vw 9.375vw 18.75vw;
          h3{
            margin-top: 10.9375vw;
            margin-bottom: 6.25vw;
            @include fz_vw(16);
            line-height: 1.7;
          }
          p{
            @include fz_vw(13);
            text-align: left !important;
            line-height: 1.8;
          }
          #cm{
            margin:12vw 0 15vw;
          }
          #chara{
            margin: 12.5vw 0 0;
            padding: 12.5vw 9.375vw 6vw;
            h3{
              margin-top: 0;
              margin-bottom: 3.5vw;
              line-height: 1.7;
              &+p{
                text-align: left !important;
                line-height: 1.8;
                margin-bottom: 1vw;
                br{
                  display: none;
                }
              }
            }
            .left{
              text-align: center;
              img{
                margin:6.25vw auto 9.375vw;
              }
            }
            .right{
              h4{
                @include fz_vw(16);
                font-weight: 500;
                margin-bottom: 4.785vw
              }
              table{
                margin-top: 4.785vw;
                tr{
                  margin-bottom: .7vw;
                }
                th,td{
                  display: block;
                  width:100%;
                  padding: 0;
                }
                th{
                  padding-top: 3.5vw;
                  padding-bottom: .7vw;
                  @include fz_vw(13);
                }
                td{
                  padding-bottom: 3.5vw;
                  @include fz_vw(13);
                }
              }
            }
          }
        }
      }
    }
    #outline{
      .block{
        h3{
          color: $color-base;
          text-align: center;
        }
        table{
          tr{
            th{
              color: $color-blue;
            }
          }
        }
      }
      @include mq('min', 'md') {
        margin:23px 0 0;
        padding-bottom:40px;
        .container{
          padding-left: 0;
          padding-right: 0;
        }
        .heading{
          margin-bottom: 85px;
          h2{
            strong{
              padding-left:2.7rem;
              padding-right:2.7rem;
            }
          }
        }
        .section-block{
          p{
            line-height: 1.9;
            text-align: center;
            width: 100%;
          }
        }
        .block{
          margin: 60px 0 0;
          h3{
            color: $color-base;
            text-align: center;
            font-size: 1.875rem;
          }
          table{
            tr{
              th{
                color: $color-blue;
                width: 404px;
                font-weight: 500;
                vertical-align: middle;
                font-size: 1.4rem;
                padding-left: 2rem;
              }
              td{
                line-height: 1.8;
                a{
                  text-decoration: underline;
                }
              }
            }
          }
        }

        @include mq('max', 'xl1440') {
          margin-left:8.3%;
          margin-right:8.3%;
        }

        @include mq('min', 'xl1440') {
          margin-left:auto;
          margin-right:auto;
        }
      }
      @include mq('max', 'md') {
        padding:15.625vw 0 14.0625vw;
        h2{
          margin-bottom: 2.2rem;
          strong{
            padding-left:11vw;
            padding-right:11vw;
          }
        }
        p{
          margin-bottom: 12vw;
          line-height: 1.8;
          br{
            display: none;
          }
        }
        h3{
          @include fz_vw(16);
          margin-bottom: 8vw;
          font-feature-settings: 1rem;
        }
        table{
          th,td{
            display: block;
            width:100%;
            padding: 0;
          }
          th{
            padding-top: 4.6875vw;
            padding-bottom: 1.5625vw;
            font-weight: 500;
            @include fz_vw(17);
          }
          td{
            padding-bottom: 4.6785vw;
            @include fz_vw(14);
            line-height: 1.75;
            letter-spacing: .09rem;
            a{
              text-decoration: underline;
            }
          }
        }
      }
    }
    #overview{
      .block{
        padding-bottom: 30px;
      }
      table{
        margin-bottom: 0;
      }
      @include mq('min', 'md') {
        margin-bottom: 85px;
        padding: 40px 0 65px;
        .bg-blue{
          padding:145px 0 95px;
        }
        .heading{
          margin-bottom: 90px;
          h2{
            strong{
              padding-left:2.7rem;
              padding-right:2.7rem;
            }
          }
        }
        .block{
          padding:0 8.3%;
          @include mq('min', 'xl1440') {
            padding:0 180px;
          }
        }
        table{
          margin-top: 95px;
          margin-bottom: 70px;
          th{
            width:249px;
            line-height: 1.8;
            padding-left: 2rem;
          }
          td{
            font-size: 1rem;
            line-height: 1.8;
          }
        }
        .banners{
          display: flex;
          justify-content: space-between;
          margin: 0 auto;
          @media (min-width: 1150px){
            padding: 0;
            max-width: 960px;
          }
          @media (max-width: 1149px){
            width:100%;
          }
          a{
            width:30.2%;
            margin:5px 0;
            display: inline-block;
            img{
              width:100%;
            }
          }
        }
        @include mq('max', 'xl1560') {
          max-width: 100%;
        }
        @include mq('min', 'xl1560') {
          max-width: 1560px;
        }
      }
      @include mq('max', 'md') {
        padding-left: 0;
        padding-right: 0;
        .bg-blue{
          padding-top:15.625vw;
          h2{
            margin-bottom: 11.5vw;
            strong{
              padding-left:11vw;
              padding-right:11vw;
            }
          }
          table{
            margin: 12vw auto 3.125vw;
            width: 81.25vw;
            th,td{
              display: block;
              width:100%;
              padding: 0;
            }
            th{
              padding-top: 4.6875vw;
              padding-bottom: 1.5625vw;
              @include fz_vw(14);
              letter-spacing: .05rem
            }
            td{
              padding-bottom: 5vw;
              @include fz_vw(13);
              line-height: 1.6;
              letter-spacing: .03rem;
              a{
                text-decoration: underline;
              }
            }
          }
          .banners{
            padding-bottom: 6vw;
            a{
              margin: 0 9.375vw 6.25vw;
              display: block;
              img{
                max-width:100%;
              }
            }
          }
        }
        #gmap.iframe{
          padding-top:109.375vw;
          iframe{
            border:0;
          }
        }
      }
    }
  }

}

/* ---------------------------
採用情報
*/
.recruit{
  #contents{
    @include mq('max', 'md') {
      padding-bottom: 0;
    }
    table{
      border-top: 1px solid #000;
      tr {
        border-bottom: 1px solid #000;
        th, td{
          border: 0;
        }
        th{
          white-space: nowrap;
        }
      }
      @include mq('min', 'md') {
        margin:60px 0;
        tr{
          th,td{
            padding:2rem 0;
          }
          th{
            width: 292px;
            padding-left:2rem;
            letter-spacing: .1rem;
          }
        }
      }
      @include mq('max', 'md') {
        margin:9.375vw 0;
        tr{
          th,td{
            display: block;
          }
          th{
            padding:5vw 0 1.15625vw;
            letter-spacing: .1rem;
            @include fz_vw(14);
          }
          td{
            padding: 0 0 5vw;
            @include fz_vw(13);
            line-height: 1.6;
            letter-spacing: .1rem;
          }
        }
      }
    }
    #headline{
      @include mq('min', 'md') {
        background: url(/_uploads/recruit-bg.jpg) no-repeat center center / cover;
      }
      @include mq('max', 'md') {
        background: url(/_uploads/recruit-bg-sp.jpg) no-repeat center center / cover;
      }
    }
    #feature{
      h2{
        color: $color-blue;
        text-align: center;
        line-height: 1.8;
      }
      @include mq('min', 'md') {
        padding-bottom: 120px;
        h2{
          margin: 70px 0 50px;
          font-size: 32px;
        }
        p{
          text-align: center;
          line-height: 2;
        }
      }
      @include mq('max', 'md') {
        padding:40px 0;
        h2{
          margin-bottom: 1rem;
          @include fz_vw(18);
          line-height: 1.7
        }
        p{
          line-height: 2;
          letter-spacing: 0;
          br{
            display: none;
          }
        }
      }
    }
    #new{
      @include mq('min', 'md') {
        margin-bottom: 150px;
        padding: 0;
        .bg-blue{
          padding:140px 8.333% 90px;
          .heading{
            margin-bottom: 80px;
          }
        }
        @include mq('min', 'xl1440') {
          .bg-blue{
            padding-left:180px;
            padding-right:180px;
          }
        }
        @include mq('max', 'xl1560') {
          max-width: 100%;
        }
        @include mq('min', 'xl1560') {
          max-width: 1560px;
        }
      }
      @include mq('max', 'md') {
        padding-left: 0;
        padding-right: 0;
        .bg-blue{
          padding:15.625vw 9.375vw 10.9375vw;
        }
      }
    }
  }
}

/* ---------------------------
オンライン面談予約
*/
.online-reservation{
  #contents{
    @include mq('max', 'md') {
      padding-bottom: 5px;
      .heading{
        h2{
          strong{
            letter-spacing: .1rem;
          }
        }
      }
    }
    table{
      border-top: 1px solid #000;
      tr {
        border-bottom: 1px solid #000;
        th, td{
          border: 0;
        }
        th{
          white-space: nowrap;
          color: $color-blue;
        }
      }
      @include mq('min', 'md') {
        margin:60px 0;
        tr{
          th,td{
            padding:1.8rem 0;
            vertical-align: middle;
          }
          th{
            width: 292px;
            padding-left:2rem;
            letter-spacing: .1rem;
          }
        }
      }
      @include mq('max', 'md') {
        margin:30px 0;
        tr{
          th,td{
            display: block;
          }
          th{
            padding:5vw 0 1.5625vw;
            letter-spacing: .1rem;
          }
          td{
            padding: 0 0 5vw;
          }
        }
      }
    }
    .bg-white{
      background: #fff;
    }
    #headline{
      @include mq('min', 'md') {
        background: url(/_uploads/online_reservation-bg.jpg) no-repeat center center / cover;
        h1 img{
          height: 130px;
          margin-bottom: 5px;
          margin-top: 20px;
        }
      }
      @include mq('max', 'md') {
        background: url(/_uploads/online_reservation-bg-sp.jpg) no-repeat center center / cover;
        h1{
          margin: 3.7vw 0 0;
          img{
            height: 23vw;
          }
          strong{
            margin-top:0;
            line-height: 1.3;
            padding-bottom: 4.6875vw;
            letter-spacing: .2rem;
          }
        }
      }
    }
    #feature{
      h2{
        color: $color-blue;
        text-align: center;
      }
      @include mq('min', 'md') {
        padding:135px 8.333% 125px;
        @include mq('min', 'xl1440') {
          padding:135px 0 125px;
        }
        h2{
          font-size: 32px;
        }
        p{
          margin-top: 4rem;
          text-align: center;
          line-height: 2;
        }
      }
      @include mq('max', 'md') {
        padding:12vw 0 10.9375vw;
        h2{
          @include fz_vw(18);
          margin-bottom: 4.6785vw;
          line-height: 1.75;
        }
        p{
          line-height: 2;
          br{
            display: none;
          }
        }
      }
    }
    #environment{
      h3{
        border: solid $color-blue;
        border-width: 2px 0;
      }
      .qr{
        display: flex;
        justify-content: center;
        a{
          color: $color-blue;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      @include mq('min', 'md') {
        padding:140px 8.333% 130px;
        .heading{
          margin-bottom: 85px;
        }
        .section-block{
          display: flex;
          justify-content:space-between;
          &>div{
            width:calc(50% - 30px);
          }
          h3{
            padding: 1.4rem 0;
            margin-bottom: 2.2rem;
            font-size: 1.5rem;
          }
          h4{
            font-size: 1.1rem;
            font-weight: bold;
            margin-bottom: .55rem;
          }
          p{
            line-height: 2;
            margin-bottom: 1.4rem;
            letter-spacing: .05rem;
            &+p{
              margin-top: 3.1rem;
            }
          }
          .qr{
            margin-top: 45px;
            a{
              width:130px;
              margin:0 30px;
              img{
                margin-bottom: 40px;
              }
            }
          }
        }

        @include mq('min', 'xl1440') {
          padding-left:0;
          padding-right:0;
        }

        @include mq('min', 'xl1560') {
          max-width: 1560px;
          margin:0 auto;

        }
      }
      @include mq('max', 'md') {
        padding: 14.0675vw 0 17vw;
        .heading{
          margin-bottom: 12.5vw;
        }
        .left{
          margin-bottom: 45px;
        }
        h3{
          padding:5.4vw 0;
          border-width: 1px 0;
          margin-bottom: 8.5vw;
          @include fz_vw(17);
          line-height: 1.5;
        }
        h4{
          @include fz_vw(15);
          margin-bottom: 4vw;
          font-weight: bold;
        }
        p{
          line-height: 1.8;
          margin-bottom: 1.2rem;
          &+p{
            margin-top: 8vw;
          }
        }
        .qr{
          margin: 7vw -4vw 0;
          a{
            width: 35.9375vw;
            @include fz_vw(13);
            margin:0 4vw;
            img{
              margin-bottom: 4.6785vw;
            }
          }
        }
      }
    }
    #flow{
      @include mq('min', 'md') {
        padding:150px 8.333% 85px;
        @include mq('min', 'xl1440') {
          padding:150px 0 85px;
        }
        .heading{
          margin-bottom: 85px;
        }
        table{
          th,td{
            padding-top:1.65rem;
            padding-bottom:1.65rem;
          }
          th{
            font-size: 24px;
            font-weight: 500;
            width: 356px;
          }
          td{
            font-size: 1rem;
          }
        }
      }
      @include mq('max', 'md') {
        padding: 14.0625vw 9.375vw 0;
        .heading{
          margin-bottom: 10.9375vw;
        }
        table{
          th{
            padding:5vw 0 1.5625vw;
            @include fz_vw(17);
            font-weight: 500;
          }
          td{
            padding-bottom:6.25vw;
            @include fz_vw(13);
            letter-spacing: .1rem;
            line-height: 1.6;
          }
        }
      }
    }
    #application{
      .bg-d-blue{
        background: #0082cd;
        max-width: 1560px;
        margin:0 auto;
        .heading{
          h2{
            strong, span{
              color: #fff;
              border-color: #fff;
            }
          }
        }
      }
      @include mq('min', 'md') {
        padding-top: 0;
        padding-bottom: 147px;
        .heading{
          padding: 150px 0 60px ;
        }
        .bg-white{
          margin: 0 8.333%;
          border-radius: 5px;
          padding:10px 10.256% 100px;
          max-width: 1200px;
        }
        .bg-d-blue{
          padding-bottom:  152px;
        }
      }
      @include mq('min', 'xl1560') {
        .bg-white{
          margin: 0 auto;
        }
      }
      @include mq('max', 'md') {
        padding-top: 9.375vw;
        padding-bottom: 0;
        .bg-d-blue{
          padding: 15.625vw 0 60px;
          .heading{
            margin-bottom: 12.5vw;
          }
          .bg-white{
            margin: 0 9.375vw;
            padding:0 7.8125vw 9.375vw;
          }
        }
      }
    }
  }
  #thanks{
    p.note{
      color: $color-blue;
      font-weight: bold;
    }
    @include mq('min', 'md') {
      h2{
        padding-top: 85px;
        font-size:32px;
        margin-bottom: 55px;
      }
      p{text-align: center;}
      p.note{
        margin: 2rem 0 90px;
      }
    }
    @include mq('max', 'md') {
      padding-top: 12.5vw;
      h2{
        @include fz_vw(18);
        margin-bottom: 25px;
        line-height: 1.75;
      }
      p.note{
        margin: 5.5vw 0 0;
        padding-bottom: 16vw;
      }
    }
  }
  #appointment{
    display: none;
  }
}

/* ---------------------------
お問合せ
*/
.contact{
  #contents{
    @include mq('max', 'md') {
      padding-bottom: 5px;
      .heading{
        h2{
          strong{
            letter-spacing: .1rem;
          }
        }
      }
    }
    .bg-white{
      background: #fff;
    }
    #headline{
      @include mq('min', 'md') {
        background: url(/_uploads/contact-bg.jpg) no-repeat center center / cover;
        h1{
          margin-bottom: 2.3rem;
        }
        h1 img{
          height: 130px;
          margin-bottom: 33px;
        }
      }
      @include mq('max', 'md') {
        background: url(/_uploads/contact-bg-sp.jpg) no-repeat center center / cover;
        h1{
          margin: 3.7vw 0 0;
          img{
            height: 23vw;
          }
          strong{
            margin-top:0;
            line-height: 1.3;
            padding-bottom: 4.6875vw;
          }
        }
      }
    }
    #feature{
      .tel{
        color: $color-blue;
        font-weight: 700;
        font-family: Montserrat,sans-serif;
      }
      .fax{
        font-weight: 700;
        font-family: Montserrat,sans-serif;
        letter-spacing: .1rem;
      }
      @include mq('min', 'md') {
        padding:140px 0 130px;
        .tel{
          font-size: 60px;
          padding-top:5px;
          line-height: 1.35;
          letter-spacing: .1rem;
        }
        p{
          margin-bottom: .9rem;
        }
        .fax{
          font-size: 48px;
        }
      }
      @include mq('max', 'md') {
        padding:12.5vw 0 6.25vw;
        .heading{
          margin-bottom: 35px;
        }
        .tel{
          @include fz_vw(26);
          margin-bottom: .3rem;
          letter-spacing: .075rem;
        }
        p{
          margin-bottom: .5rem;
          line-height: 1.6;
        }
        .fax{
          @include fz_vw(22);
        }
      }
    }
    #application{
      .bg-d-blue{
        background: #0082cd;
        max-width: 1560px;
        margin:0 auto;
        .heading{
          h2{
            strong, span{
              color: #fff;
              border-color: #fff;
            }
          }
        }
      }
      @include mq('min', 'md') {
        padding-top: 0;
        padding-bottom: 150px;
        .heading{
          padding: 145px 0 0 ;
          h2{
            margin-bottom: 5rem;
          }
          p{
            color: #fff;
            line-height: 2;
          }
        }
        .bg-white{
          margin: 80px 8.333% 0;
          padding:10px 10.256% 90px;
          max-width: 1200px;
        }
        .bg-d-blue{
          padding-bottom:  155px;
        }
      }
      @include mq('min', 'xl1560') {
        .bg-white{
          margin: 80px auto 0;
        }
      }
      @include mq('max', 'md') {
        padding-top: 30px;
        padding-bottom: 0;
        .bg-d-blue{
          padding: 45px 9.735vw 60px;
          .heading{
            margin-bottom: 7.8125vw;
            h2{
              margin-bottom: 12.5vw;
            }
            p{
              color: #fff;
              text-align:left;
            }
          }
          .bg-white{
            margin: 0;
            padding:0 7.8125vw 9.735vw;
          }
        }
      }
    }
    #thanks{
      p.note{
        color: $color-blue;
        font-weight: bold;
      }
      @include mq('min', 'md') {
        h2{
          padding-top: 85px;
          font-size:32px;
          margin-bottom: 55px;
        }
        p{text-align: center;}
        p.note{
          margin: 2rem 0 90px;
        }
      }
      @include mq('max', 'md') {
        padding-top: 12.5vw;
        h2{
          @include fz_vw(18);
          margin-bottom: 25px;
          line-height: 1.75;
        }
        p.note{
          margin: 1.1rem 0 0;
          padding-bottom: 16vw;
        }
      }
    }
  }
  #appointment{
    display: none;
  }
}
//
// body.form-header{
//   header{
//     position: relative;
//     @include mq('max', 'xl') {
//       nav{
//         height:100vh;
//         top:0;
//       }
//     }
//   }
//   #contents{
//     padding-top: 0 !important;
//   }
// }
