@charset "UTF-8";
/* ------------------------------------------------------
デスクトップ・スマホ共通
 ------------------------------------------------------ */
body {
  margin: 0;
  padding: 0;
  color: #373737;
  font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  font-weight: 500; }
  @media (max-width: 1280px) {
    body.sp-menu-open {
      overflow: hidden; } }

b, strong, .bold {
  font-family: YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif; }

h1, h2, h3 {
  font-family: YuGothic, "Yu Gothic", "Segoe UI", Verdana, Meiryo, sans-serif;
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-feature-settings: "palt"; }

table {
  margin: 1em 0;
  width: 100%;
  font-size: 110%; }

th, td {
  vertical-align: top;
  padding: 0.4em;
  border: 1px dotted #5D3F37; }

th {
  text-align: left; }

td ol, td ul {
  margin: 0; }

dt {
  font-weight: bold; }

dd {
  margin: 0 0 1em; }

a {
  color: #444; }
  a:hover {
    color: #373737; }

.bg-blue {
  background: #e0f2fc; }

/* ---------------------------
header
*/
header {
  position: fixed;
  top: 0;
  background: white;
  z-index: 1200;
  width: 100%; }
  header .title a {
    color: #373737; }
    header .title a:hover {
      text-decoration: none; }
  header ul li a {
    color: #373737; }
    header ul li a:hover {
      text-decoration: none;
      opacity: .9; }
  header ul li#nav-online, header ul li#nav-contact {
    background: #fff; }
    header ul li#nav-online a, header ul li#nav-contact a {
      display: block;
      height: 100%; }
      header ul li#nav-online a:hover, header ul li#nav-contact a:hover {
        opacity: .6; }
  header ul li#nav-online a {
    background: #0080f0; }
  header ul li#nav-contact a {
    background: #0864b5; }
  header .sns {
    align-items: center; }
    header .sns li {
      font-size: 1.5rem; }
      header .sns li#nav-sns-youtube a {
        color: #ff0500; }
      header .sns li#nav-sns-line {
        height: 29px;
        width: 29px;
        border-radius: 50%;
        overflow: hidden;
        justify-content: center;
        display: flex;
        align-items: center;
        border: 2px solid #25C131;
        margin-right: .4rem; }
        header .sns li#nav-sns-line a {
          color: #25C131;
          font-size: 2rem; }
  @media screen and (min-width: 80.0625em) {
    header {
      display: flex;
      justify-content: space-between; }
      header .title {
        padding: 29px 0 0 29px; }
        header .title a {
          justify-content: center;
          font-size: 1.6rem;
          font-weight: 500; }
        header .title img {
          width: 165px; }
      header nav {
        display: flex;
        justify-content: flex-end; }
        header nav ul {
          display: flex;
          justify-content: flex-end;
          list-style: none;
          padding-left: 0;
          margin-bottom: 0; }
          header nav ul li {
            font-size: .9rem;
            padding: 0;
            font-feature-settings: "palt";
            letter-spacing: 0.08rem; }
        header nav ul#gnav {
          margin: 30px 0 0;
          height: 80px; }
          header nav ul#gnav li {
            line-height: 1.2;
            position: relative; }
            header nav ul#gnav li a {
              display: flex;
              align-items: center;
              flex-direction: column;
              padding: 0;
              text-decoration: none;
              text-align: center;
              transition: 0.2s;
              color: #373737;
              height: 100%;
              border-right: 1px solid #373737; }
              header nav ul#gnav li a img {
                width: 93px;
                margin-bottom: 2px; }
              header nav ul#gnav li a span {
                height: 2rem;
                vertical-align: middle;
                padding-top: 7px; }
              header nav ul#gnav li a:hover {
                text-decoration: none;
                color: #222; }
            header nav ul#gnav li > div {
              align-items: center;
              flex-direction: column;
              padding: 0;
              text-decoration: none;
              text-align: center;
              transition: 0.2s;
              height: 100%;
              border-right: 1px solid #373737;
              cursor: pointer; }
              header nav ul#gnav li > div img {
                width: 93px;
                margin-bottom: 2px; }
              header nav ul#gnav li > div span {
                height: 2rem;
                vertical-align: middle;
                padding-top: 7px; }
            header nav ul#gnav li .pulldown {
              position: absolute;
              left: 0;
              top: 100px;
              width: 100%; }
              header nav ul#gnav li .pulldown ul {
                background: rgba(255, 255, 255, 0.9);
                display: none;
                padding: 17px 0; }
                header nav ul#gnav li .pulldown ul li {
                  padding: 3px 0 2px;
                  font-size: 13px;
                  line-height: 20px; }
                  header nav ul#gnav li .pulldown ul li a {
                    border: 0 !important;
                    color: #373737 !important;
                    text-align: left !important;
                    padding: 5px 20px !important;
                    display: block; }
                    header nav ul#gnav li .pulldown ul li a:hover {
                      color: #0091e3 !important; }
              header nav ul#gnav li .pulldown.spread ul {
                display: block; }
            header nav ul#gnav li#nav-rent div {
              border-left: 1px solid #373737;
              padding: 0 5px; }
            header nav ul#gnav li#nav-rent .pulldown {
              border: 0 !important;
              width: 155px; }
            header nav ul#gnav li#nav-buyandsell a span {
              padding-top: 0; }
            header nav ul#gnav li#nav-campaign div {
              padding: 0 13px; }
              header nav ul#gnav li#nav-campaign div span {
                padding-top: 0; }
            header nav ul#gnav li#nav-campaign .pulldown {
              border: 0 !important;
              padding: 0;
              width: 175px; }
            header nav ul#gnav li#nav-topics a {
              padding: 0 5px; }
              header nav ul#gnav li#nav-topics a span {
                padding-top: 0; }
            header nav ul#gnav li#nav-customer a {
              padding: 0 5px; }
            header nav ul#gnav li#nav-about a, header nav ul#gnav li#nav-recruit a {
              padding: 0 1px; }
        header nav ul#head-nav {
          height: 130px; }
          header nav ul#head-nav li {
            color: #fff;
            width: 130px;
            text-align: center;
            font-weight: bold;
            line-height: 1.3; }
            header nav ul#head-nav li a {
              color: #fff;
              padding: .2rem .1rem 0 .1rem;
              border: 0; }
              header nav ul#head-nav li a img {
                width: 130px;
                margin-top: 10px;
                margin-bottom: 3px; }
            header nav ul#head-nav li#nav-online {
              background: #0080f0;
              margin-left: 40px; }
            header nav ul#head-nav li#nav-contact {
              background: #0864b5;
              border: 0; }
              header nav ul#head-nav li#nav-contact a img {
                margin-bottom: 12px; }
              header nav ul#head-nav li#nav-contact a span {
                padding-top: 10px; }
      header .overlay, header .menu-trigger {
        display: none; }
      header .sns {
        position: absolute;
        top: 0;
        right: 10px; } }
  @media screen and (max-width: 80em) {
    header .title {
      z-index: 10000;
      position: relative;
      margin: 0 100px;
      text-align: center;
      background: rgba(255, 255, 255, 0.9);
      padding: 10px 0 12px; }
      header .title .logo {
        font-size: 1.5rem; }
        header .title .logo img {
          width: 80px; } }
  @media screen and (max-width: 80em) and (min-width: 30em) {
    header .title {
      padding: 20px 0 14px 10px; }
      header .title .logo img {
        width: 128px; } }
  @media screen and (max-width: 80em) {
    header .menu-trigger {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      position: fixed;
      z-index: 10001;
      transform: translateX(0); } }
  @media screen and (max-width: 80em) and (max-width: 29.9375em) {
    header .menu-trigger {
      width: 22px;
      height: 34px;
      top: 19px;
      right: 18px; } }
  @media screen and (max-width: 80em) and (min-width: 30em) {
    header .menu-trigger {
      width: 25px;
      height: 34px;
      top: 33px;
      right: 40px; } }
  @media screen and (max-width: 80em) {
      header .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #0091e3;
        transition: all .5s; } }
    @media screen and (max-width: 80em) and (max-width: 29.9375em) {
      header .menu-trigger span:nth-of-type(1) {
        top: 3px; }
      header .menu-trigger span:nth-of-type(2) {
        top: 11px; }
      header .menu-trigger span:nth-of-type(3) {
        top: 19px; } }
    @media screen and (max-width: 80em) and (min-width: 30em) {
      header .menu-trigger span:nth-of-type(1) {
        top: 3px; }
      header .menu-trigger span:nth-of-type(2) {
        top: 12px; }
      header .menu-trigger span:nth-of-type(3) {
        top: 21px; } }
  @media screen and (max-width: 80em) {
      header .menu-trigger.active {
        transform: translateX(0);
        z-index: 9990; }
        header .menu-trigger.active span {
          left: -3px; }
          header .menu-trigger.active span:nth-of-type(2) {
            opacity: 0; } }
      @media screen and (max-width: 80em) and (max-width: 29.9375em) {
        header .menu-trigger.active span:nth-of-type(1) {
          transform: translateY(8px) rotate(-43deg);
          width: 30px; }
        header .menu-trigger.active span:nth-of-type(3) {
          transform: translateY(-8px) rotate(43deg);
          width: 30px; } }
      @media screen and (max-width: 80em) and (min-width: 30em) {
        header .menu-trigger.active span:nth-of-type(1) {
          transform: translateY(9px) rotate(42deg);
          width: 28px; }
        header .menu-trigger.active span:nth-of-type(3) {
          transform: translateY(-9px) rotate(-42deg);
          width: 28px; } }
  @media screen and (max-width: 80em) {
    header nav {
      background: rgba(255, 255, 255, 0.9);
      display: none;
      width: 100vw;
      position: fixed;
      z-index: 9900;
      transform: translate(-100vw);
      transition: all .5s;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
      padding: 5px 8px 0; }
      header nav ul {
        padding-left: 0;
        width: 85%;
        z-index: 2000; }
        header nav ul li a {
          color: #373737;
          display: block;
          text-decoration: none; }
          header nav ul li a:hover {
            text-decoration: none; }
        header nav ul li > div.icon-down {
          z-index: 10000;
          cursor: pointer;
          display: block; }
          header nav ul li > div.icon-down i {
            color: #0091e3;
            padding: 0 .4rem;
            transition: .5s; }
          header nav ul li > div.icon-down.icon-rotate i {
            transform: rotateX(180deg); }
        header nav ul#gnav li {
          position: relative; }
          header nav ul#gnav li span.icon-down {
            padding-left: .4rem;
            color: #0091e3; }
          header nav ul#gnav li div.pulldown.spread ul {
            display: block;
            border-top: 1px solid #373737; }
            header nav ul#gnav li div.pulldown.spread ul li {
              font-size: 1rem; }
        header nav ul#head-nav {
          display: flex;
          width: 75%; }
          header nav ul#head-nav li {
            width: 50%;
            text-align: center;
            padding: 0; }
            header nav ul#head-nav li a {
              color: #fff;
              font-weight: bold;
              line-height: 1.4;
              padding: 3px 5px 15px; }
            header nav ul#head-nav li img {
              width: 100%;
              margin-bottom: 2px; }
          header nav ul#head-nav #nav-contact a img {
            margin-bottom: 12px; }
        header nav ul ul {
          display: none; }
      header nav #nav-sns {
        text-align: center;
        padding-bottom: 20px; }
        header nav #nav-sns span img {
          width: 77px;
          margin-right: 1rem; }
        header nav #nav-sns a {
          color: #0091e3;
          margin: 0 .2rem;
          vertical-align: middle; }
      header nav.open {
        display: block;
        transform: translateX(0);
        overflow: hidden;
        z-index: 9900; }
        header nav.open ul {
          box-sizing: border-box; }
          header nav.open ul li {
            list-style: none; } }
  @media screen and (max-width: 80em) and (min-width: 30em) {
    header nav {
      width: 480px;
      right: 0;
      height: calc(100vh - 90px);
      top: 90px; }
      header nav ul#gnav {
        margin: 60px auto 5px;
        width: 330px; }
        header nav ul#gnav li {
          font-size: 1rem;
          padding: .35rem .5rem .25rem; }
          header nav ul#gnav li div.pulldown.spread ul {
            padding: 8px 0 0 55px;
            margin: 5px 16px 7px; }
        header nav ul#gnav img {
          width: 80px; }
      header nav ul#head-nav {
        position: relative;
        width: 276px;
        margin: 25px auto 25px; }
      header nav #nav-sns {
        margin: 10px;
        padding-bottom: 70px; }
        header nav #nav-sns span img {
          width: 92px; }
        header nav #nav-sns a {
          font-size: 1.4rem; } }
  @media screen and (max-width: 80em) and (max-width: 29.9375em) {
    header nav {
      height: calc(100vh - 60px);
      top: 60px; }
      header nav ul#gnav {
        margin: 20px auto 5px;
        width: 90.625vw; }
        header nav ul#gnav li {
          font-size: 14px;
          font-size: 4.375vw;
          padding: .2rem .5rem .4rem; }
          header nav ul#gnav li div.pulldown.spread ul {
            padding: 10px 0 0 46px;
            margin: 5px 16px 5px; }
            header nav ul#gnav li div.pulldown.spread ul li {
              font-size: 13px;
              font-size: 4.0625vw;
              padding: .1rem .5rem; }
              header nav ul#gnav li div.pulldown.spread ul li a {
                padding: .25rem 0 .3rem; }
        header nav ul#gnav img {
          width: 21.875vw; }
      header nav ul#head-nav {
        position: relative;
        width: 75vw;
        margin: 17px auto 20px; }
        header nav ul#head-nav li {
          font-size: 14px;
          font-size: 4.375vw; }
          header nav ul#head-nav li a {
            padding: 6px 7px 13px; }
      header nav #nav-sns {
        font-size: 13px;
        font-size: 4.0625vw;
        margin: 7px auto; }
        header nav #nav-sns span img {
          width: 77px; }
        header nav #nav-sns a {
          font-size: 19px;
          font-size: 5.9375vw; } }
  @media screen and (max-width: 80em) {
    header .overlay {
      content: "";
      display: block;
      width: 0;
      height: 0;
      background: rgba(255, 255, 255, 0);
      position: fixed;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity .5s;
      top: 0;
      z-index: 0; }
      header .overlay.open {
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 9000; } }

/* ---------------------------
footer
*/
footer {
  width: 100vw;
  text-align: center;
  background: #0082cd;
  color: #fff; }
  footer a {
    color: #fff; }
    footer a:hover {
      color: rgba(255, 255, 255, 0.8); }
  footer .copyright {
    margin-top: 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500; }
  @media screen and (min-width: 48em) {
    footer {
      padding: 95px 0 30px; }
      footer #foot-logo img {
        width: 175px;
        margin-bottom: 35px; }
      footer dl {
        font-feature-settings: "palt";
        margin-bottom: 0; }
        footer dl dt {
          margin-bottom: .4rem;
          font-size: .95rem;
          letter-spacing: .1rem; }
        footer dl dd {
          letter-spacing: .05rem;
          margin-bottom: 0.1rem; }
      footer .banners {
        width: 960px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto; }
        footer .banners a {
          margin: 60px 0 65px;
          background: #fff; }
          footer .banners a img {
            width: 217px; }
          footer .banners a:hover img {
            opacity: .5; }
      footer .sns a {
        font-size: 1.8rem;
        display: inline-block;
        margin: .5rem 13px 1.4rem; }
      footer .copyright {
        text-align: right;
        padding-right: 30px;
        font-size: 13px; } }
  @media screen and (min-width: 48em) and (max-width: 59.9375em) {
    footer {
      width: 960px; } }
  @media screen and (max-width: 47.9375em) {
    footer {
      padding: 17.8125vw 0 13.8vw; }
      footer #foot-logo img {
        width: 31.25vw;
        margin-bottom: 7vw; }
      footer dl {
        font-feature-settings: "palt";
        margin-bottom: 9vw; }
        footer dl dt {
          margin-bottom: 2.7vw;
          font-size: 13px;
          font-size: 4.0625vw;
          letter-spacing: .1rem; }
        footer dl dd {
          letter-spacing: .05rem;
          margin-bottom: 0;
          font-size: 12px;
          font-size: 3.75vw;
          line-height: 1.8; }
      footer .banners {
        width: 81.25vw;
        margin: 0 auto 7.3vw;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        footer .banners a {
          display: inline-block;
          margin-bottom: 3.125vw; }
          footer .banners a img {
            width: 39.0625vw; }
      footer .sns a {
        font-size: 22px;
        font-size: 6.875vw;
        display: inline-block;
        margin: 0 1.35vw 5.4vw; }
      footer .copyright {
        font-size: 11px;
        font-size: 3.4375vw; } }

#page-top {
  position: fixed;
  z-index: 1000; }
  @media screen and (min-width: 48em) {
    #page-top {
      bottom: 64px;
      right: 35px; } }
  @media screen and (max-width: 47.9375em) {
    #page-top {
      bottom: 15px;
      right: 15px; }
      #page-top img {
        width: 10.3125vw; } }
  #page-top a {
    display: block;
    text-decoration: none; }

/* ---------------------------
contents
*/
@media screen and (max-width: 47.9375em) {
  #contents .container {
    padding-right: 9.375vw;
    padding-left: 9.375vw;
    max-width: 100%; } }

@media screen and (min-width: 48em) {
  #contents .container {
    max-width: 1200px; } }

@media screen and (min-width: 48em) {
  #contents section {
    padding: 60px 0; } }

#contents p {
  font-size: 1rem;
  line-height: 1.8; }

#contents h2 {
  margin-top: 0.4rem;
  font-feature-settings: "palt"; }

#contents h3 {
  color: #0091e3; }

@media screen and (min-width: 80.0625em) {
  #contents {
    padding-top: 130px; } }

@media screen and (max-width: 80em) {
  #contents {
    padding-top: 90px; }
    #contents h2 {
      font-size: 1.4rem; }
    #contents h3 {
      font-size: 1.1rem; } }
  @media screen and (max-width: 80em) and (min-width: 48em) {
    #contents h3 {
      font-size: 1.75rem;
      margin-top: 0.4rem; } }
  @media screen and (max-width: 80em) and (max-width: 47.9375em) {
    #contents {
      padding-bottom: 1.5625vw; } }
  @media screen and (max-width: 80em) and (max-width: 29.9375em) {
    #contents {
      padding-top: 60px; } }

@media screen and (min-width: 48em) and (max-width: 59.9375em) {
  #contents {
    width: 960px; } }

@media screen and (min-width: 48em) {
  #contents h2 {
    font-size: 1.875rem; }
    #contents h2 span {
      font-size: 1rem; }
  #contents .hover-blue {
    position: relative;
    height: 100%; }
  #contents .hover-blue:hover::before {
    background: rgba(0, 145, 227, 0.3);
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0; }
  #contents .hover-blue a {
    z-index: 1; }
  #contents .hover-transparent {
    position: relative;
    height: 100%; }
  #contents .hover-transparent::before {
    background: rgba(0, 145, 227, 0.3);
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0; }
  #contents .hover-transparent:hover::before {
    background: rgba(0, 145, 227, 0); }
  #contents .hover-transparent a {
    z-index: 1; } }

@media screen and (max-width: 47.9375em) {
  #contents h2 {
    font-size: 22px;
    font-size: 6.875vw; }
    #contents h2 span {
      font-size: 12px;
      font-size: 3.75vw; }
  #contents h3 {
    font-size: 20px;
    font-size: 6.25vw; }
  #contents p {
    font-size: 13px;
    font-size: 4.0625vw;
    line-height: 1.75;
    letter-spacing: .03rem; }
  #contents li {
    font-size: 13px;
    font-size: 4.0625vw; }
  #contents table th {
    font-size: 14px;
    font-size: 4.375vw; }
  #contents table td {
    font-size: 13px;
    font-size: 4.0625vw; } }

#contents #feature .text h2 {
  color: #0091e3;
  text-align: center; }

@media screen and (min-width: 90em) {
  #contents #feature .text {
    max-width: 1200px;
    margin: 0 auto; } }

@media screen and (min-width: 60em) and (max-width: 89.9375em) {
  #contents #feature .text {
    padding: 0 9.722%; } }

@media screen and (min-width: 48em) and (max-width: 59.9375em) {
  #contents #feature .text {
    width: 900px;
    margin: 0 auto; } }

@media screen and (min-width: 48em) {
  #contents #feature .text h2 {
    font-size: 2rem;
    line-height: 1.65;
    margin-top: 140px; }
  #contents #feature .text p {
    text-align: center;
    line-height: 2;
    margin: 45px 0 65px; } }

@media screen and (max-width: 47.9375em) {
  #contents #feature .text {
    padding: 0 9.375vw; }
    #contents #feature .text h2 {
      font-size: 18px;
      font-size: 5.625vw;
      line-height: 1.7;
      margin-top: 15.625vw; }
    #contents #feature .text p {
      margin-top: 4.6875vw;
      margin-bottom: 9.375vw;
      font-size: 13px;
      font-size: 4.0625vw;
      line-height: 2; } }

@media screen and (min-width: 90em) {
  #contents #feature .images {
    max-width: 1200px;
    margin: 0 auto; } }

@media screen and (min-width: 60em) and (max-width: 89.9375em) {
  #contents #feature .images {
    width: 100vw;
    padding: 0 9.722%; } }

@media screen and (min-width: 48em) and (max-width: 59.9375em) {
  #contents #feature .images {
    width: auto;
    padding: 0 9.722%; }
    #contents #feature .images img.main, #contents #feature .images .sub {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto; } }

@media screen and (min-width: 48em) {
  #contents #feature .images {
    margin-bottom: 70px; }
    #contents #feature .images img {
      margin-bottom: 15px; }
    #contents #feature .images img.main {
      width: 1200px !important; }
    #contents #feature .images .sub {
      display: flex;
      justify-content: space-between; }
      #contents #feature .images .sub img {
        width: calc(50% - 7.5px);
        height: 66.385%; } }

@media screen and (max-width: 47.9375em) {
  #contents #feature .images {
    margin-bottom: 12.5vw; }
    #contents #feature .images img {
      margin-bottom: 4.6875vw; }
    #contents #feature .images .sub {
      padding: 4.6875vw 9.375vw; }
      #contents #feature .images .sub img {
        margin: 1.5625vw auto;
        display: block; } }

#contents .heading {
  text-align: center; }
  #contents .heading a, #contents .heading a:hover {
    text-decoration: none; }
  #contents .heading#headline {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee; }
    #contents .heading#headline h1 {
      margin-bottom: 1.8rem;
      text-align: center; }
      #contents .heading#headline h1 a, #contents .heading#headline h1 a:hover {
        text-decoration: none; }
      #contents .heading#headline h1 img {
        height: 100px;
        display: block;
        margin: 0 auto; }
      #contents .heading#headline h1 strong {
        padding: 0 0 1rem .4rem;
        display: inline-block;
        font-feature-settings: "palt";
        letter-spacing: 0.3rem;
        font-weight: 500; }
      #contents .heading#headline h1 span {
        display: block;
        font-family: 'Montserrat', sans-serif;
        font-feature-settings: "palt";
        letter-spacing: 0.18rem;
        font-weight: 600; }
    @media screen and (min-width: 48em) {
      #contents .heading#headline {
        height: 600px; }
        #contents .heading#headline h1 img {
          height: 100px;
          margin-bottom: 25px; }
        #contents .heading#headline h1 strong {
          font-size: 2.7rem;
          padding: 0 0 1.3rem .4rem; }
        #contents .heading#headline h1 span {
          font-size: 1.25rem;
          margin-top: 1.45rem; } }
    @media screen and (max-width: 47.9375em) {
      #contents .heading#headline {
        height: 112.5vw; }
        #contents .heading#headline h1 strong {
          font-size: 28px;
          font-size: 8.75vw;
          letter-spacing: 0.4rem;
          margin-top: 1rem;
          border-bottom: .9375vw solid #fff;
          padding: 0 0 .6rem .4rem;
          font-weight: 500; }
        #contents .heading#headline h1 span {
          font-size: 14px;
          font-size: 4.375vw;
          letter-spacing: 0.1rem;
          margin-top: .9rem; }
        #contents .heading#headline h1 img {
          height: 17.8125vw; } }
  #contents .heading h2 a {
    color: #373737;
    text-decoration: none; }
  #contents .heading h2 strong {
    color: #373737;
    border-bottom: 3px solid #0091e3;
    padding: 0 0 1.1rem .4rem;
    display: inline-block;
    font-feature-settings: "palt";
    letter-spacing: 0.65rem;
    font-weight: 500; }
  #contents .heading h2 span {
    display: block;
    color: #0091e3;
    font-size: 1.1rem;
    margin-top: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: "palt";
    letter-spacing: 0.1 0.8rem;
    font-weight: 600; }
  #contents .heading h2 img {
    display: block;
    height: 100px;
    margin: 0 auto 10px; }
  @media screen and (min-width: 48em) {
    #contents .heading h2 {
      margin-bottom: 1.7rem; }
      #contents .heading h2 strong {
        letter-spacing: .09rem;
        font-size: 2.2rem; } }
  @media screen and (min-width: 48em) and (min-width: 1700px) {
    #contents .heading h2 {
      background-position: 16% 200px; } }
  @media screen and (min-width: 48em) and (max-width: 1400px) {
    #contents .heading h2 {
      background-size: 300px;
      background-position: 6% 180px; } }
  @media screen and (max-width: 47.9375em) {
    #contents .heading h2 {
      margin-bottom: 8.5vw; }
      #contents .heading h2 strong {
        font-size: 22px;
        font-size: 6.875vw;
        letter-spacing: 0.15rem;
        padding: 0 0 2.8vw 2vw;
        font-weight: 500;
        line-height: 1.5;
        border-bottom-width: .9375vw; }
      #contents .heading h2 span {
        font-size: 12px;
        font-size: 3.75vw;
        letter-spacing: 0.1rem;
        margin-top: 4vw;
        line-height: 1.6; }
      #contents .heading h2 img {
        height: 16.875vw; } }
  #contents .heading.heading-w h1 strong, #contents .heading.heading-w h2 strong {
    color: #fff;
    border-bottom: 5px solid #fff; }
  #contents .heading.heading-w h1 span, #contents .heading.heading-w h2 span {
    color: #fff; }

#contents .viewmore {
  text-align: center; }

#contents a.btn {
  display: inline-block;
  text-align: center;
  color: #0091e3;
  border: 3px solid #0091e3;
  border-radius: 40px;
  padding: .8rem 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  width: 100%;
  font-weight: bold; }
  #contents a.btn:hover {
    background: #0091e3;
    color: #fff; }
  @media screen and (max-width: 47.9375em) {
    #contents a.btn {
      padding: 3.5vw 5vw;
      width: 100%;
      font-size: 15px;
      font-size: 4.6875vw;
      border-radius: 12.5vw; } }

#contents .btn-primary {
  font-weight: 300; }

@media screen and (min-width: 48em) {
  #contents .section-block {
    display: flex; } }

#contents .iframe {
  position: relative;
  width: 100%;
  padding-top: 600px; }
  #contents .iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

#contents #results .section-block {
  flex-wrap: wrap; }
  #contents #results .section-block .asset h3 {
    color: #373737; }

@media screen and (min-width: 48em) {
  #contents #results {
    padding: 110px 0 145px; }
    #contents #results .heading {
      margin-bottom: 85px; }
    #contents #results .section-block {
      margin: 0 auto; }
      #contents #results .section-block .asset {
        margin: 0 2.5% 50px;
        width: 20%; }
        #contents #results .section-block .asset h3 {
          font-size: 1rem;
          margin-top: 1.5rem; }
        #contents #results .section-block .asset h4 {
          font-size: 1.2rem;
          margin-top: .8rem; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    #contents #results .section-block {
      width: 88.474%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    #contents #results .section-block {
      max-width: 1260px; } }

@media screen and (min-width: 97.5em) {
  #contents #results {
    width: 1560px;
    margin: 0 auto; } }

#contents #results #content-nav {
  margin-top: 30px; }
  #contents #results #content-nav a {
    font-size: 1.1rem;
    text-decoration: underline; }

@media screen and (max-width: 47.9375em) {
  #contents #results {
    padding: 15.625vw 0; }
    #contents #results .heading {
      margin-bottom: 0; }
    #contents #results .assets {
      padding: 3.2vw 9.375vw 0;
      text-align: left;
      display: flex;
      justify-content: space-between; }
      #contents #results .assets .asset {
        margin-bottom: 6vw;
        width: calc(50% - 3.125vw); }
        #contents #results .assets .asset h3 {
          font-size: 13px;
          font-size: 4.0625vw;
          margin-top: 5vw; }
        #contents #results .assets .asset h4 {
          font-size: 16px;
          font-size: 5vw;
          margin-top: 2vw; }
    #contents #results #content-nav {
      margin-top: 3.125vw;
      margin-bottom: 2vw;
      display: flex;
      font-size: 13px;
      font-size: 4.0625vw;
      height: 5vw;
      overflow: hidden; }
      #contents #results #content-nav a {
        font-size: 13px;
        font-size: 4.0625vw; } }

#contents .topic-block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 20px; }
  #contents .topic-block .topic {
    width: calc(50% - 20px);
    margin: 0 10px 20px;
    position: relative; }
    #contents .topic-block .topic .icon-new {
      background: #fff000;
      color: #0080f0;
      border-radius: 5px;
      font-weight: 700;
      font-family: 'Montserrat', sans-serif;
      display: inline-block;
      text-align: center;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      position: absolute;
      top: -10px;
      left: -10px;
      font-size: 12px;
      padding: 2px 6px;
      border-radius: 2px;
      z-index: 1; }
    #contents .topic-block .topic .icon-new:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 1;
      border-style: solid;
      border-color: #fff000 transparent;
      border-width: 8px 0 0 10px;
      bottom: -8px;
      left: 48%;
      margin-left: -5px; }
    #contents .topic-block .topic a {
      display: block;
      text-decoration: none; }
    #contents .topic-block .topic .thumb {
      position: relative; }
    #contents .topic-block .topic .thumb:hover::after {
      background: rgba(255, 255, 255, 0.3);
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0; }
    #contents .topic-block .topic h3 {
      color: #373737;
      letter-spacing: 0;
      font-weight: bold; }
    #contents .topic-block .topic .cat {
      background: #0091e3;
      color: #fff;
      padding: 5px 8px;
      border-radius: 3px;
      font-weight: bold;
      display: inline-block; }
      #contents .topic-block .topic .cat:hover {
        text-decoration: none; }
    @media screen and (min-width: 48em) {
      #contents .topic-block .topic .thumb {
        margin-bottom: 30px; }
      #contents .topic-block .topic span.date {
        display: block;
        margin-bottom: 10px;
        font-size: 13px; }
      #contents .topic-block .topic h3 {
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 20px; }
      #contents .topic-block .topic .cat {
        font-size: 13px;
        margin-bottom: .3rem;
        margin-right: .3rem; } }
    @media screen and (max-width: 47.9375em) {
      #contents .topic-block .topic {
        margin-bottom: 9vw; }
        #contents .topic-block .topic:nth-child(1) {
          width: 100%; }
        #contents .topic-block .topic span.date {
          font-size: 12px;
          font-size: 3.75vw; }
        #contents .topic-block .topic h3 {
          font-size: 14px;
          font-size: 4.375vw; }
        #contents .topic-block .topic .cat {
          font-size: 13px;
          font-size: 4.0625vw;
          margin-bottom: .3rem;
          margin-right: .3rem; } }
  @media screen and (min-width: 48em) {
    #contents .topic-block {
      margin: 0 6.25%; }
      #contents .topic-block .topic {
        width: 21.428571%;
        margin: 0 1.785714% 60px; } }

#appointment {
  width: 100%; }
  @media screen and (min-width: 48em) and (max-width: 59.9375em) {
    #appointment {
      width: 960px; } }
  #appointment a {
    text-align: center;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    z-index: 0; }
    #appointment a#appointment-online {
      background: url(/_uploads/appointment-online-unhover.jpg) no-repeat top center/cover; }
    #appointment a#appointment-contact {
      background: url(/_uploads/appointment-contact-unhover.jpg) no-repeat top center/cover; }
    #appointment a .heading {
      text-align: center; }
      #appointment a .heading h2 {
        margin-bottom: 1.7rem; }
        #appointment a .heading h2 img {
          display: block;
          margin: 0 auto 10px; }
        #appointment a .heading h2 strong {
          color: #fff;
          border-bottom: 5px solid #fff;
          display: inline-block;
          font-feature-settings: "palt";
          font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
          font-weight: 500; }
        #appointment a .heading h2 span {
          display: block;
          color: #fff;
          font-size: 1.3rem;
          margin-top: 1.4rem;
          font-family: 'Montserrat', sans-serif;
          font-feature-settings: "palt";
          letter-spacing: 0.1 0.8rem;
          font-weight: 600; }
  @media screen and (min-width: 48em) {
    #appointment {
      display: flex;
      justify-content: center;
      align-items: center; }
      #appointment a {
        width: 50%;
        height: 500px; }
        #appointment a h2 {
          z-index: 100;
          position: relative; }
          #appointment a h2 img {
            height: 130px;
            display: block;
            margin: 0 auto; }
          #appointment a h2 strong {
            letter-spacing: .09rem;
            padding: 0 0 1.1rem .4rem;
            font-size: 2.7rem;
            line-height: 1.3; }
        #appointment a#appointment-online:hover {
          background: url(/_uploads/appointment-online-hover.jpg) no-repeat top center/cover; }
        #appointment a#appointment-contact:hover {
          background: url(/_uploads/appointment-contact-hover.jpg) no-repeat top center/cover; } }
  @media screen and (max-width: 47.9375em) {
    #appointment a {
      height: 84.375vw; }
      #appointment a + a {
        margin-top: 5px; }
      #appointment a .heading {
        z-index: 1; }
        #appointment a .heading h2 strong {
          font-size: 28px;
          font-size: 8.75vw;
          font-weight: 500;
          border-bottom-width: 3px;
          line-height: 1.3; }
        #appointment a .heading h2 span {
          font-size: 14px;
          font-size: 4.375vw;
          letter-spacing: 0.1rem;
          margin-top: 4.75vw; }
    #appointment #appointment-online .heading h2 {
      margin-bottom: 0; }
      #appointment #appointment-online .heading h2 img {
        height: 21vw;
        margin-bottom: 1.5625vw; }
      #appointment #appointment-online .heading h2 strong {
        padding: 0 7vw 3.5vw;
        letter-spacing: 0.3rem; }
    #appointment #appointment-contact .heading h2 {
      margin-bottom: 4.6875vw; }
      #appointment #appointment-contact .heading h2 img {
        height: 23vw;
        margin-bottom: 8px; }
      #appointment #appointment-contact .heading h2 strong {
        letter-spacing: 0.1rem;
        padding: 0 0 3.5vw 1vw; } }

.results-pager #results .assets {
  display: none !important; }

.results-pager #results .assets.show {
  display: flex !important; }

.results-pager #results #content-nav #nav-pager::before {
  content: '｜'; }

.results-pager #results #content-nav #nav-pager span.pager {
  text-decoration: underline;
  cursor: pointer; }

.results-pager #results #content-nav #nav-prev span, .results-pager #results #content-nav #nav-next span {
  text-decoration: underline;
  cursor: pointer; }

.results-pager #results #content-nav #nav-pager span.pager.active {
  text-decoration: none; }

/* ---------------------------
ホーム
*/
@media screen and (min-width: 80.0625em) {
  .home header.top0 {
    background: transparent; }
    .home header.top0 .title {
      padding: 44px 0 0 46px; }
      .home header.top0 .title img {
        display: none; }
        .home header.top0 .title img.d-none {
          display: block !important;
          width: 206px; }
    .home header.top0 ul#gnav li a, .home header.top0 ul#gnav li > div {
      color: #fff;
      border-right: 1px solid #fff;
      cursor: pointer; }
    .home header.top0 ul#gnav li div.nav-current > span {
      color: #0091e3 !important; }
    .home header.top0 ul#gnav li#nav-rent > div {
      border-left: 1px solid #fff; } }

.home #contents {
  z-index: 1000 !important;
  position: relative; }
  @media screen and (min-width: 48em) {
    .home #contents {
      padding: 0; } }
  @media screen and (min-width: 30em) and (max-width: 47.9375em) {
    .home #contents {
      padding-top: 90px; } }
  @media screen and (max-width: 47.9375em) {
    .home #contents {
      padding-bottom: 0; } }
  @media screen and (max-width: 29.9375em) {
    .home #contents {
      padding-top: 60px; } }
  .home #contents .heading h1 strong, .home #contents .heading h2 strong {
    color: #0091e3; }
    @media screen and (min-width: 48em) {
      .home #contents .heading h1 strong, .home #contents .heading h2 strong {
        border-bottom-width: 5px; } }
  .home #contents .heading h1 span, .home #contents .heading h2 span {
    color: #212121; }
    @media screen and (min-width: 48em) {
      .home #contents .heading h1 span, .home #contents .heading h2 span {
        font-size: 1.3rem; } }
  .home #contents .heading.heading-w h1 strong, .home #contents .heading.heading-w h2 strong {
    color: #fff; }
  .home #contents .heading.heading-w h1 span, .home #contents .heading.heading-w h2 span {
    color: #fff; }
  @media screen and (max-width: 47.9375em) {
    .home #contents .heading.heading-w h1 img, .home #contents .heading.heading-w h2 img {
      height: 18vw;
      margin-bottom: 3.125vw; }
    .home #contents .heading.heading-w h1 strong, .home #contents .heading.heading-w h2 strong {
      font-size: 28px;
      font-size: 8.75vw;
      border-bottom-width: 3px;
      letter-spacing: 0.4rem;
      padding: 0 0 2vw 2vw;
      font-weight: 500; }
    .home #contents .heading.heading-w h1 span, .home #contents .heading.heading-w h2 span {
      font-size: 14px;
      font-size: 4.375vw;
      margin-top: 3.5vw; } }
  .home #contents h2 a {
    color: #373737;
    text-decoration: none; }
  .home #contents h2 strong {
    display: inline-block;
    font-feature-settings: "palt";
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
    font-weight: 500; }
  .home #contents h2 span {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-feature-settings: "palt";
    letter-spacing: 0.1rem;
    font-weight: 600; }
  @media screen and (min-width: 48em) {
    .home #contents h2 strong {
      font-size: 3rem;
      padding: .2rem 0 1.4rem .4rem;
      letter-spacing: 0.65rem; }
    .home #contents h2 span {
      margin-top: 1.6rem; }
    .home #contents h2 img {
      height: 100px; } }
  @media screen and (max-width: 47.9375em) {
    .home #contents h2 strong {
      font-size: 28px;
      font-size: 8.75vw;
      letter-spacing: 0.4rem;
      padding: 0 0 3.5vw 2vw;
      border-bottom-width: 3px; }
    .home #contents h2 span {
      font-size: 14px;
      font-size: 4.375vw;
      margin-top: 4.75vw; } }
  .home #contents h3 {
    line-height: 1.7;
    font-size: 2rem;
    margin-bottom: 1.9rem;
    letter-spacing: 0.2rem;
    font-feature-settings: "palt"; }
  .home #contents p {
    line-height: 2.4;
    letter-spacing: 0.01rem;
    color: #212121; }
  .home #contents .btn {
    letter-spacing: 0.2rem;
    font-feature-settings: "palt";
    padding: 1.3rem; }
  .home #contents #feature {
    padding: 0; }
    .home #contents #feature .slick-dots li {
      width: 50px;
      height: 8px; }
      .home #contents #feature .slick-dots li button {
        width: 50px;
        height: 8px; }
        .home #contents #feature .slick-dots li button:before {
          content: '　';
          background: rgba(55, 55, 55, 0.3);
          width: 50px;
          height: 8px;
          opacity: 1; }
      .home #contents #feature .slick-dots li.slick-active button:before {
        opacity: 1;
        background: #0080f0; }
    @media screen and (min-width: 48em) {
      .home #contents #feature .slick-dots {
        bottom: -55px; }
        .home #contents #feature .slick-dots li {
          margin: 0 .9rem; } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #feature .slick-dots {
        bottom: -36px; }
        .home #contents #feature .slick-dots li {
          width: 5.9375vw;
          height: .9375vw; }
          .home #contents #feature .slick-dots li button {
            width: 5.9375vw;
            height: .9375vw; }
            .home #contents #feature .slick-dots li button:before {
              width: 5.9375vw;
              height: .9375vw; } }
    @media screen and (min-width: 48em) {
      .home #contents #feature {
        height: 870px;
        margin-bottom: 140px !important; }
        .home #contents #feature img {
          width: 870px; }
        .home #contents #feature .slick-arrow {
          width: 60px;
          height: 90px;
          color: #fff; }
          .home #contents #feature .slick-arrow:before {
            font-size: 6rem;
            top: calc(50% - 80px);
            width: 60px;
            color: #fff;
            opacity: 1;
            font-weight: 900; }
        .home #contents #feature .slick-prev {
          left: calc(50% - 470px);
          z-index: 1; }
          .home #contents #feature .slick-prev:before {
            font-family: 'Font Awesome 5 Free';
            content: '\f053'; }
        .home #contents #feature .slick-next {
          right: calc(50% - 470px);
          text-align: right; }
          .home #contents #feature .slick-next:before {
            font-family: 'Font Awesome 5 Free';
            content: '\f054'; } }
  @media screen and (min-width: 48em) and (max-width: 80em) {
    .home #contents #feature {
      height: 67.96875vw; }
      .home #contents #feature img {
        width: 67.96875vw; }
      .home #contents #feature .slick-prev {
        left: calc(17% - 40px); }
      .home #contents #feature .slick-next {
        text-align: right;
        right: calc(17% - 40px); } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #feature img {
        width: 100vw; }
      .home #contents #feature .slick-arrow {
        width: 45px;
        height: 70px;
        color: #fff; }
        .home #contents #feature .slick-arrow:before {
          font-size: 30px;
          font-size: 9.375vw;
          top: calc(50% - 70px);
          width: 45px;
          color: #fff;
          opacity: 1;
          font-weight: 900; }
      .home #contents #feature .slick-prev {
        left: 2px;
        z-index: 1;
        text-align: left; }
        .home #contents #feature .slick-prev:before {
          font-family: 'Font Awesome 5 Free';
          content: '\f053'; }
      .home #contents #feature .slick-next {
        right: 2px;
        text-align: right; }
        .home #contents #feature .slick-next:before {
          font-family: 'Font Awesome 5 Free';
          content: '\f054'; } }
  .home #contents #rent .left {
    background: url(/_uploads/home-rent-bg.jpg) no-repeat center center/cover;
    height: 84.375vw; }
    .home #contents #rent .left a {
      display: block;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  @media screen and (min-width: 48em) {
    .home #contents #rent {
      position: relative;
      margin-bottom: 50px;
      padding-top: 40px; }
      .home #contents #rent .section-block {
        justify-content: space-between;
        align-items: center; }
      .home #contents #rent .left {
        width: calc(86.4585% - 400px);
        height: 600px;
        order: 1; }
        .home #contents #rent .left:hover {
          background-image: url(/_uploads/home-rent-bg-o.jpg); }
        .home #contents #rent .left h2 strong {
          letter-spacing: .4rem; }
        .home #contents #rent .left h2 img {
          margin: 0 auto 20px; }
      .home #contents #rent .right {
        width: 400px;
        margin-left: 5.729%;
        margin-right: 7.8125%;
        margin-bottom: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2; }
        .home #contents #rent .right p {
          letter-spacing: .04rem;
          margin-bottom: 4rem; }
      .home #contents #rent .apaman {
        position: absolute;
        right: 0;
        bottom: 70px;
        width: 400px;
        margin-right: 7.8125%; } }
  @media screen and (max-width: 47.9375em) {
    .home #contents #rent .right {
      padding: 9.375vw 9.375vw 55px; }
      .home #contents #rent .right h3 {
        text-align: center;
        font-size: 18px;
        font-size: 5.625vw;
        margin-top: 4vw;
        margin-bottom: 6vw;
        letter-spacing: .1rem; }
      .home #contents #rent .right p {
        line-height: 2;
        margin-bottom: 2vw;
        letter-spacing: .02rem; }
    .home #contents #rent .apaman {
      padding: 9.375vw 9.375vw 3.125vw; }
      .home #contents #rent .apaman a {
        padding: .7rem;
        font-size: 15px;
        font-size: 4.6875vw;
        border-radius: 80px; } }
  .home #contents #buyandsell .right {
    background: url(/_uploads/home-buyandsell-bg.jpg) no-repeat center center/cover;
    height: 84.375vw; }
    .home #contents #buyandsell .right a {
      display: block;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
  @media screen and (min-width: 48em) {
    .home #contents #buyandsell {
      position: relative;
      padding-bottom: 142px;
      padding-top: 0; }
      .home #contents #buyandsell .section-block {
        justify-content: space-between;
        align-items: center; }
      .home #contents #buyandsell .right {
        width: calc(84.062% - 480px);
        height: 480px;
        margin-right: 7.865%;
        order: 2; }
        .home #contents #buyandsell .right:hover {
          background-image: url(/_uploads/home-buyandsell-bg-o.jpg); }
        .home #contents #buyandsell .right h2 {
          margin-bottom: 0; }
          .home #contents #buyandsell .right h2 img {
            height: 100px; } }
    @media screen and (min-width: 48em) and (max-width: 80em) {
      .home #contents #buyandsell .right {
        width: calc(91.927% - 480px);
        margin-right: 0; } }
  @media screen and (min-width: 48em) {
      .home #contents #buyandsell .left {
        width: 400px;
        margin-left: 8.073%;
        margin-right: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 1; }
        .home #contents #buyandsell .left h3 {
          margin-top: 18px; } }
  @media screen and (max-width: 47.9375em) {
    .home #contents #buyandsell .left {
      padding: 9.375vw; }
      .home #contents #buyandsell .left h3 {
        text-align: center;
        font-size: 18px;
        font-size: 5.625vw;
        margin-top: 4vw;
        margin-bottom: 6vw;
        letter-spacing: .1rem; }
      .home #contents #buyandsell .left p {
        line-height: 1.8;
        margin-bottom: 2vw;
        letter-spacing: .03rem; }
    .home #contents #buyandsell .right h2 img {
      height: 16.875vw; } }

@media screen and (max-width: 47.9375em) and (max-width: 47.9375em) {
  .home #contents #buyandsell .right h2 {
    margin-bottom: 0; }
    .home #contents #buyandsell .right h2 strong {
      line-height: 1.25;
      padding-bottom: 4vw; } }
  .home #contents #specialcontents-h {
    width: 31.875vw;
    margin: 25px auto 0; }
  .home #contents #specialcontents {
    padding-bottom: 20px; }
    @media screen and (min-width: 48em) {
      .home #contents #specialcontents .heading {
        padding: 80px 0 65px;
        background: url(/_uploads/home-specialcontents-bg.png) no-repeat 80% bottom/248px; } }
  @media screen and (min-width: 48em) and (max-width: 80em) {
    .home #contents #specialcontents .heading {
      background-position: 90% bottom; } }
    @media screen and (min-width: 48em) {
        .home #contents #specialcontents .heading strong {
          color: #0091e3;
          border-bottom-color: #0091e3;
          letter-spacing: .09rem;
          font-size: 2.8rem; }
        .home #contents #specialcontents .heading span {
          color: #212121; } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #specialcontents .heading h2 {
        margin: 0 0 13.5vw;
        padding-top: 15.625vw; }
        .home #contents #specialcontents .heading h2 strong {
          font-size: 25px;
          font-size: 7.8125vw; }
        .home #contents #specialcontents .heading h2 span {
          font-size: 14px;
          font-size: 4.375vw; }
        .home #contents #specialcontents .heading h2 img {
          height: 16.875vw; } }
    .home #contents #specialcontents .banners .banner {
      text-align: center;
      background: #fff;
      min-height: 100px;
      margin-bottom: 5px; }
      .home #contents #specialcontents .banners .banner a:hover img {
        opacity: .7; }
    @media screen and (min-width: 48em) {
      .home #contents #specialcontents .banners {
        display: flex;
        flex-wrap: wrap;
        margin: 0 8px; }
        .home #contents #specialcontents .banners .banner {
          width: calc(50% - 16px);
          margin: 0 8px 16px; } }
  .home #contents #topics {
    padding-bottom: 20px; }
    .home #contents #topics #topics-h-image {
      width: 54vw;
      margin: 0 auto;
      padding: 10px 0 0;
      display: block; }
    .home #contents #topics .heading h2 {
      margin-top: 0; }
    .home #contents #topics .heading img {
      margin: 0 auto; }
    @media screen and (min-width: 48em) {
      .home #contents #topics .heading {
        padding: 55px 0;
        background: url(/_uploads/home-topics-bg.png) no-repeat 16% 30px/390px; }
        .home #contents #topics .heading h2 strong {
          letter-spacing: .09rem;
          font-size: 2.8rem; } }
  @media screen and (min-width: 48em) and (max-width: 1600px) {
    .home #contents #topics .heading {
      background-size: 300px;
      background-position: 6% 100px; } }
  @media screen and (min-width: 48em) and (max-width: 80em) {
    .home #contents #topics .heading {
      background-size: 220px;
      background-position: 6% 140px; } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #topics .heading h2 {
        margin: 0 0 13.5vw;
        padding-top: 15.625vw; }
        .home #contents #topics .heading h2 strong {
          font-size: 25px;
          font-size: 7.8125vw;
          letter-spacing: .15rem; }
        .home #contents #topics .heading h2 span {
          font-size: 14px;
          font-size: 4.375vw; } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #topics .topic .icon-new {
        font-size: 10px;
        font-size: 3.125vw;
        padding: .625vw 1.5625vw;
        left: -1.5625vw;
        top: -1.875vw; }
      .home #contents #topics .topic:first-child .icon-new {
        font-size: 14px;
        font-size: 4.375vw;
        padding: .9375vw 2.1875vw;
        letter-spacing: .02rem; }
      .home #contents #topics .topic .thumb {
        margin-bottom: 3.125vw; }
      .home #contents #topics .topic h3 {
        margin-bottom: .3rem;
        line-height: 1.5; }
      .home #contents #topics .topic .cat {
        font-size: 10px;
        font-size: 3.125vw;
        padding: 1.25vw 2.1875vw;
        margin-bottom: .3rem;
        margin-right: .3rem; }
      .home #contents #topics .topic:nth-child(n+6) {
        display: none; } }
    .home #contents #topics .viewmore a {
      max-width: 400px;
      padding: .82rem; }
    @media screen and (min-width: 48em) {
      .home #contents #topics {
        padding-bottom: 230px;
        background: #e0f2fc url(/_uploads/home-topics-bottom-bg.png) no-repeat bottom right/457px; }
        .home #contents #topics .viewmore {
          margin-top: 28px; }
          .home #contents #topics .viewmore a {
            background: #e0f2fc; }
            .home #contents #topics .viewmore a:hover {
              background: #0091e3; } }
    @media screen and (max-width: 47.9375em) {
      .home #contents #topics {
        padding-bottom: calc( 72.76vw + 40px);
        background: #e0f2fc url(/_uploads/home-topics-bottom-bg-sp.png) no-repeat bottom right/contain; }
        .home #contents #topics .viewmore {
          padding-right: 30px;
          padding-left: 30px;
          margin-top: 3.125vw; } }

.home #sns {
  margin-top: -160px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px; }
  .home #sns a {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    background: #f2f9fe;
    position: relative; }
    @media screen and (min-width: 48em) {
      .home #sns a {
        width: 180px;
        height: 180px; } }
    .home #sns a:hover::before {
      background: rgba(255, 255, 255, 0.3);
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0; }
  @media screen and (max-width: 47.9375em) {
    .home #sns {
      flex-wrap: wrap;
      box-sizing: border-box;
      justify-content: space-between;
      margin: -18.75vw 6.25vw 15.625vw; }
      .home #sns a {
        width: 37.5vw;
        height: 37.5vw;
        padding-top: calc((14% - 3.125vw) / 2);
        padding-bottom: calc((14% - 3.125vw) / 2);
        margin-bottom: 6.25vw; }
        .home #sns a#sns-line {
          width: 100%; }
        .home #sns a img {
          display: block;
          width: 100%; } }

.home #menu a {
  text-decoration: none; }
  .home #menu a h2 strong {
    letter-spacing: .3rem; }
  .home #menu a#menu-resident .bg-image {
    background: url(/_uploads/home-menu-resident.jpg) no-repeat top center/cover; }
  .home #menu a#menu-company .bg-image {
    background: url(/_uploads/home-menu-company.jpg) no-repeat top center/cover; }
  .home #menu a#menu-recruit .bg-image {
    background: url(/_uploads/home-menu-recruit.jpg) no-repeat top center/cover; }
  .home #menu a .bg-image {
    position: relative;
    z-index: -1; }
    .home #menu a .bg-image::after {
      background: rgba(0, 145, 227, 0.2);
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0; }

@media screen and (min-width: 48em) {
  .home #menu {
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px; }
    .home #menu a {
      width: 28.05%;
      margin: 0 8px;
      text-align: center; }
      .home #menu a .bg-image {
        height: 330px;
        position: relative; }
      .home #menu a:hover .bg-image::after {
        background: rgba(0, 145, 227, 0.1); }
      .home #menu a .heading {
        margin-top: -53px;
        position: relative;
        z-index: 100; }
      .home #menu a h2 img {
        height: 90px;
        margin-bottom: 5px;
        z-index: 100; }
      .home #menu a h2 strong {
        font-size: 2.25rem;
        border-bottom-width: 3px;
        padding-bottom: .7rem; }
      .home #menu a h2 span {
        font-size: 1.1rem;
        margin-top: .9rem; } }

@media screen and (max-width: 47.9375em) {
  .home #menu {
    margin-bottom: 18.75vw; }
    .home #menu a {
      display: block;
      text-align: center;
      margin-bottom: 6.25vw; }
      .home #menu a .bg-image {
        height: 62.5vw; }
      .home #menu a .heading {
        margin-top: -14.0625vw;
        margin-bottom: 13.125vw; }
      .home #menu a h2 img {
        height: 18.75vw;
        margin-bottom: 2.5vw; }
      .home #menu a h2 strong {
        font-size: 25px;
        font-size: 7.8125vw;
        border-bottom-width: 3px;
        margin-top: 0;
        padding-bottom: 3vw; } }

/* ---------------------------
借りたい
*/
@media screen and (max-width: 47.9375em) {
  .for-rent #contents {
    padding-bottom: 1.5625vw; } }

@media screen and (min-width: 48em) {
  .for-rent #contents #headline {
    background: url(/_uploads/for-rent-headline-bg.jpg) no-repeat center center/cover; }
    .for-rent #contents #headline h1 strong {
      letter-spacing: .65rem; } }

@media screen and (max-width: 47.9375em) {
  .for-rent #contents #headline {
    background: url(/_uploads/for-rent-headline-bg-sp.jpg) no-repeat center center/cover; } }

@media screen and (min-width: 48em) {
  .for-rent #contents #feature {
    padding-top: 0; } }

@media screen and (min-width: 48em) {
  .for-rent #contents #feature .text {
    padding: 0;
    width: auto; } }

@media screen and (max-width: 47.9375em) {
  .for-rent #contents #feature .text {
    padding: 0 4.5vw; } }

.for-rent #contents #concept .section-block {
  justify-content: space-between;
  flex-wrap: wrap; }
  .for-rent #contents #concept .section-block .block h3 {
    padding: 30px 0 10px; }

.for-rent #contents #concept .viewmore a {
  max-width: 480px; }

.for-rent #contents #concept #concept-contact {
  background: #fff;
  flex-direction: column; }
  .for-rent #contents #concept #concept-contact h3 {
    text-align: center;
    color: #373737;
    line-height: 1.6; }
  @media screen and (min-width: 48em) {
    .for-rent #contents #concept #concept-contact {
      margin-top: 25px;
      padding: 85px 20px 100px;
      margin-bottom: 0; }
      .for-rent #contents #concept #concept-contact h3 {
        margin-bottom: 2rem; } }
  @media screen and (max-width: 47.9375em) {
    .for-rent #contents #concept #concept-contact {
      margin: 5.5vw 9.375vw;
      background: #fff;
      padding: 11vw 9.375vw 12vw; }
      .for-rent #contents #concept #concept-contact h3 {
        text-align: left;
        margin-bottom: 6.25vw;
        font-size: 16px;
        font-size: 5vw;
        letter-spacing: .02rem;
        line-height: 1.65; }
        .for-rent #contents #concept #concept-contact h3 br {
          display: none; }
      .for-rent #contents #concept #concept-contact a {
        font-size: 15px;
        font-size: 4.6875vw;
        padding: 3.5vw;
        display: block;
        max-width: inherit;
        width: 100%; } }

@media screen and (min-width: 48em) {
  .for-rent #contents #concept {
    padding: 145px 0 75px; }
    .for-rent #contents #concept .heading {
      margin-bottom: 85px; }
    .for-rent #contents #concept .section-block .block p {
      margin-bottom: 2.2rem; }
    .for-rent #contents #concept .section-block .block .viewmore a {
      padding: .8rem 2rem; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .for-rent #contents #concept .section-block {
      margin: 0 5.763%; }
      .for-rent #contents #concept .section-block .block {
        margin: 0 3% 74px;
        width: 27%; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .for-rent #contents #concept {
      width: 1560px;
      margin: 0 auto; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .for-rent #contents #concept .section-block {
      margin: 0 auto;
      box-sizing: border-box;
      width: 1200px; }
      .for-rent #contents #concept .section-block .block {
        margin: 0 0 99px;
        width: 350px; } }

@media screen and (max-width: 47.9375em) {
  .for-rent #contents #concept {
    padding: 14.5vw 0 10.5vw; }
    .for-rent #contents #concept .heading {
      margin-bottom: 20px; }
    .for-rent #contents #concept .section-block {
      display: block; }
      .for-rent #contents #concept .section-block .block {
        margin: 0 auto;
        padding: 4.6875vw 9.375vw 8.7vw; }
        .for-rent #contents #concept .section-block .block h3 {
          padding: 8.7vw 0 1.5625vw; }
        .for-rent #contents #concept .section-block .block p {
          margin-bottom: 8vw; } }

.for-rent #contents #results .section-block a {
  display: block; }
  .for-rent #contents #results .section-block a .thumb {
    overflow: hidden; }

.for-rent #contents #results #recommend-contact {
  background: #fff;
  flex-direction: column; }
  .for-rent #contents #results #recommend-contact h3 {
    text-align: center;
    color: #373737;
    line-height: 1.6; }
  .for-rent #contents #results #recommend-contact .viewmore a {
    max-width: 480px; }
  @media screen and (min-width: 48em) {
    .for-rent #contents #results #recommend-contact {
      margin-top: 125px;
      padding: 85px 20px 100px;
      margin-bottom: 5px; }
      .for-rent #contents #results #recommend-contact h3 {
        margin-bottom: 2rem; }
      .for-rent #contents #results #recommend-contact .viewmore a {
        padding: .8rem 2rem;
        margin: 5px auto; } }
  @media screen and (max-width: 47.9375em) {
    .for-rent #contents #results #recommend-contact {
      margin: 14vw 9.375vw 4vw;
      background: #fff;
      padding: 11vw 9.375vw 12vw; }
      .for-rent #contents #results #recommend-contact h3 {
        text-align: left;
        margin-bottom: 6.25vw;
        font-size: 16px;
        font-size: 5vw;
        letter-spacing: .02rem;
        line-height: 1.65; }
        .for-rent #contents #results #recommend-contact h3 br {
          display: none; }
      .for-rent #contents #results #recommend-contact a {
        font-size: 15px;
        font-size: 4.6875vw;
        padding: 3.5vw;
        display: block;
        max-width: inherit;
        width: 100%; } }

@media screen and (min-width: 48em) {
  .for-rent #contents #results {
    padding-top: 145px; } }

@media screen and (max-width: 47.9375em) {
  .for-rent #contents #results .heading {
    padding-bottom: 5px; } }

.for-rent #contents #management .section-block {
  justify-content: space-between;
  flex-wrap: wrap; }
  .for-rent #contents #management .section-block .left h3 {
    color: #373737;
    line-height: 1.6; }

@media screen and (min-width: 48em) {
  .for-rent #contents #management {
    padding: 140px 0 75px;
    margin-bottom: 178px; }
    .for-rent #contents #management .heading {
      margin-bottom: 80px; }
    .for-rent #contents #management .right {
      order: 2; }
    .for-rent #contents #management .left h3 {
      letter-spacing: .15rem;
      margin-bottom: 1.4rem; }
    .for-rent #contents #management .left p {
      margin-bottom: 2rem; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .for-rent #contents #management .section-block {
      margin: 0 5.625%; }
      .for-rent #contents #management .section-block .block {
        margin: 0 3% 74px;
        width: 43.97%; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .for-rent #contents #management {
      width: 1560px;
      margin: 0 auto 178px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .for-rent #contents #management .section-block {
      margin: 0 180px;
      box-sizing: border-box;
      width: 1200px; }
      .for-rent #contents #management .section-block .block {
        margin: 0 0 74px;
        width: 562px; }
        .for-rent #contents #management .section-block .block .viewmore a {
          padding: .8rem 2rem; } }

@media screen and (max-width: 47.9375em) {
  .for-rent #contents #management {
    padding: 13.6vw 9.375vw 19vw; }
    .for-rent #contents #management .heading h2 {
      margin-bottom: 6vw; }
    .for-rent #contents #management img {
      margin-bottom: 7.9vw; }
    .for-rent #contents #management h3 {
      margin-bottom: 4vw;
      font-size: 16px;
      font-size: 5vw;
      letter-spacing: .135rem; }
    .for-rent #contents #management p {
      margin-bottom: 8.75vw;
      line-height: 1.76; } }

/* ---------------------------
買いたい・売りたい
*/
@media screen and (min-width: 48em) {
  .buy-and-sell #contents #headline {
    background: url(/_uploads/buyandsell-headline-bg.jpg) no-repeat center center/cover; } }

@media screen and (max-width: 47.9375em) {
  .buy-and-sell #contents #headline {
    background: url(/_uploads/buyandsell-headline-bg-sp.jpg) no-repeat center center/cover; }
    .buy-and-sell #contents #headline h1 {
      margin-bottom: 6vw; }
      .buy-and-sell #contents #headline h1 strong {
        padding: 0 0 3.75vw 2vw;
        line-height: 1.25;
        margin-top: 3vw;
        letter-spacing: .2rem; } }

@media screen and (min-width: 48em) {
  .buy-and-sell #contents #feature {
    padding-top: 0; } }

@media screen and (max-width: 47.9375em) {
  .buy-and-sell #contents #feature {
    padding: 0; } }

@media screen and (min-width: 48em) {
  .buy-and-sell #contents #feature .text {
    padding: 0;
    width: auto; } }

@media screen and (max-width: 47.9375em) {
  .buy-and-sell #contents #feature .text {
    padding: 0 4.5vw; }
    .buy-and-sell #contents #feature .text h2 {
      margin-top: 14.625vw; } }

.buy-and-sell #contents #estate .section-block {
  justify-content: space-between;
  flex-wrap: wrap; }
  .buy-and-sell #contents #estate .section-block .left h3 {
    color: #373737;
    line-height: 1.7; }

.buy-and-sell #contents #estate .cmp {
  background: #fff; }

@media screen and (min-width: 48em) and (max-width: 89.9375em) {
  .buy-and-sell #contents #estate .section-block {
    margin: 0 5.625%; }
    .buy-and-sell #contents #estate .section-block .block {
      margin: 0 3% 74px;
      width: 43.97%; }
    .buy-and-sell #contents #estate .section-block .cmp {
      margin: 0 3%; }
      .buy-and-sell #contents #estate .section-block .cmp h3 {
        letter-spacing: .06rem; } }

@media screen and (min-width: 97.5em) {
  .buy-and-sell #contents #estate {
    width: 1560px;
    margin: 0 auto; } }

@media screen and (min-width: 90em) {
  .buy-and-sell #contents #estate .section-block {
    margin: 0 180px;
    box-sizing: border-box;
    width: 1200px; }
    .buy-and-sell #contents #estate .section-block .block {
      width: 562px; } }

@media screen and (min-width: 48em) {
  .buy-and-sell #contents #estate {
    padding: 140px 0 145px;
    margin-bottom: 35px; }
    .buy-and-sell #contents #estate .heading h2 strong {
      padding-left: 3rem;
      padding-right: 3rem; }
    .buy-and-sell #contents #estate .section-block .block {
      margin-bottom: 74px; }
      .buy-and-sell #contents #estate .section-block .block h3 {
        margin-bottom: 1.4rem;
        letter-spacing: .15rem; }
      .buy-and-sell #contents #estate .section-block .block p {
        margin-bottom: 3rem;
        line-height: 2; }
      .buy-and-sell #contents #estate .section-block .block .viewmore a {
        padding: .8rem 2rem; }
    .buy-and-sell #contents #estate .section-block + .section-block {
      margin-top: 40px; }
    .buy-and-sell #contents #estate .heading {
      margin-bottom: 80px; }
    .buy-and-sell #contents #estate .right {
      order: 2; }
    .buy-and-sell #contents #estate .left a + a {
      margin-top: 25px; }
    .buy-and-sell #contents #estate .cmp {
      padding: 7.333%; }
      .buy-and-sell #contents #estate .cmp .cmp-block {
        display: flex;
        justify-content: space-between; }
      .buy-and-sell #contents #estate .cmp h3 {
        color: #373737;
        line-height: 1.6; }
      .buy-and-sell #contents #estate .cmp p {
        line-height: 2; }
      .buy-and-sell #contents #estate .cmp .image, .buy-and-sell #contents #estate .cmp .text {
        width: calc(50% - 30px); }
      .buy-and-sell #contents #estate .cmp a {
        padding: 1rem 2rem; } }

@media screen and (max-width: 47.9375em) {
  .buy-and-sell #contents #estate {
    padding: 14.75vw 9.375vw; }
    .buy-and-sell #contents #estate .heading {
      margin-bottom: 10.9375vw; }
    .buy-and-sell #contents #estate img {
      margin-bottom: 7.8125vw; }
    .buy-and-sell #contents #estate h3 {
      margin-bottom: 3vw;
      font-size: 16px;
      font-size: 5vw; }
    .buy-and-sell #contents #estate p {
      margin-bottom: 8.75vw; }
    .buy-and-sell #contents #estate .viewmore {
      margin-bottom: 8.75vw; }
      .buy-and-sell #contents #estate .viewmore a {
        padding: 3vw;
        margin-bottom: 15px; }
    .buy-and-sell #contents #estate .cmp {
      padding: 11vw 9.375vw 13vw;
      margin-bottom: 4.6875vw; }
      .buy-and-sell #contents #estate .cmp h3 {
        color: #373737;
        line-height: 1.7;
        text-align: left !important;
        font-size: 15px;
        font-size: 4.6875vw;
        margin-bottom: 0; }
        .buy-and-sell #contents #estate .cmp h3 br {
          display: none; }
      .buy-and-sell #contents #estate .cmp .cmp-block {
        margin-top: 5.625vw; }
      .buy-and-sell #contents #estate .cmp img {
        margin-bottom: 20px; }
      .buy-and-sell #contents #estate .cmp p {
        margin-bottom: 1rem; }
      .buy-and-sell #contents #estate .cmp a {
        padding: .5rem 2rem;
        font-size: .95rem; } }

/* ---------------------------
スペシャルコンテンツ
*/
@media screen and (min-width: 48em) {
  .special-contents #contents #headline {
    height: 740px; }
    .special-contents #contents #headline h1 {
      margin-bottom: 0 !important; }
      .special-contents #contents #headline h1 img {
        margin-bottom: 0 !important;
        height: auto;
        width: 870px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents #headline {
    height: 85vw; }
    .special-contents #contents #headline h1 {
      margin-bottom: 0; }
      .special-contents #contents #headline h1 img {
        height: auto;
        width: 100vw; } }

.special-contents #contents #feature p {
  line-height: 1.8; }
  .special-contents #contents #feature p.copy {
    color: #0091e3;
    text-align: center; }

.special-contents #contents #feature h2 {
  color: #373737;
  text-align: center; }
  .special-contents #contents #feature h2 strong {
    border-bottom: solid #0091e3;
    padding: .6rem 0; }

@media screen and (min-width: 48em) {
  .special-contents #contents #feature p.copy {
    font-size: 2rem;
    margin: 75px 0 1.5rem; }
  .special-contents #contents #feature p.lead {
    line-height: 2; }
  .special-contents #contents #feature h2 {
    font-size: 2.8rem;
    margin: 0 0 3.1rem; }
    .special-contents #contents #feature h2 strong {
      border-bottom-width: 5px;
      padding: .6rem 0;
      letter-spacing: .3rem;
      font-weight: 500; }
  .special-contents #contents #feature .images {
    margin-bottom: 80px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents #feature h2 strong {
    font-weight: 500; }
  .special-contents #contents #feature p {
    line-height: 2; }
    .special-contents #contents #feature p.copy {
      margin: 14.75vw 0 4.6vw;
      font-size: 18px;
      font-size: 5.625vw;
      line-height: 1.7; }
    .special-contents #contents #feature p.lead {
      letter-spacing: .02rem; }
      .special-contents #contents #feature p.lead br {
        display: none; }
  .special-contents #contents #feature h2 {
    font-size: 25px;
    font-size: 7.8125vw;
    margin: 0 0 13vw;
    letter-spacing: .2rem; }
    .special-contents #contents #feature h2 strong {
      border-bottom-width: 3px;
      padding: 0 0 4.5vw; }
  .special-contents #contents #feature .images {
    margin: 10.9375vw 0 12vw; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents #feature .viewmore {
    margin: 0 9.375vw 18.75vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents #feature .viewmore {
    padding-bottom: 100px;
    max-width: 450px;
    margin: -65px auto 0; } }

@media screen and (min-width: 48em) {
  .special-contents #contents #results {
    padding-top: 140px;
    margin-bottom: 145px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents #results {
    padding: 15.625vw 0; }
    .special-contents #contents #results h2 strong {
      letter-spacing: .2rem;
      padding-left: .1rem; } }

.special-contents #contents.omoshiro-chintai #headline img {
  padding: 0 7% 0 15%;
  box-sizing: border-box; }

@media screen and (min-width: 60em) and (max-width: 89.9375em) {
  .special-contents #contents.omoshiro-chintai #feature .text {
    padding: 0 8%; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.sumitsuku #feature .images {
    margin-bottom: 2.25vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.sumitsuku #feature .viewmore {
    margin: -20px auto 0; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.regnoah_ #feature p.lead {
    letter-spacing: .13rem; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.regnoah_ #feature .images {
    margin-bottom: 2.25vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.regnoah_ #feature .viewmore {
    margin: -20px auto 0; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.sumaino-shoushu #feature .images .sub img.img1 {
    width: calc(33.95% - 7.5px); }
  .special-contents #contents.sumaino-shoushu #feature .images .sub img.img2 {
    width: calc(66.04% - 7.5px); }
  .special-contents #contents.sumaino-shoushu #feature .viewmore {
    margin-top: -20px;
    padding-bottom: 85px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.sumaino-shoushu #feature .images {
    margin: 4.6875vw 0 10.9735vw; }
    .special-contents #contents.sumaino-shoushu #feature .images .sub {
      width: 100%;
      padding: 0; }
      .special-contents #contents.sumaino-shoushu #feature .images .sub img {
        width: 100%; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.pet-kyousei-jyutaku #feature .images {
    margin-bottom: 2vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.pet-kyousei-jyutaku #feature .viewmore {
    max-width: 600px; } }

.special-contents #contents.coin-laundry #feature .kind dt {
  color: #0091e3;
  font-weight: 500; }
  .special-contents #contents.coin-laundry #feature .kind dt img {
    border: 1px solid #80c8f1; }

@media screen and (min-width: 48em) {
  .special-contents #contents.coin-laundry #feature .kind {
    margin: 0 30px 100px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 130px; }
    .special-contents #contents.coin-laundry #feature .kind dl {
      width: 29.5%; }
      .special-contents #contents.coin-laundry #feature .kind dl dt {
        font-size: 28px;
        letter-spacing: .1rem;
        margin-bottom: 1rem; }
        .special-contents #contents.coin-laundry #feature .kind dl dt img {
          margin-bottom: 2rem; }
      .special-contents #contents.coin-laundry #feature .kind dl dd {
        line-height: 2;
        font-size: 1rem;
        letter-spacing: .03rem; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .special-contents #contents.coin-laundry #feature .kind {
      max-width: 1200px;
      margin: 0 auto 45px; } }
  @media screen and (min-width: 48em) and (min-width: 60em) and (max-width: 89.9375em) {
    .special-contents #contents.coin-laundry #feature .kind {
      margin: 0 0 45px;
      padding: 0 9.722%; } }
  @media screen and (min-width: 48em) and (max-width: 59.9375em) {
    .special-contents #contents.coin-laundry #feature .kind {
      width: 880px;
      margin: 0 auto 45px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.coin-laundry #feature p.lead {
    margin-bottom: 6vw; }
  .special-contents #contents.coin-laundry #feature .images {
    margin: 10vw 0; }
  .special-contents #contents.coin-laundry #feature .kind {
    margin: 0 9.375vw 17vw; }
    .special-contents #contents.coin-laundry #feature .kind dl {
      margin-bottom: 11vw; }
      .special-contents #contents.coin-laundry #feature .kind dl dt {
        font-size: 20px;
        font-size: 6.25vw;
        margin-bottom: 5.2vw; }
        .special-contents #contents.coin-laundry #feature .kind dl dt img {
          margin-bottom: 8vw; }
      .special-contents #contents.coin-laundry #feature .kind dl dd {
        font-size: 14px;
        font-size: 4.375vw;
        margin-bottom: 5vw;
        line-height: 1.7; } }

.special-contents #contents.coin-laundry #results #locations .location {
  border-bottom: 1px solid #373737; }
  .special-contents #contents.coin-laundry #results #locations .location strong {
    display: block;
    font-weight: bold; }

.special-contents #contents.coin-laundry #results #locations p {
  font-weight: bold;
  color: #0091e3; }

@media screen and (min-width: 48em) {
  .special-contents #contents.coin-laundry #results #locations {
    padding-top: 60px; }
    .special-contents #contents.coin-laundry #results #locations .location-block {
      display: flex;
      flex-wrap: wrap; }
      .special-contents #contents.coin-laundry #results #locations .location-block .location {
        margin: 0 2.94% 1rem;
        width: 27.45%;
        padding-top: 2.1rem;
        padding-bottom: 1.5rem; }
        .special-contents #contents.coin-laundry #results #locations .location-block .location strong {
          font-size: 1.1rem;
          margin-bottom: .3rem; }
    .special-contents #contents.coin-laundry #results #locations p {
      margin-top: 65px;
      text-align: center;
      width: 100%;
      margin-bottom: 0; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .special-contents #contents.coin-laundry #results #locations {
      margin: 0 7.28%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .special-contents #contents.coin-laundry #results #locations .location-block {
      width: 1275px;
      margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.coin-laundry #results #locations .location-block {
    margin: 4vw 9.375vw; }
    .special-contents #contents.coin-laundry #results #locations .location-block .location {
      padding-top: 7vw;
      padding-bottom: 5vw;
      font-size: 13px;
      font-size: 4.0625vw;
      color: #212121; }
      .special-contents #contents.coin-laundry #results #locations .location-block .location strong {
        color: #373737;
        font-size: 15px;
        font-size: 4.6875vw;
        margin-bottom: 2vw; }
  .special-contents #contents.coin-laundry #results #locations p {
    margin: 8.5vw 9.375vw 1.5625vw;
    font-size: 13px;
    font-size: 4.0625vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.coin-laundry #results {
    padding-bottom: 175px;
    margin-bottom: 110px; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.regcon #feature {
    padding-bottom: 45px; } }

.special-contents #contents.regcon #results #locations .location {
  border-bottom: 1px solid #373737; }
  .special-contents #contents.regcon #results #locations .location strong {
    display: block;
    font-weight: bold; }

.special-contents #contents.regcon #results #locations p {
  font-weight: bold;
  color: #0091e3; }

@media screen and (min-width: 48em) {
  .special-contents #contents.regcon #results #locations {
    padding-top: 60px; }
    .special-contents #contents.regcon #results #locations .location-block {
      display: flex;
      flex-wrap: wrap; }
      .special-contents #contents.regcon #results #locations .location-block .location {
        margin: 0 2.94% 1rem;
        width: 27.45%;
        padding-top: 2.1rem;
        padding-bottom: 1.5rem; }
        .special-contents #contents.regcon #results #locations .location-block .location strong {
          font-size: 1.1rem;
          margin-bottom: .3rem; }
    .special-contents #contents.regcon #results #locations p {
      margin-top: 65px;
      text-align: center;
      width: 100%;
      margin-bottom: 0; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .special-contents #contents.regcon #results #locations {
      margin: 0 7.28%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .special-contents #contents.regcon #results #locations .location-block {
      width: 1275px;
      margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.regcon #results #locations .location-block {
    margin: 4vw 9.375vw; }
    .special-contents #contents.regcon #results #locations .location-block .location {
      padding-top: 7vw;
      padding-bottom: 5vw;
      font-size: 13px;
      font-size: 4.0625vw;
      color: #212121; }
      .special-contents #contents.regcon #results #locations .location-block .location strong {
        color: #373737;
        font-size: 15px;
        font-size: 4.6875vw;
        margin-bottom: 2vw; }
  .special-contents #contents.regcon #results #locations p {
    margin: 8.5vw 9.375vw 1.5625vw;
    font-size: 13px;
    font-size: 4.0625vw; } }

@media screen and (min-width: 48em) {
  .special-contents #contents.regcon #results {
    padding-bottom: 132px;
    margin-bottom: 150px; } }

@media screen and (max-width: 47.9375em) {
  .special-contents #contents.regcon #results .heading h2 {
    margin-bottom: 11.5vw; } }

/* ---------------------------
実績詳細
*/
.results-detail main {
  width: 100%; }

.results-detail #contents #headline {
  position: relative; }
  .results-detail #contents #headline::after {
    background: rgba(0, 0, 0, 0.25);
    content: ' ';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0; }
  .results-detail #contents #headline h1 {
    z-index: 1; }
    .results-detail #contents #headline h1 span {
      font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
      font-weight: 500; }
  @media screen and (min-width: 48em) {
    .results-detail #contents #headline h1 {
      max-width: 1200px;
      margin-left: 120px;
      margin-right: 120px; }
      .results-detail #contents #headline h1 span {
        font-size: 2rem;
        margin-bottom: 1.75rem; }
      .results-detail #contents #headline h1 strong {
        font-size: 2.8rem;
        letter-spacing: .3rem;
        padding-bottom: .9rem;
        margin-bottom: 11px; } }
  @media screen and (max-width: 47.9375em) {
    .results-detail #contents #headline {
      height: 84.375vw; }
      .results-detail #contents #headline h1 {
        margin-right: 30px;
        margin-left: 30px; }
        .results-detail #contents #headline h1 span {
          font-size: 18px;
          font-size: 5.625vw; }
        .results-detail #contents #headline h1 strong {
          font-size: 25px;
          font-size: 7.8125vw;
          letter-spacing: .1rem;
          margin-top: 6vw; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents {
    margin-bottom: 5px; } }

.results-detail #contents #feature {
  width: 100%; }
  .results-detail #contents #feature h2 {
    color: #0091e3;
    text-align: center; }
  @media screen and (min-width: 48em) {
    .results-detail #contents #feature {
      padding-bottom: 0; }
      .results-detail #contents #feature h2 {
        font-size: 2rem;
        line-height: 1.6875;
        margin-top: 75px; }
      .results-detail #contents #feature p {
        text-align: center;
        line-height: 2;
        margin: 40px 0 35px; } }
  @media screen and (max-width: 47.9375em) {
    .results-detail #contents #feature h2 {
      font-size: 18px;
      font-size: 5.625vw;
      line-height: 1.7;
      margin-top: 42px; }
    .results-detail #contents #feature p {
      margin-top: 6.25vw;
      font-size: 13px;
      font-size: 4.0625vw;
      line-height: 2;
      margin-bottom: 10vw; } }

@media screen and (min-width: 48em) {
  .results-detail #contents #deg360 {
    padding-bottom: 0; } }

.results-detail #contents #deg360 #a3DTHETA-youtube .iframe {
  padding-top: 56.25%; }

@media screen and (min-width: 48em) {
  .results-detail #contents #deg360 #a3DTHETA {
    margin-bottom: 100px; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents #deg360 #a3DTHETA {
    margin-bottom: 9.375vw; } }

@media screen and (min-width: 48em) {
  .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube {
    margin: 0 auto 100px; }
    .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube .iframe {
      margin-bottom: 20px; } }

@media screen and (min-width: 48em) and (max-width: 97.4375em) {
  .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube {
    margin: 0 5.625% 100px; } }

@media screen and (min-width: 97.5em) {
  .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube {
    width: 1560px;
    padding: 0 5.625%; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube {
    margin: 9.375vw 0; }
    .results-detail #contents #deg360 #a3DTHETA + #a3DTHETA-youtube .iframe {
      margin-bottom: 10px; } }

.results-detail #contents #movie-photo .iframe {
  padding-top: 56.25%; }

@media screen and (min-width: 48em) {
  .results-detail #contents #movie-photo {
    padding: 0; }
    .results-detail #contents #movie-photo .iframe, .results-detail #contents #movie-photo img {
      margin-bottom: 100px; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .results-detail #contents #movie-photo {
      margin: 0 5.625%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .results-detail #contents #movie-photo {
      width: 1200px;
      margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents #movie-photo {
    margin: 9.375vw 0 3.125vw; }
    .results-detail #contents #movie-photo .iframe, .results-detail #contents #movie-photo img {
      margin-bottom: 3.125vw; } }

.results-detail #contents #detail .section-block {
  justify-content: space-between;
  flex-wrap: wrap; }
  .results-detail #contents #detail .section-block .left {
    border: solid #373737;
    border-width: 1px 0;
    padding: 30px 0;
    position: relative; }
    .results-detail #contents #detail .section-block .left h3 {
      color: #373737;
      line-height: 1.6; }
    .results-detail #contents #detail .section-block .left .entry-body {
      padding-left: 1rem; }
      .results-detail #contents #detail .section-block .left .entry-body p {
        margin-bottom: 1rem; }
    .results-detail #contents #detail .section-block .left .entry-body-switch {
      color: #0091e3;
      font-size: 1.1rem;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #e0f2fc;
      width: 100%;
      padding: 20px 15px 25px;
      cursor: pointer;
      display: none; }
      .results-detail #contents #detail .section-block .left .entry-body-switch strong {
        font-weight: bold; }
    .results-detail #contents #detail .section-block .left.spread .entry-body {
      margin-bottom: 3rem; }
    .results-detail #contents #detail .section-block .left .entry-more {
      padding-left: 1rem; }
      .results-detail #contents #detail .section-block .left .entry-more .more-block {
        display: none; }

.results-detail #contents #detail #images > div {
  position: relative; }
  .results-detail #contents #detail #images > div .over {
    background: rgba(51, 51, 51, 0.5);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column; }
    .results-detail #contents #detail #images > div .over strong {
      display: block; }
    @media screen and (min-width: 48em) {
      .results-detail #contents #detail #images > div .over {
        font-size: 20px; }
        .results-detail #contents #detail #images > div .over strong {
          font-size: 36px; } }
    @media screen and (max-width: 47.9375em) {
      .results-detail #contents #detail #images > div .over {
        font-size: 15px;
        font-size: 4.6875vw; }
        .results-detail #contents #detail #images > div .over strong {
          font-size: 22px;
          font-size: 6.875vw; } }
  .results-detail #contents #detail #images > div:nth-child(n+5) {
    display: none; }

@media screen and (min-width: 48em) {
  .results-detail #contents #detail {
    padding: 0 0 20px; }
    .results-detail #contents #detail .section-block {
      margin: 0 auto; }
      .results-detail #contents #detail .section-block .block {
        margin: 0 0 54px; }
        .results-detail #contents #detail .section-block .block h3 {
          margin-bottom: 1.4rem; }
        .results-detail #contents #detail .section-block .block p {
          margin-bottom: 3rem; }
        .results-detail #contents #detail .section-block .block .viewmore a {
          padding: .8rem 2rem; }
    .results-detail #contents #detail .section-block + .section-block {
      margin-top: 40px; }
    .results-detail #contents #detail .right {
      order: 2; }
      .results-detail #contents #detail .right img {
        display: block; }
    .results-detail #contents #detail .left a + a {
      margin-top: 25px; }
    .results-detail #contents #detail #images {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; }
      .results-detail #contents #detail #images > div {
        width: calc(50% - 8px);
        margin-bottom: 16px;
        position: relative; }
        .results-detail #contents #detail #images > div img {
          width: 100%;
          height: auto; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .results-detail #contents #detail .section-block {
      margin: 0 5.625%; }
      .results-detail #contents #detail .section-block .block {
        width: 43.97%;
        margin: 0 0 54px;
        width: calc(50% - 30px); } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .results-detail #contents #detail {
      width: 1560px;
      margin: 0 auto; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .results-detail #contents #detail .section-block {
      box-sizing: border-box;
      width: 1200px; }
      .results-detail #contents #detail .section-block .block {
        width: 562px; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents #detail {
    padding: 8vw 9.375vw; }
    .results-detail #contents #detail .section-block .left .entry-body-switch {
      font-size: 15px;
      font-size: 4.6875vw;
      padding-bottom: 9.375vw; }
    .results-detail #contents #detail .section-block .left p {
      font-size: 13px;
      font-size: 4.0625vw;
      line-height: 2; }
    .results-detail #contents #detail .section-block .right {
      padding-bottom: 12.5vw; }
    .results-detail #contents #detail #images {
      margin: 12.5vw 0 4.6875vw; }
      .results-detail #contents #detail #images > div {
        margin-bottom: 1.5625vw; }
    .results-detail #contents #detail p {
      margin-bottom: 1vw; } }

.results-detail #contents #map #gmap {
  padding-top: 550px; }

@media screen and (min-width: 48em) {
  .results-detail #contents #map .viewmore {
    padding: 70px 0 90px;
    max-width: 450px;
    margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .results-detail #contents #map .viewmore {
    padding: 9.375vw 0 14.0625vw;
    margin: 0 9.375vw; }
    .results-detail #contents #map .viewmore a {
      font-size: 15px;
      font-size: 4.6875vw;
      font-weight: bold;
      width: 100%; } }

.results-detail .smartphoto {
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8); }
  .results-detail .smartphoto .smartphoto-header {
    background: none; }
    .results-detail .smartphoto .smartphoto-header .smartphoto-count {
      display: none; }
  .results-detail .smartphoto .smartphoto-arrows li {
    background: transparent;
    width: 50px;
    height: 80px;
    top: calc(45% - 40px);
    margin-top: 0; }
    .results-detail .smartphoto .smartphoto-arrows li a {
      background: none; }
      .results-detail .smartphoto .smartphoto-arrows li a:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 80px;
        line-height: 1;
        color: rgba(255, 255, 255, 0.9);
        font-weight: 900; }
  .results-detail .smartphoto .smartphoto-arrow-left {
    left: 20px; }
    .results-detail .smartphoto .smartphoto-arrow-left a:before {
      content: '\f053'; }
  .results-detail .smartphoto .smartphoto-arrow-right {
    right: 20px; }
    .results-detail .smartphoto .smartphoto-arrow-right a:before {
      content: '\f054'; }
  @media screen and (max-width: 47.9375em) {
    .results-detail .smartphoto .smartphoto-arrows li {
      width: 25px;
      height: 40px;
      top: calc(45% - 20px); }
      .results-detail .smartphoto .smartphoto-arrows li a:before {
        font-size: 40px; }
    .results-detail .smartphoto .smartphoto-arrow-left {
      left: 5px; }
    .results-detail .smartphoto .smartphoto-arrow-right {
      right: 5px; } }

/* ---------------------------
お知らせ
*/
.news #contents #headline h1 strong {
  color: #0091e3;
  border-bottom-color: #0091e3; }

.news #contents #headline h1 span {
  color: #373737; }

@media screen and (min-width: 48em) {
  .news #contents #headline {
    background: url(/_uploads/news-headline-bg.png) no-repeat center center/cover;
    height: 600px; }
    .news #contents #headline h1 strong {
      border-bottom: 5px solid;
      letter-spacing: .3rem; } }

@media screen and (max-width: 47.9375em) {
  .news #contents #headline {
    background: url(/_uploads/news-headline-bg-sp.png) no-repeat center center/cover; }
    .news #contents #headline h1 strong {
      margin-top: 0; } }

.news #contents .main .icon-new {
  background: #fff000;
  color: #09f;
  padding: 5px 10px 3px;
  border-radius: 5px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  display: inline-block;
  text-align: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); }

.news #contents .main .icon-new:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 1;
  border-style: solid;
  border-color: #fff000 transparent;
  border-width: 10px 0 0 20px;
  bottom: -10px;
  left: 48%;
  margin-left: -10px; }

.news #contents .main .entry-meta {
  margin-bottom: 1rem; }
  .news #contents .main .entry-meta span + span {
    margin-left: 1rem; }
  .news #contents .main .entry-meta .category {
    background: #0082cd;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    font-size: .8rem;
    margin-left: 1rem;
    display: inline-block; }
    .news #contents .main .entry-meta .category:hover {
      text-decoration: none; }

.news #contents .side aside {
  margin-bottom: 70px; }
  .news #contents .side aside h3 {
    color: #373737;
    font-size: 24px;
    border-bottom: 1px solid #373737;
    padding-bottom: 1.6rem;
    margin-bottom: 0;
    font-feature-settings: "palt";
    letter-spacing: 0.2rem; }
    .news #contents .side aside h3 span {
      color: #0091e3;
      font-size: 55%;
      padding-left: .8rem;
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.1rem;
      font-weight: 600; }
  .news #contents .side aside ul {
    list-style: none;
    padding-left: 0; }
    .news #contents .side aside ul li {
      border-bottom: 1px solid #373737; }
      .news #contents .side aside ul li a {
        display: block;
        text-decoration: none; }
        .news #contents .side aside ul li a span.date {
          display: block;
          letter-spacing: .05rem; }
  .news #contents .side aside.widget-archive-category ul li span {
    display: block; }
  .news #contents .side aside .select {
    margin-top: 40px;
    position: relative;
    width: 100%;
    border: 1px solid #373737;
    box-sizing: border-box;
    background: #fff; }
    .news #contents .side aside .select select {
      appearance: none;
      border: none;
      outline: none;
      background: linear-gradient(90deg, #fff 0 82%, #0091e3 82% 100%);
      padding: 10px 50px 10px 10px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0; }
      .news #contents .side aside .select select::-ms-expand {
        display: none; }
    .news #contents .side aside .select::before, .news #contents .side aside .select::after {
      content: '';
      display: block;
      position: absolute;
      pointer-events: none;
      border: 1px solid transparent;
      width: 0;
      height: 0;
      right: 16px; }
    .news #contents .side aside .select::before {
      bottom: 55%;
      border-width: 0 6.5px 8px 6.5px;
      border-bottom-color: #fff; }
    .news #contents .side aside .select::after {
      border-width: 8px 6.5px 0 6.5px;
      border-top-color: #fff;
      top: 55%; }

@media screen and (min-width: 48em) {
  .news #contents .section-block {
    display: flex;
    justify-content: space-between; }
    .news #contents .section-block .main {
      order: 2;
      width: calc(92.5% - 270px); }
    .news #contents .section-block .side {
      width: 270px !important;
      order: 1; }
      .news #contents .section-block .side aside {
        margin-bottom: 75px; }
        .news #contents .section-block .side aside ul li {
          line-height: 1.4; }
          .news #contents .section-block .side aside ul li a {
            padding: 1.65rem 0; }
            .news #contents .section-block .side aside ul li a span.date {
              margin-bottom: .5rem;
              font-size: .7rem; }
      .news #contents .section-block .side .widget-recent-entries ul li a {
        padding: 1.6rem 0 1.5rem; }
      .news #contents .section-block .side .widget-archive-monthly-dropdow {
        margin-bottom: 0; }
      .news #contents .section-block .side .widget-archive-category ul li span {
        padding: 1.65rem 0; }
      .news #contents .section-block .side .select {
        margin-top: 40px; } }

@media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 89.9375em) {
  .news #contents .section-block {
    margin: 0 8.47% 0 8.26%;
    padding: 145px 0 140px; } }

@media screen and (min-width: 48em) and (min-width: 90em) {
  .news #contents .section-block {
    width: 1200px;
    margin: 0 auto;
    padding: 140px 0; }
    .news #contents .section-block .main {
      width: calc(92.5% - 270px); } }

@media screen and (max-width: 47.9375em) {
  .news #contents .section-block {
    padding: 14.0625vw 9.375vw 5vw; }
    .news #contents .section-block .main, .news #contents .section-block .side {
      width: 100%; }
    .news #contents .section-block .main {
      padding-bottom: 25%; }
      .news #contents .section-block .main .entry-meta .category {
        margin: 0 2.5vw 1.5625vw; }
        .news #contents .section-block .main .entry-meta .category + .category {
          margin-bottom: 1.5625vw;
          margin-left: 0; }
    .news #contents .section-block .side aside {
      margin-bottom: 12.5vw; }
      .news #contents .section-block .side aside h3 {
        padding-bottom: 5vw;
        font-size: 19px;
        font-size: 5.9375vw; }
        .news #contents .section-block .side aside h3 span {
          font-size: 12px;
          font-size: 3.75vw; }
      .news #contents .section-block .side aside ul li {
        line-height: 1.6; }
        .news #contents .section-block .side aside ul li a {
          padding: 5.5vw 0; }
          .news #contents .section-block .side aside ul li a span.date {
            margin-bottom: 1.3vw;
            font-size: 12px;
            font-size: 3.75vw; }
          .news #contents .section-block .side aside ul li a span.post-title {
            font-size: 14px;
            font-size: 4.375vw; }
      .news #contents .section-block .side aside.widget-archive-category ul li span {
        padding: 5.5vw 0; }
      .news #contents .section-block .side aside .select {
        margin-top: 9.375vw; } }

/* お知らせ一覧 */
.news-list #contents .main .news-block {
  border-top: 1px solid #333; }
  @media screen and (max-width: 47.9375em) {
    .news-list #contents .main .news-block {
      padding-top: 35px; } }
  @media screen and (min-width: 48em) {
    .news-list #contents .main .news-block {
      padding-top: 27px; } }
  .news-list #contents .main .news-block .topic {
    border-bottom: 1px solid #373737;
    position: relative; }
    .news-list #contents .main .news-block .topic span.date {
      font-size: 12px;
      display: inline-block;
      margin-right: 1rem;
      letter-spacing: .05rem; }
    .news-list #contents .main .news-block .topic span.cat a {
      font-size: 12px;
      padding: 2px 5px;
      display: inline-block;
      background: #0091e3;
      border-radius: 3px;
      color: #fff;
      text-decoration: none; }
    .news-list #contents .main .news-block .topic h2, .news-list #contents .main .news-block .topic h3 {
      color: #373737; }
    @media screen and (min-width: 48em) {
      .news-list #contents .main .news-block .topic {
        display: flex;
        margin: 0 0 30px;
        padding-bottom: 30px; }
        .news-list #contents .main .news-block .topic .flag {
          position: absolute;
          top: -10px;
          left: -10px; }
          .news-list #contents .main .news-block .topic .flag .icon-new {
            font-size: 13px;
            padding: 2px 5px 1px; }
          .news-list #contents .main .news-block .topic .flag .icon-new:before {
            border-width: 5px 0 0 10px;
            bottom: -5px;
            left: 48%;
            margin-left: -4px; }
        .news-list #contents .main .news-block .topic .meta {
          margin-bottom: 19px; }
          .news-list #contents .main .news-block .topic .meta span.cat {
            margin-bottom: 5px; }
        .news-list #contents .main .news-block .topic .thumb {
          width: 150px;
          margin-right: 30px; }
          .news-list #contents .main .news-block .topic .thumb a {
            max-height: 100px;
            overflow: hidden;
            display: block; }
        .news-list #contents .main .news-block .topic .text {
          width: calc( 100% - 180px); }
          .news-list #contents .main .news-block .topic .text h2, .news-list #contents .main .news-block .topic .text h3 {
            font-size: 1.5rem;
            line-height: 1.4; }
            .news-list #contents .main .news-block .topic .text h2 a, .news-list #contents .main .news-block .topic .text h3 a {
              text-decoration: none; } }
    @media screen and (max-width: 47.9375em) {
      .news-list #contents .main .news-block .topic {
        padding-bottom: 10.9375vw;
        margin-bottom: 12.5vw; }
        .news-list #contents .main .news-block .topic .icon-new {
          position: absolute;
          border-radius: 3px;
          font-size: 12px;
          font-size: 3.75vw;
          padding: .625vw 1.5625vw;
          left: -3.125vw;
          top: -3.125vw; }
        .news-list #contents .main .news-block .topic .icon-new:before {
          border-width: 5px 0 0 10px;
          bottom: -5px;
          left: 48%;
          margin-left: -4px; }
        .news-list #contents .main .news-block .topic .thumb img {
          margin-bottom: 15px; }
        .news-list #contents .main .news-block .topic .meta {
          margin-bottom: 13px; }
          .news-list #contents .main .news-block .topic .meta span.date {
            font-size: 12px;
            font-size: 3.75vw; }
          .news-list #contents .main .news-block .topic .meta span.cat {
            margin-bottom: 5px; }
            .news-list #contents .main .news-block .topic .meta span.cat a {
              font-size: 12px;
              font-size: 3.75vw; }
        .news-list #contents .main .news-block .topic h2, .news-list #contents .main .news-block .topic h3 {
          font-size: 16px;
          font-size: 5vw;
          line-height: 1.6; } }

.news-list #contents .main #content-nav {
  display: flex; }
  @media screen and (min-width: 48em) {
    .news-list #contents .main #content-nav {
      padding: 140px 0; } }
  @media screen and (max-width: 47.9375em) {
    .news-list #contents .main #content-nav {
      height: 5vw;
      overflow: hidden;
      font-size: 13px;
      font-size: 4.0625vw; }
      .news-list #contents .main #content-nav .col-3 {
        padding: 0; }
      .news-list #contents .main #content-nav a {
        text-decoration: underline; } }

/* お知らせ詳細 */
.news-detail #contents .main .entry-title {
  border-bottom: 1px solid #373737; }
  .news-detail #contents .main .entry-title h1 {
    font-weight: 500; }

.news-detail #contents .main .entry-widget .share {
  color: #0091e3;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif; }
  .news-detail #contents .main .entry-widget .share #share-twitter {
    color: #1ab1f4;
    vertical-align: middle; }
  .news-detail #contents .main .entry-widget .share #share-facebook {
    color: #0079e6;
    vertical-align: middle; }

.news-detail #contents .main .entry-widget .copy {
  color: #0091e3;
  font-weight: bold;
  margin-bottom: .7rem; }

.news-detail #contents .main .entry-widget dl dt {
  margin-bottom: .8rem; }

.news-detail #contents .main .entry-widget dl dd {
  line-height: 1.8; }
  .news-detail #contents .main .entry-widget dl dd a {
    color: #0091e3; }

@media screen and (min-width: 48em) {
  .news-detail #contents .section-block .main .entry-meta {
    margin-bottom: 2.4rem; }
    .news-detail #contents .section-block .main .entry-meta .icon-new {
      font-size: 1.1rem; }
    .news-detail #contents .section-block .main .entry-meta .date {
      letter-spacing: .1rem;
      font-size: 14px; }
  .news-detail #contents .section-block .main .entry-title {
    padding-bottom: 2.2rem;
    margin-bottom: 4rem; }
    .news-detail #contents .section-block .main .entry-title h1 {
      font-size: 28px; }
  .news-detail #contents .section-block .main .entry-content p {
    line-height: 2;
    margin-bottom: 1.9rem; }
  .news-detail #contents .section-block .main .entry-widget .share {
    font-size: 1.2rem;
    margin: 3.2rem 0 3.6rem; }
    .news-detail #contents .section-block .main .entry-widget .share #share-twitter, .news-detail #contents .section-block .main .entry-widget .share #share-facebook {
      font-size: 2.8rem; }
  .news-detail #contents .content-nav {
    margin-top: 110px;
    font-size: 1.1rem; }
    .news-detail #contents .content-nav a {
      text-decoration: underline; } }

@media screen and (max-width: 47.9375em) {
  .news-detail #contents .section-block .main .entry-meta {
    margin-bottom: 8vw; }
    .news-detail #contents .section-block .main .entry-meta .icon-new {
      margin-bottom: 6.25vw;
      font-size: 12px;
      font-size: 3.75vw;
      padding: 5px 7px 3px;
      border-radius: 2px; }
    .news-detail #contents .section-block .main .entry-meta .date {
      font-size: 12px;
      font-size: 3.75vw; }
    .news-detail #contents .section-block .main .entry-meta .date::before {
      content: "\A";
      white-space: pre; }
    .news-detail #contents .section-block .main .entry-meta .category {
      padding: 3px 8px;
      font-size: 12px;
      font-size: 3.75vw; }
  .news-detail #contents .section-block .main .entry-title {
    margin-bottom: 10vw;
    padding-bottom: 2vw; }
    .news-detail #contents .section-block .main .entry-title h1 {
      line-height: 1.5;
      font-size: 17px;
      font-size: 5.3125vw; }
  .news-detail #contents .section-block .main .entry-content p {
    line-height: 1.8;
    margin-bottom: 7vw; }
  .news-detail #contents .section-block .main .entry-widget .share {
    font-size: 13px;
    font-size: 4.0625vw;
    margin: 0 0 5vw; }
    .news-detail #contents .section-block .main .entry-widget .share #share-twitter, .news-detail #contents .section-block .main .entry-widget .share #share-facebook {
      font-size: 34px;
      font-size: 10.625vw; }
  .news-detail #contents .section-block .main .entry-widget .copy {
    font-size: 13px;
    font-size: 4.0625vw;
    margin-bottom: 1.5625vw; }
  .news-detail #contents .section-block .main .entry-widget dl {
    margin-bottom: 3rem; }
    .news-detail #contents .section-block .main .entry-widget dl dt {
      margin-bottom: 1.5625vw;
      font-size: 14px;
      font-size: 4.375vw; }
    .news-detail #contents .section-block .main .entry-widget dl dd {
      line-height: 1.6;
      font-size: 12px;
      font-size: 3.75vw; }
      .news-detail #contents .section-block .main .entry-widget dl dd a {
        text-decoration: underline; }
  .news-detail #contents .section-block .main .entry-widget .content-nav {
    font-size: 13px;
    font-size: 4.0625vw;
    margin: 0 0 1vw; }
    .news-detail #contents .section-block .main .entry-widget .content-nav > div {
      padding: 0; }
      .news-detail #contents .section-block .main .entry-widget .content-nav > div a {
        text-decoration: underline; } }

/* ---------------------------
入居者さま
*/
@media screen and (min-width: 48em) {
  .for-resident #contents #headline {
    background: url("/_uploads/for_resident-bg.jpg") no-repeat center center/cover; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #headline {
    background: url("/_uploads/for_resident-bg-sp.jpg") no-repeat center center/cover; } }

.for-resident #contents .assets {
  border-top: 1px solid #000; }
  .for-resident #contents .assets .asset {
    border-bottom: 1px solid #000; }
    @media screen and (min-width: 48em) {
      .for-resident #contents .assets .asset {
        margin: 15px 0; } }
    .for-resident #contents .assets .asset h3 {
      color: #373737; }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents .assets {
    padding-top: 3vw; }
    .for-resident #contents .assets .asset {
      text-align: center;
      padding-bottom: 9.735vw;
      margin-bottom: 7.8125vw;
      padding-left: 9.375%;
      padding-right: 9.375%; }
      .for-resident #contents .assets .asset h3 {
        margin: 5vw;
        font-size: 17px;
        font-size: 5.3125vw;
        line-height: 1.5; }
      .for-resident #contents .assets .asset a {
        padding: 2.8vw 5vw; } }

@media screen and (min-width: 48em) {
  .for-resident #contents .container {
    padding-bottom: 20px; }
  .for-resident #contents .assets {
    padding-top: 40px; }
    .for-resident #contents .assets .asset {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 45px;
      padding-bottom: 40px; }
      .for-resident #contents .assets .asset h3 {
        font-size: 1.8rem;
        width: 71.77%; }
      .for-resident #contents .assets .asset a {
        text-align: center;
        font-size: 1.1rem;
        width: 28.23%;
        height: 60px; } }

@media screen and (min-width: 48em) {
  .for-resident #contents #feature {
    padding-bottom: 5px; }
    .for-resident #contents #feature .container h2 {
      margin-top: 75px; }
    .for-resident #contents #feature .container p {
      margin-top: 52px;
      margin-bottom: 60px; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #feature .container {
    padding: 0 0 9.375vw; }
  .for-resident #contents #feature p br {
    display: none; } }

@media screen and (min-width: 48em) {
  .for-resident #contents #rules .heading {
    padding: 140px 0 50px; }
  .for-resident #contents #rules .section-block {
    padding: 0 8.3% 150px; }
  .for-resident #contents #rules .block {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .for-resident #contents #rules .block > div {
      width: calc(50% - 30px); }
    .for-resident #contents #rules .block .text {
      order: 1; }
      .for-resident #contents #rules .block .text h3 {
        color: #373737;
        line-height: 1.6;
        margin-bottom: 1.5rem;
        margin-top: .7rem;
        letter-spacing: .18rem; }
      .for-resident #contents #rules .block .text p {
        margin-bottom: 3.3rem;
        line-height: 2;
        font-size: 1rem; }
    .for-resident #contents #rules .block .images {
      order: 2; } }

@media screen and (min-width: 48em) and (min-width: 90em) {
  .for-resident #contents #rules .section-block {
    width: 1440px;
    margin: 0 auto; } }

@media screen and (min-width: 48em) and (min-width: 97.5em) {
  .for-resident #contents #rules {
    max-width: 1560px;
    margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #rules .bg-blue {
    padding: 13vw 9.375vw 18.75vw; }
  .for-resident #contents #rules .heading {
    padding-bottom: 3.125vw; }
    .for-resident #contents #rules .heading h2 strong {
      letter-spacing: .1rem; }
  .for-resident #contents #rules .block .text h3 {
    color: #373737;
    margin-top: 9vw;
    line-height: 1.7;
    font-size: 16px;
    font-size: 5vw; }
  .for-resident #contents #rules .block .text p {
    font-size: 13px;
    font-size: 4.0625vw;
    line-height: 1.8; }
  .for-resident #contents #rules .block .text a {
    margin-top: 3.125vw; } }

@media screen and (min-width: 48em) {
  .for-resident #contents #procedures .heading {
    padding: 20px 0 50px; }
  .for-resident #contents #procedures .section-block p {
    text-align: center;
    width: 100%;
    line-height: 2; }
  .for-resident #contents #procedures .assets {
    margin-left: 8.333%;
    margin-right: 8.333%;
    margin-top: 75px;
    margin-bottom: 70px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .for-resident #contents #procedures .assets {
      margin-left: 0;
      margin-right: 0; } }

@media screen and (min-width: 48em) {
    .for-resident #contents #procedures .assets .asset {
      padding-left: 13.75%;
      padding-right: 13.75%; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #procedures .heading {
    padding: 14.0625vw 0 2vw; }
  .for-resident #contents #procedures .section-block {
    margin-bottom: 12vw; }
    .for-resident #contents #procedures .section-block p {
      line-height: 1.8; }
  .for-resident #contents #procedures .assets {
    margin-bottom: 18.75vw; }
    .for-resident #contents #procedures .assets .asset h3 {
      font-size: 17px;
      font-size: 5.3125vw;
      line-height: 1.5; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #problems .bg-blue {
    padding: 14.0625vw 9.375vw 10.9375vw; }
  .for-resident #contents #problems .heading {
    padding: 0 0 2vw; }
  .for-resident #contents #problems .block p {
    text-align: left;
    margin-bottom: 10.9375vw; }
  .for-resident #contents #problems .block h3 {
    margin-bottom: 2rem;
    font-size: 16px;
    font-size: 5vw; }
  .for-resident #contents #problems .section-block {
    border-top: 1px solid #000;
    margin-top: 9.735vw;
    padding-top: 12.5vw; }
    .for-resident #contents #problems .section-block h4 {
      margin: 8.25vw 0 5vw;
      color: #0091e3;
      font-size: 20px;
      font-size: 6.25vw;
      font-weight: 500;
      line-height: 1.4; }
    .for-resident #contents #problems .section-block .block p {
      margin-bottom: 12vw; } }

@media screen and (min-width: 48em) {
  .for-resident #contents #problems {
    padding-top: 0; }
    .for-resident #contents #problems .heading {
      padding: 145px 0 20px; }
    .for-resident #contents #problems .block-content {
      padding: 0 8.3% 100px; }
      .for-resident #contents #problems .block-content > .block {
        padding: 30px 0; }
        .for-resident #contents #problems .block-content > .block p {
          line-height: 1.9;
          margin-bottom: 85px; }
        .for-resident #contents #problems .block-content > .block h3 {
          margin-bottom: 40px; }
    .for-resident #contents #problems .section-block {
      padding: 60px 0 30px;
      justify-content: space-between;
      border-top: 1px solid #373737; }
      .for-resident #contents #problems .section-block + .section-block {
        margin-top: 30px; }
      .for-resident #contents #problems .section-block .left {
        width: 33.333%; }
      .for-resident #contents #problems .section-block .right {
        width: 61.42%; }
        .for-resident #contents #problems .section-block .right h4 {
          color: #0091e3;
          font-size: 28px;
          margin: 8px 0 1rem;
          font-weight: 500; }
        .for-resident #contents #problems .section-block .right p {
          margin-bottom: 0;
          line-height: 1.9; }
      .for-resident #contents #problems .section-block + .assets {
        margin-top: 35px; }
        .for-resident #contents #problems .section-block + .assets .asset {
          padding-left: 13.75%;
          padding-right: 13.75%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .for-resident #contents #problems .block-content {
      width: 1440px;
      margin: 0 auto;
      padding: 0 120px 100px; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .for-resident #contents #problems {
      max-width: 1560px;
      margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .for-resident #contents #contact .container {
    padding-top: 14.0625vw;
    padding-bottom: 14.0625vw; }
    .for-resident #contents #contact .container h2 {
      line-height: 1.8;
      margin-bottom: 7.8125vw;
      font-size: 18px;
      font-size: 5.625vw; } }

@media screen and (min-width: 48em) {
  .for-resident #contents #contact {
    padding: 40px 0 80px; }
    .for-resident #contents #contact .container h2 {
      line-height: 1.9; }
    .for-resident #contents #contact .container p {
      max-width: 400px;
      margin: 35px auto; } }

/* ---------------------------
会社概要
*/
.company #contents #headline {
  background: url("/_uploads/company-bg.jpg") no-repeat center center/cover; }

.company #contents table {
  border-top: 1px solid #000; }
  .company #contents table tr {
    border-bottom: 1px solid #000; }
    .company #contents table tr th, .company #contents table tr td {
      border: 0;
      vertical-align: middle; }
    .company #contents table tr th {
      white-space: nowrap; }
  @media screen and (min-width: 48em) {
    .company #contents table {
      margin: 60px 0; }
      .company #contents table tr th, .company #contents table tr td {
        padding: 1.9rem 0; } }

.company #contents #feature h2 {
  color: #0091e3;
  text-align: center;
  line-height: 1.8; }

.company #contents #feature .section-block .text p {
  text-align: left;
  margin: 0 0 1rem; }

@media screen and (min-width: 48em) {
  .company #contents #feature {
    margin: 0 auto; }
    .company #contents #feature .container {
      padding-left: 0;
      padding-right: 0;
      margin: 0; }
    .company #contents #feature h2 {
      font-size: 2rem;
      line-height: 1.6875;
      margin: 75px 0 50px; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .company #contents #feature {
      max-width: 83.4%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .company #contents #feature {
      max-width: 1200px; } }

@media screen and (min-width: 48em) {
    .company #contents #feature .section-block {
      display: flex;
      justify-content: space-between; }
      .company #contents #feature .section-block > div {
        width: calc( 50% - 30px);
        margin: 0; } }
      @media screen and (min-width: 48em) and (max-width: 89.9375em) {
        .company #contents #feature .section-block > div {
          padding: 0; } }

@media screen and (min-width: 48em) {
      .company #contents #feature .section-block .text .copy {
        font-size: 28px;
        margin-top: 15px;
        margin-bottom: 35px;
        line-height: 1.6;
        letter-spacing: .1rem; }
      .company #contents #feature .section-block .text p {
        letter-spacing: .1rem;
        font-feature-settings: "palt"; }
      .company #contents #feature .section-block .text .name {
        font-size: 28px;
        margin-top: 30px;
        letter-spacing: .25rem; }
        .company #contents #feature .section-block .text .name span {
          font-size: 1rem;
          letter-spacing: .1rem; } }

@media screen and (max-width: 47.9375em) {
  .company #contents #feature {
    padding: 12.5vw 0 10.9375vw; }
    .company #contents #feature h2 {
      margin-bottom: 10vw;
      font-size: 18px;
      font-size: 5.625vw;
      line-height: 1.7; }
    .company #contents #feature .section-block .images {
      margin-bottom: 3.125vw; }
    .company #contents #feature .section-block .text {
      padding-right: 0;
      padding-left: 0; }
      .company #contents #feature .section-block .text .copy {
        font-size: 18px;
        font-size: 5.625vw;
        margin-bottom: 8vw;
        line-height: 1.7;
        text-align: center; }
      .company #contents #feature .section-block .text .name {
        font-size: 18px;
        font-size: 5.625vw;
        margin-top: 6vw;
        letter-spacing: .2rem;
        text-align: center; }
        .company #contents #feature .section-block .text .name span {
          font-size: 13px;
          font-size: 4.0625vw;
          letter-spacing: .1rem; } }

.company #contents #mission .block h3 {
  color: #373737; }

.company #contents #mission .block p {
  line-height: 2; }

.company #contents #mission #chara {
  background: #fff; }
  .company #contents #mission #chara h3 {
    color: #373737;
    margin-bottom: 2.7rem; }
    .company #contents #mission #chara h3 + p {
      line-height: 2; }
  .company #contents #mission #chara h4 {
    color: #0091e3;
    margin-bottom: 18px; }
  .company #contents #mission #chara table {
    border-top: 1px solid #000;
    margin-bottom: 25px; }
    .company #contents #mission #chara table tr {
      border-bottom: 1px solid #000; }
      .company #contents #mission #chara table tr th, .company #contents #mission #chara table tr td {
        padding: 1rem .8rem;
        font-size: 1rem; }

@media screen and (min-width: 48em) {
  .company #contents #mission .bg-blue {
    padding: 145px 0 150px; }
  .company #contents #mission h2 strong {
    letter-spacing: .3rem; }
  .company #contents #mission .block h3 {
    margin-top: 80px;
    margin-bottom: 40px;
    letter-spacing: .2rem; }
  .company #contents #mission #cm {
    margin: 80px 8.3333% 125px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .company #contents #mission #cm {
      width: 1200px;
      margin-left: auto;
      margin-right: auto; } }

@media screen and (min-width: 48em) {
  .company #contents #mission #chara {
    padding: 10px 7.333% 20px;
    margin: 120px 8.3333% 0; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .company #contents #mission #chara {
      width: 1200px;
      margin-left: auto;
      margin-right: auto; } }

@media screen and (min-width: 48em) {
    .company #contents #mission #chara .section-block {
      justify-content: space-between;
      padding: 45px 0; }
    .company #contents #mission #chara .left {
      width: 36.666%;
      margin-top: 50px; }
    .company #contents #mission #chara .right {
      width: 56.56%; }
    .company #contents #mission #chara table {
      margin-top: 0; } }

@media screen and (min-width: 48em) and (max-width: 97.4375em) {
  .company #contents #mission {
    max-width: 100%;
    padding: 0; } }

@media screen and (min-width: 48em) and (min-width: 97.5em) {
  .company #contents #mission {
    max-width: 1560px; } }

@media screen and (max-width: 47.9375em) {
  .company #contents #mission {
    padding-left: 0;
    padding-right: 0; }
    .company #contents #mission .bg-blue {
      padding: 14.0625vw 9.375vw 18.75vw; }
      .company #contents #mission .bg-blue h3 {
        margin-top: 10.9375vw;
        margin-bottom: 6.25vw;
        font-size: 16px;
        font-size: 5vw;
        line-height: 1.7; }
      .company #contents #mission .bg-blue p {
        font-size: 13px;
        font-size: 4.0625vw;
        text-align: left !important;
        line-height: 1.8; }
      .company #contents #mission .bg-blue #cm {
        margin: 12vw 0 15vw; }
      .company #contents #mission .bg-blue #chara {
        margin: 12.5vw 0 0;
        padding: 12.5vw 9.375vw 6vw; }
        .company #contents #mission .bg-blue #chara h3 {
          margin-top: 0;
          margin-bottom: 3.5vw;
          line-height: 1.7; }
          .company #contents #mission .bg-blue #chara h3 + p {
            text-align: left !important;
            line-height: 1.8;
            margin-bottom: 1vw; }
            .company #contents #mission .bg-blue #chara h3 + p br {
              display: none; }
        .company #contents #mission .bg-blue #chara .left {
          text-align: center; }
          .company #contents #mission .bg-blue #chara .left img {
            margin: 6.25vw auto 9.375vw; }
        .company #contents #mission .bg-blue #chara .right h4 {
          font-size: 16px;
          font-size: 5vw;
          font-weight: 500;
          margin-bottom: 4.785vw; }
        .company #contents #mission .bg-blue #chara .right table {
          margin-top: 4.785vw; }
          .company #contents #mission .bg-blue #chara .right table tr {
            margin-bottom: .7vw; }
          .company #contents #mission .bg-blue #chara .right table th, .company #contents #mission .bg-blue #chara .right table td {
            display: block;
            width: 100%;
            padding: 0; }
          .company #contents #mission .bg-blue #chara .right table th {
            padding-top: 3.5vw;
            padding-bottom: .7vw;
            font-size: 13px;
            font-size: 4.0625vw; }
          .company #contents #mission .bg-blue #chara .right table td {
            padding-bottom: 3.5vw;
            font-size: 13px;
            font-size: 4.0625vw; } }

.company #contents #outline .block h3 {
  color: #373737;
  text-align: center; }

.company #contents #outline .block table tr th {
  color: #0091e3; }

@media screen and (min-width: 48em) {
  .company #contents #outline {
    margin: 23px 0 0;
    padding-bottom: 40px; }
    .company #contents #outline .container {
      padding-left: 0;
      padding-right: 0; }
    .company #contents #outline .heading {
      margin-bottom: 85px; }
      .company #contents #outline .heading h2 strong {
        padding-left: 2.7rem;
        padding-right: 2.7rem; }
    .company #contents #outline .section-block p {
      line-height: 1.9;
      text-align: center;
      width: 100%; }
    .company #contents #outline .block {
      margin: 60px 0 0; }
      .company #contents #outline .block h3 {
        color: #373737;
        text-align: center;
        font-size: 1.875rem; }
      .company #contents #outline .block table tr th {
        color: #0091e3;
        width: 404px;
        font-weight: 500;
        vertical-align: middle;
        font-size: 1.4rem;
        padding-left: 2rem; }
      .company #contents #outline .block table tr td {
        line-height: 1.8; }
        .company #contents #outline .block table tr td a {
          text-decoration: underline; } }
  @media screen and (min-width: 48em) and (max-width: 89.9375em) {
    .company #contents #outline {
      margin-left: 8.3%;
      margin-right: 8.3%; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .company #contents #outline {
      margin-left: auto;
      margin-right: auto; } }

@media screen and (max-width: 47.9375em) {
  .company #contents #outline {
    padding: 15.625vw 0 14.0625vw; }
    .company #contents #outline h2 {
      margin-bottom: 2.2rem; }
      .company #contents #outline h2 strong {
        padding-left: 11vw;
        padding-right: 11vw; }
    .company #contents #outline p {
      margin-bottom: 12vw;
      line-height: 1.8; }
      .company #contents #outline p br {
        display: none; }
    .company #contents #outline h3 {
      font-size: 16px;
      font-size: 5vw;
      margin-bottom: 8vw;
      font-feature-settings: 1rem; }
    .company #contents #outline table th, .company #contents #outline table td {
      display: block;
      width: 100%;
      padding: 0; }
    .company #contents #outline table th {
      padding-top: 4.6875vw;
      padding-bottom: 1.5625vw;
      font-weight: 500;
      font-size: 17px;
      font-size: 5.3125vw; }
    .company #contents #outline table td {
      padding-bottom: 4.6785vw;
      font-size: 14px;
      font-size: 4.375vw;
      line-height: 1.75;
      letter-spacing: .09rem; }
      .company #contents #outline table td a {
        text-decoration: underline; } }

.company #contents #overview .block {
  padding-bottom: 30px; }

.company #contents #overview table {
  margin-bottom: 0; }

@media screen and (min-width: 48em) {
  .company #contents #overview {
    margin-bottom: 85px;
    padding: 40px 0 65px; }
    .company #contents #overview .bg-blue {
      padding: 145px 0 95px; }
    .company #contents #overview .heading {
      margin-bottom: 90px; }
      .company #contents #overview .heading h2 strong {
        padding-left: 2.7rem;
        padding-right: 2.7rem; }
    .company #contents #overview .block {
      padding: 0 8.3%; } }
    @media screen and (min-width: 48em) and (min-width: 90em) {
      .company #contents #overview .block {
        padding: 0 180px; } }

@media screen and (min-width: 48em) {
    .company #contents #overview table {
      margin-top: 95px;
      margin-bottom: 70px; }
      .company #contents #overview table th {
        width: 249px;
        line-height: 1.8;
        padding-left: 2rem; }
      .company #contents #overview table td {
        font-size: 1rem;
        line-height: 1.8; }
    .company #contents #overview .banners {
      display: flex;
      justify-content: space-between;
      margin: 0 auto; } }
    @media screen and (min-width: 48em) and (min-width: 1150px) {
      .company #contents #overview .banners {
        padding: 0;
        max-width: 960px; } }
    @media screen and (min-width: 48em) and (max-width: 1149px) {
      .company #contents #overview .banners {
        width: 100%; } }

@media screen and (min-width: 48em) {
      .company #contents #overview .banners a {
        width: 30.2%;
        margin: 5px 0;
        display: inline-block; }
        .company #contents #overview .banners a img {
          width: 100%; } }
  @media screen and (min-width: 48em) and (max-width: 97.4375em) {
    .company #contents #overview {
      max-width: 100%; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .company #contents #overview {
      max-width: 1560px; } }

@media screen and (max-width: 47.9375em) {
  .company #contents #overview {
    padding-left: 0;
    padding-right: 0; }
    .company #contents #overview .bg-blue {
      padding-top: 15.625vw; }
      .company #contents #overview .bg-blue h2 {
        margin-bottom: 11.5vw; }
        .company #contents #overview .bg-blue h2 strong {
          padding-left: 11vw;
          padding-right: 11vw; }
      .company #contents #overview .bg-blue table {
        margin: 12vw auto 3.125vw;
        width: 81.25vw; }
        .company #contents #overview .bg-blue table th, .company #contents #overview .bg-blue table td {
          display: block;
          width: 100%;
          padding: 0; }
        .company #contents #overview .bg-blue table th {
          padding-top: 4.6875vw;
          padding-bottom: 1.5625vw;
          font-size: 14px;
          font-size: 4.375vw;
          letter-spacing: .05rem; }
        .company #contents #overview .bg-blue table td {
          padding-bottom: 5vw;
          font-size: 13px;
          font-size: 4.0625vw;
          line-height: 1.6;
          letter-spacing: .03rem; }
          .company #contents #overview .bg-blue table td a {
            text-decoration: underline; }
      .company #contents #overview .bg-blue .banners {
        padding-bottom: 6vw; }
        .company #contents #overview .bg-blue .banners a {
          margin: 0 9.375vw 6.25vw;
          display: block; }
          .company #contents #overview .bg-blue .banners a img {
            max-width: 100%; }
    .company #contents #overview #gmap.iframe {
      padding-top: 109.375vw; }
      .company #contents #overview #gmap.iframe iframe {
        border: 0; } }

/* ---------------------------
採用情報
*/
@media screen and (max-width: 47.9375em) {
  .recruit #contents {
    padding-bottom: 0; } }

.recruit #contents table {
  border-top: 1px solid #000; }
  .recruit #contents table tr {
    border-bottom: 1px solid #000; }
    .recruit #contents table tr th, .recruit #contents table tr td {
      border: 0; }
    .recruit #contents table tr th {
      white-space: nowrap; }
  @media screen and (min-width: 48em) {
    .recruit #contents table {
      margin: 60px 0; }
      .recruit #contents table tr th, .recruit #contents table tr td {
        padding: 2rem 0; }
      .recruit #contents table tr th {
        width: 292px;
        padding-left: 2rem;
        letter-spacing: .1rem; } }
  @media screen and (max-width: 47.9375em) {
    .recruit #contents table {
      margin: 9.375vw 0; }
      .recruit #contents table tr th, .recruit #contents table tr td {
        display: block; }
      .recruit #contents table tr th {
        padding: 5vw 0 1.15625vw;
        letter-spacing: .1rem;
        font-size: 14px;
        font-size: 4.375vw; }
      .recruit #contents table tr td {
        padding: 0 0 5vw;
        font-size: 13px;
        font-size: 4.0625vw;
        line-height: 1.6;
        letter-spacing: .1rem; } }

@media screen and (min-width: 48em) {
  .recruit #contents #headline {
    background: url(/_uploads/recruit-bg.jpg) no-repeat center center/cover; } }

@media screen and (max-width: 47.9375em) {
  .recruit #contents #headline {
    background: url(/_uploads/recruit-bg-sp.jpg) no-repeat center center/cover; } }

.recruit #contents #feature h2 {
  color: #0091e3;
  text-align: center;
  line-height: 1.8; }

@media screen and (min-width: 48em) {
  .recruit #contents #feature {
    padding-bottom: 120px; }
    .recruit #contents #feature h2 {
      margin: 70px 0 50px;
      font-size: 32px; }
    .recruit #contents #feature p {
      text-align: center;
      line-height: 2; } }

@media screen and (max-width: 47.9375em) {
  .recruit #contents #feature {
    padding: 40px 0; }
    .recruit #contents #feature h2 {
      margin-bottom: 1rem;
      font-size: 18px;
      font-size: 5.625vw;
      line-height: 1.7; }
    .recruit #contents #feature p {
      line-height: 2;
      letter-spacing: 0; }
      .recruit #contents #feature p br {
        display: none; } }

@media screen and (min-width: 48em) {
  .recruit #contents #new {
    margin-bottom: 150px;
    padding: 0; }
    .recruit #contents #new .bg-blue {
      padding: 140px 8.333% 90px; }
      .recruit #contents #new .bg-blue .heading {
        margin-bottom: 80px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .recruit #contents #new .bg-blue {
      padding-left: 180px;
      padding-right: 180px; } }
  @media screen and (min-width: 48em) and (max-width: 97.4375em) {
    .recruit #contents #new {
      max-width: 100%; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .recruit #contents #new {
      max-width: 1560px; } }

@media screen and (max-width: 47.9375em) {
  .recruit #contents #new {
    padding-left: 0;
    padding-right: 0; }
    .recruit #contents #new .bg-blue {
      padding: 15.625vw 9.375vw 10.9375vw; } }

/* ---------------------------
オンライン面談予約
*/
@media screen and (max-width: 47.9375em) {
  .online-reservation #contents {
    padding-bottom: 5px; }
    .online-reservation #contents .heading h2 strong {
      letter-spacing: .1rem; } }

.online-reservation #contents table {
  border-top: 1px solid #000; }
  .online-reservation #contents table tr {
    border-bottom: 1px solid #000; }
    .online-reservation #contents table tr th, .online-reservation #contents table tr td {
      border: 0; }
    .online-reservation #contents table tr th {
      white-space: nowrap;
      color: #0091e3; }
  @media screen and (min-width: 48em) {
    .online-reservation #contents table {
      margin: 60px 0; }
      .online-reservation #contents table tr th, .online-reservation #contents table tr td {
        padding: 1.8rem 0;
        vertical-align: middle; }
      .online-reservation #contents table tr th {
        width: 292px;
        padding-left: 2rem;
        letter-spacing: .1rem; } }
  @media screen and (max-width: 47.9375em) {
    .online-reservation #contents table {
      margin: 30px 0; }
      .online-reservation #contents table tr th, .online-reservation #contents table tr td {
        display: block; }
      .online-reservation #contents table tr th {
        padding: 5vw 0 1.5625vw;
        letter-spacing: .1rem; }
      .online-reservation #contents table tr td {
        padding: 0 0 5vw; } }

.online-reservation #contents .bg-white {
  background: #fff; }

@media screen and (min-width: 48em) {
  .online-reservation #contents #headline {
    background: url(/_uploads/online_reservation-bg.jpg) no-repeat center center/cover; }
    .online-reservation #contents #headline h1 img {
      height: 130px;
      margin-bottom: 5px;
      margin-top: 20px; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #contents #headline {
    background: url(/_uploads/online_reservation-bg-sp.jpg) no-repeat center center/cover; }
    .online-reservation #contents #headline h1 {
      margin: 3.7vw 0 0; }
      .online-reservation #contents #headline h1 img {
        height: 23vw; }
      .online-reservation #contents #headline h1 strong {
        margin-top: 0;
        line-height: 1.3;
        padding-bottom: 4.6875vw;
        letter-spacing: .2rem; } }

.online-reservation #contents #feature h2 {
  color: #0091e3;
  text-align: center; }

@media screen and (min-width: 48em) {
  .online-reservation #contents #feature {
    padding: 135px 8.333% 125px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .online-reservation #contents #feature {
      padding: 135px 0 125px; } }

@media screen and (min-width: 48em) {
    .online-reservation #contents #feature h2 {
      font-size: 32px; }
    .online-reservation #contents #feature p {
      margin-top: 4rem;
      text-align: center;
      line-height: 2; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #contents #feature {
    padding: 12vw 0 10.9375vw; }
    .online-reservation #contents #feature h2 {
      font-size: 18px;
      font-size: 5.625vw;
      margin-bottom: 4.6785vw;
      line-height: 1.75; }
    .online-reservation #contents #feature p {
      line-height: 2; }
      .online-reservation #contents #feature p br {
        display: none; } }

.online-reservation #contents #environment h3 {
  border: solid #0091e3;
  border-width: 2px 0; }

.online-reservation #contents #environment .qr {
  display: flex;
  justify-content: center; }
  .online-reservation #contents #environment .qr a {
    color: #0091e3;
    font-weight: bold;
    text-decoration: underline; }

@media screen and (min-width: 48em) {
  .online-reservation #contents #environment {
    padding: 140px 8.333% 130px; }
    .online-reservation #contents #environment .heading {
      margin-bottom: 85px; }
    .online-reservation #contents #environment .section-block {
      display: flex;
      justify-content: space-between; }
      .online-reservation #contents #environment .section-block > div {
        width: calc(50% - 30px); }
      .online-reservation #contents #environment .section-block h3 {
        padding: 1.4rem 0;
        margin-bottom: 2.2rem;
        font-size: 1.5rem; }
      .online-reservation #contents #environment .section-block h4 {
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: .55rem; }
      .online-reservation #contents #environment .section-block p {
        line-height: 2;
        margin-bottom: 1.4rem;
        letter-spacing: .05rem; }
        .online-reservation #contents #environment .section-block p + p {
          margin-top: 3.1rem; }
      .online-reservation #contents #environment .section-block .qr {
        margin-top: 45px; }
        .online-reservation #contents #environment .section-block .qr a {
          width: 130px;
          margin: 0 30px; }
          .online-reservation #contents #environment .section-block .qr a img {
            margin-bottom: 40px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .online-reservation #contents #environment {
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (min-width: 48em) and (min-width: 97.5em) {
    .online-reservation #contents #environment {
      max-width: 1560px;
      margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #contents #environment {
    padding: 14.0675vw 0 17vw; }
    .online-reservation #contents #environment .heading {
      margin-bottom: 12.5vw; }
    .online-reservation #contents #environment .left {
      margin-bottom: 45px; }
    .online-reservation #contents #environment h3 {
      padding: 5.4vw 0;
      border-width: 1px 0;
      margin-bottom: 8.5vw;
      font-size: 17px;
      font-size: 5.3125vw;
      line-height: 1.5; }
    .online-reservation #contents #environment h4 {
      font-size: 15px;
      font-size: 4.6875vw;
      margin-bottom: 4vw;
      font-weight: bold; }
    .online-reservation #contents #environment p {
      line-height: 1.8;
      margin-bottom: 1.2rem; }
      .online-reservation #contents #environment p + p {
        margin-top: 8vw; }
    .online-reservation #contents #environment .qr {
      margin: 7vw -4vw 0; }
      .online-reservation #contents #environment .qr a {
        width: 35.9375vw;
        font-size: 13px;
        font-size: 4.0625vw;
        margin: 0 4vw; }
        .online-reservation #contents #environment .qr a img {
          margin-bottom: 4.6785vw; } }

@media screen and (min-width: 48em) {
  .online-reservation #contents #flow {
    padding: 150px 8.333% 85px; } }
  @media screen and (min-width: 48em) and (min-width: 90em) {
    .online-reservation #contents #flow {
      padding: 150px 0 85px; } }

@media screen and (min-width: 48em) {
    .online-reservation #contents #flow .heading {
      margin-bottom: 85px; }
    .online-reservation #contents #flow table th, .online-reservation #contents #flow table td {
      padding-top: 1.65rem;
      padding-bottom: 1.65rem; }
    .online-reservation #contents #flow table th {
      font-size: 24px;
      font-weight: 500;
      width: 356px; }
    .online-reservation #contents #flow table td {
      font-size: 1rem; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #contents #flow {
    padding: 14.0625vw 9.375vw 0; }
    .online-reservation #contents #flow .heading {
      margin-bottom: 10.9375vw; }
    .online-reservation #contents #flow table th {
      padding: 5vw 0 1.5625vw;
      font-size: 17px;
      font-size: 5.3125vw;
      font-weight: 500; }
    .online-reservation #contents #flow table td {
      padding-bottom: 6.25vw;
      font-size: 13px;
      font-size: 4.0625vw;
      letter-spacing: .1rem;
      line-height: 1.6; } }

.online-reservation #contents #application .bg-d-blue {
  background: #0082cd;
  max-width: 1560px;
  margin: 0 auto; }
  .online-reservation #contents #application .bg-d-blue .heading h2 strong, .online-reservation #contents #application .bg-d-blue .heading h2 span {
    color: #fff;
    border-color: #fff; }

@media screen and (min-width: 48em) {
  .online-reservation #contents #application {
    padding-top: 0;
    padding-bottom: 147px; }
    .online-reservation #contents #application .heading {
      padding: 150px 0 60px; }
    .online-reservation #contents #application .bg-white {
      margin: 0 8.333%;
      border-radius: 5px;
      padding: 10px 10.256% 100px;
      max-width: 1200px; }
    .online-reservation #contents #application .bg-d-blue {
      padding-bottom: 152px; } }

@media screen and (min-width: 97.5em) {
  .online-reservation #contents #application .bg-white {
    margin: 0 auto; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #contents #application {
    padding-top: 9.375vw;
    padding-bottom: 0; }
    .online-reservation #contents #application .bg-d-blue {
      padding: 15.625vw 0 60px; }
      .online-reservation #contents #application .bg-d-blue .heading {
        margin-bottom: 12.5vw; }
      .online-reservation #contents #application .bg-d-blue .bg-white {
        margin: 0 9.375vw;
        padding: 0 7.8125vw 9.375vw; } }

.online-reservation #thanks p.note {
  color: #0091e3;
  font-weight: bold; }

@media screen and (min-width: 48em) {
  .online-reservation #thanks h2 {
    padding-top: 85px;
    font-size: 32px;
    margin-bottom: 55px; }
  .online-reservation #thanks p {
    text-align: center; }
  .online-reservation #thanks p.note {
    margin: 2rem 0 90px; } }

@media screen and (max-width: 47.9375em) {
  .online-reservation #thanks {
    padding-top: 12.5vw; }
    .online-reservation #thanks h2 {
      font-size: 18px;
      font-size: 5.625vw;
      margin-bottom: 25px;
      line-height: 1.75; }
    .online-reservation #thanks p.note {
      margin: 5.5vw 0 0;
      padding-bottom: 16vw; } }

.online-reservation #appointment {
  display: none; }

/* ---------------------------
お問合せ
*/
@media screen and (max-width: 47.9375em) {
  .contact #contents {
    padding-bottom: 5px; }
    .contact #contents .heading h2 strong {
      letter-spacing: .1rem; } }

.contact #contents .bg-white {
  background: #fff; }

@media screen and (min-width: 48em) {
  .contact #contents #headline {
    background: url(/_uploads/contact-bg.jpg) no-repeat center center/cover; }
    .contact #contents #headline h1 {
      margin-bottom: 2.3rem; }
    .contact #contents #headline h1 img {
      height: 130px;
      margin-bottom: 33px; } }

@media screen and (max-width: 47.9375em) {
  .contact #contents #headline {
    background: url(/_uploads/contact-bg-sp.jpg) no-repeat center center/cover; }
    .contact #contents #headline h1 {
      margin: 3.7vw 0 0; }
      .contact #contents #headline h1 img {
        height: 23vw; }
      .contact #contents #headline h1 strong {
        margin-top: 0;
        line-height: 1.3;
        padding-bottom: 4.6875vw; } }

.contact #contents #feature .tel {
  color: #0091e3;
  font-weight: 700;
  font-family: Montserrat,sans-serif; }

.contact #contents #feature .fax {
  font-weight: 700;
  font-family: Montserrat,sans-serif;
  letter-spacing: .1rem; }

@media screen and (min-width: 48em) {
  .contact #contents #feature {
    padding: 140px 0 130px; }
    .contact #contents #feature .tel {
      font-size: 60px;
      padding-top: 5px;
      line-height: 1.35;
      letter-spacing: .1rem; }
    .contact #contents #feature p {
      margin-bottom: .9rem; }
    .contact #contents #feature .fax {
      font-size: 48px; } }

@media screen and (max-width: 47.9375em) {
  .contact #contents #feature {
    padding: 12.5vw 0 6.25vw; }
    .contact #contents #feature .heading {
      margin-bottom: 35px; }
    .contact #contents #feature .tel {
      font-size: 26px;
      font-size: 8.125vw;
      margin-bottom: .3rem;
      letter-spacing: .075rem; }
    .contact #contents #feature p {
      margin-bottom: .5rem;
      line-height: 1.6; }
    .contact #contents #feature .fax {
      font-size: 22px;
      font-size: 6.875vw; } }

.contact #contents #application .bg-d-blue {
  background: #0082cd;
  max-width: 1560px;
  margin: 0 auto; }
  .contact #contents #application .bg-d-blue .heading h2 strong, .contact #contents #application .bg-d-blue .heading h2 span {
    color: #fff;
    border-color: #fff; }

@media screen and (min-width: 48em) {
  .contact #contents #application {
    padding-top: 0;
    padding-bottom: 150px; }
    .contact #contents #application .heading {
      padding: 145px 0 0; }
      .contact #contents #application .heading h2 {
        margin-bottom: 5rem; }
      .contact #contents #application .heading p {
        color: #fff;
        line-height: 2; }
    .contact #contents #application .bg-white {
      margin: 80px 8.333% 0;
      padding: 10px 10.256% 90px;
      max-width: 1200px; }
    .contact #contents #application .bg-d-blue {
      padding-bottom: 155px; } }

@media screen and (min-width: 97.5em) {
  .contact #contents #application .bg-white {
    margin: 80px auto 0; } }

@media screen and (max-width: 47.9375em) {
  .contact #contents #application {
    padding-top: 30px;
    padding-bottom: 0; }
    .contact #contents #application .bg-d-blue {
      padding: 45px 9.735vw 60px; }
      .contact #contents #application .bg-d-blue .heading {
        margin-bottom: 7.8125vw; }
        .contact #contents #application .bg-d-blue .heading h2 {
          margin-bottom: 12.5vw; }
        .contact #contents #application .bg-d-blue .heading p {
          color: #fff;
          text-align: left; }
      .contact #contents #application .bg-d-blue .bg-white {
        margin: 0;
        padding: 0 7.8125vw 9.735vw; } }

.contact #contents #thanks p.note {
  color: #0091e3;
  font-weight: bold; }

@media screen and (min-width: 48em) {
  .contact #contents #thanks h2 {
    padding-top: 85px;
    font-size: 32px;
    margin-bottom: 55px; }
  .contact #contents #thanks p {
    text-align: center; }
  .contact #contents #thanks p.note {
    margin: 2rem 0 90px; } }

@media screen and (max-width: 47.9375em) {
  .contact #contents #thanks {
    padding-top: 12.5vw; }
    .contact #contents #thanks h2 {
      font-size: 18px;
      font-size: 5.625vw;
      margin-bottom: 25px;
      line-height: 1.75; }
    .contact #contents #thanks p.note {
      margin: 1.1rem 0 0;
      padding-bottom: 16vw; } }

.contact #appointment {
  display: none; }
